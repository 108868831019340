// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { SkiplinksModule, SkiplinksModuleContent } from './Skiplinks.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const skiplinksModuleContentFactory = (): SkiplinksModuleContent => ({ containers: [] })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const skiplinksFactory: ModuleFactory<SkiplinksModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<SkiplinksModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: skiplinksModuleContentFactory(),
      driver: ModuleDriver.Skiplinks
    }
  }
}
