


























































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { IFile, MediaType } from '@movecloser/ui-core'

import { DescriptionOfImageFile } from '../../../models'
import { Picker, Related, RelatedType, UnresolvedImage } from '../../../contracts'

import { RelatedPartial } from '../_abstract'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({ name: 'ImageForm' })
export class ImageForm extends RelatedPartial<DescriptionOfImageFile> {
  /**
   * TODO: Documentation.
   */
  @PropSync('image', { type: Object, required: false, default: null })
  public _image!: UnresolvedImage | null

  /**
   * TODO: Documentation.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly simple!: boolean

  /**
   * TODO: Documentation.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly stackView!: boolean

  /**
   * TODO: Documentation.
   */
  public get isRelatedSet (): boolean {
    return this._image !== null && typeof this._image.image === 'object' && this._image.image !== null &&
    typeof this._image.image.value !== 'undefined'
  }

  /**
   * TODO: Documentation.
   */
  public get preview (): IFile | null {
    if (!this.resolved) {
      return null
    }

    return {
      type: this.resolved.mime as MediaType,
      thumbnail: this.resolved.url
    }
  }

  /**
   * TODO: Documentation.
   */
  public addRelatedSource (): void {
    this.pickRelated(Picker.File, this.setRelatedImage, this.getRelatedSource())
  }

  /**
   * TODO: Documentation.
   */
  public clear (): void {
    if (!this._image) {
      return
    }

    const imageCopy = { ...this._image }

    imageCopy.image = null
    this.resolved = null

    this._image = imageCopy
  }

  /**
   * @inheritDoc
   */
  protected getRelatedSource (): Related<RelatedType.File> | undefined {
    if (this._image === null || this._image.image === null ||
      typeof this._image.image.value === 'undefined') {
      return undefined
    }

    return this._image.image
  }

  /**
   * TODO: Documentation.
   */
  protected setRelatedImage (selected: Related<RelatedType.File>): void {
    if (this._image === null) {
      this._image = { image: selected }
    } else {
      const imageCopy = {
        ...this._image
      }

      imageCopy.image = selected
      this._image = imageCopy
    }

    this.$nextTick(() => {
      this.resolveRelated()
    })
  }
}

export default ImageForm
