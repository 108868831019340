// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'

declare global {
  interface Window {
    NONCE: string
  }
}

@Component<UseNonce>({
  name: 'UseNonce',
  created () {
    if (typeof window !== 'undefined') {
      this.nonce = window.NONCE ?? null
    }
  }
})
export class UseNonce extends Vue {
  public nonce: string | null = null
}
