// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'
import { ContainerWidth } from '../../atoms'

import { MosaicModule, MosaicModuleBasicVersionContent, MosaicModuleVersion } from './Mosaic.contracts'

export const mosaicContentFactory = (): MosaicModuleBasicVersionContent => ({
  addons: [],
  containerWidth: ContainerWidth.Normal,
  hasBottomSpacing: false
})

export const mosaicFactory: ModuleFactory<MosaicModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<MosaicModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.Mosaic,
      content: mosaicContentFactory(),
      version: MosaicModuleVersion.Basic
    }
  }
}
