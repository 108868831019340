// Copyright © 2022 Move Closer

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanHaveColor {
  /**
   * Determines the color of the "object".
   */
  color?: Color
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export enum Color {
  Body = 'body',
  // Note! Body should be first element due to it's a default value & we want it to be displayed a top.
  Black = 'black',
  Primary = 'primary',
  White = 'white',
}
