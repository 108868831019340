// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize, RelatedType } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { DepartmentsModule, DepartmentsModuleContent, DepartmentsModuleVersion } from './Departments.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const departmentsContentFactory = (): DepartmentsModuleContent => ({
  departments: {
    type: RelatedType.DepartmentsFeed,
    value: 1
  }
})

/**
 * @author Javon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const departmentsFactory: ModuleFactory<DepartmentsModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 1
    }
  },
  createModule (): ModuleBase<DepartmentsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: departmentsContentFactory(),
      driver: ModuleDriver.Departments,
      version: DepartmentsModuleVersion.Carousel
    }
  }
}
