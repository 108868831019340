









































import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { FormAddBtn, FormFieldset } from '../../atoms'

import { ElementFactory, MoveDirection } from './Iterator.contracts'

@Component({
  name: 'Iterator',
  components: { FormAddBtn, FormFieldset }
})
export class Iterator extends Vue {
  @Prop({ type: String, required: false })
  public readonly addClass?: string

  @Prop({ type: Function, required: false, default: null })
  protected readonly createElement!: ElementFactory | null

  @Prop({ type: String, required: false })
  public readonly elementClass?: string

  @PropSync('list', { type: Array, required: true })
  public readonly items!: unknown[]

  @Prop({ type: Boolean, required: false, default: true })
  public readonly showControls!: boolean

  @Prop({ type: Object, required: false, default: null })
  public readonly types!: AnyObject | null

  @Prop({ type: String, required: false, default: '' })
  public readonly typesTranslationKey!: string

  public icons = DashmixIconName

  public MoveDirection = MoveDirection

  public addElement (type?: string): Promise<void> | void {
    if (!this.createElement) {
      if (!this.showControls) {
        return
      }

      throw new Error('Iterator: Property [createElement] not given!')
    }

    const newElement = this.createElement(type)
    if (newElement instanceof Promise) {
      return newElement.then((e: unknown) => {
        this.items.splice(this.items.length, 0, e)
      })
    }

    this.items.splice(this.items.length, 0, newElement)
  }

  public removeElement (index: number): void {
    this.items.splice(index, 1)
  }

  public updateElement (index: number, element: unknown): void {
    this.items.splice(index, 1)
    this.items.splice(index, 0, element)
  }

  public moveElement (index: number, direction: MoveDirection): void {
    const element = this.items.splice(index, 1)[0]
    const resultIndex = direction === MoveDirection.Up ? index - 1 : index + 1
    this.items.splice(resultIndex, 0, element)
  }
}

export default Iterator
