








import { BootstrapTheme } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { isValidEnumValue, log } from '../../../support'

import { UI_ALERT_DEFAULT_THEME } from './UiAlert.config'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<UiAlert>({ name: 'UiAlert' })
export class UiAlert extends Vue {
  /**
   * The alert's colour theme.
   */
  @Prop({ type: String, required: false, default: UI_ALERT_DEFAULT_THEME })
  public readonly theme!: BootstrapTheme

  /**
   * CSS classname defining the alert's colour theme,
   */
  public get themeClassName (): string {
    return `alert-${this.safeTheme}`
  }

  /**
   * `this.theme` checked for its validity.
   *
   * @see theme
   */
  private get safeTheme (): BootstrapTheme {
    if (!isValidEnumValue(this.theme, BootstrapTheme)) {
      log(`UiAlert.safeTheme(): Expected [this.theme] to be a valid value from the [BootstrapTheme] enum, but got [${this.theme}]!`, 'error')
      return UI_ALERT_DEFAULT_THEME
    }

    return this.theme
  }
}

export default UiAlert
