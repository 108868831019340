








import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { DescriptionOfImageFile, ImageRatio } from '../../../models'
import { FetchesRelatedAsync, HasId, InjectionCallback, ModuleImageRatio } from '../../../contracts'
import { log, toBootstrapImageProps } from '../../../support'
import { ResolvesRelatedAsync } from '../../../services'

import { ContainerData, UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME, UiContainer } from '../../atoms'

import { ContainerContent } from './Container.contracts'

/**
 * Component that's aware of all the different container widths/types/modes specified in the app.
 * It helps you get the correct CSS class combination using the passed-in `width` prop.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Component<Container>({
  name: 'Container',
  components: { UiContainer },

  async prefetch (): Promise<void> {
    await this.fetchRelated()
  },

  mounted (): void {
    this.eventBus.emit(UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME, { id: this.id })
  }
})
export class Container extends Vue implements FetchesRelatedAsync {
  /**
   * Container's configuration.
   */
  @Prop({ type: Object, required: true })
  public readonly data!: ContainerContent & HasId

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  private readonly relatedService!: ResolvesRelatedAsync

  /**
   * Function capable of resolving the injections from the IoC container.
   */
  @Prop({ type: Function, required: true })
  private readonly resolveInjection!: InjectionCallback

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Inject({ from: 'isMobile', default: false })
  private readonly isMobile!: boolean

  /**
   * `this.data` with all related data already resolved.
   */
  public resolvedData: ContainerData | null = null

  /**
   * Determines what image ratio should be used in particular module.
   */
  protected imageRatios: ModuleImageRatio = {
    desktop: ImageRatio.HeroDesktop,
    mobile: ImageRatio.HeroMobile
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    this.resolvedData = { ...this.data, backgroundImage: undefined }

    if (!this.data.backgroundImage) {
      return
    }

    try {
      const descriptionOfImageFile: DescriptionOfImageFile =
        await this.relatedService.resolve<DescriptionOfImageFile>(this.data.backgroundImage)

      this.resolvedData.backgroundImage = toBootstrapImageProps(descriptionOfImageFile, this.imageRatio)
    } catch (error) {
      const message: string = 'Container.fetchRelated(): Failed to resolve the [ImageFile] for the containers [backgroundImage]!'
      log([message, error], 'error')
    }
  }

  /**
   * @see ContainerData.id
   */
  public get id (): HasId['id'] {
    return this.data.id
  }

  /**
   * Instance of the `EventBus` service.
   */
  private get eventBus (): IEventbus {
    return this.resolveInjection<IEventbus>(EventbusType)
  }

  /**
   * Determines image ratio which should be used.
   */
  protected get imageRatio (): ImageRatio {
    if (this.isMobile) {
      return this.imageRatios.mobile
    }

    return this.imageRatios.desktop
  }
}

export default Container
