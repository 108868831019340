// Copyright © 2022 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { AsyncComponent, VueConstructor } from 'vue'
import { HasId } from '@movecloser/page-builder'
import { isPlainObject } from 'lodash'

import { isValidEnumValue } from '../support'

import { HorizontalAlignment, VerticalAlignment } from './alignment'
import { ImageRatio } from '../models'

export { HasId }

/**
 * Shorthand for declaring the `Module` of unknown `Content` and `Driver`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type AnyModule = Module<ModuleContent, ModuleDriver>

/**
 * Describes the object that can be aligned within the grid container, in both X and Y-axis.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanBeAlignedInGrid {
  /**
   * Describes the object's alignment.
   */
  alignment?: {
    /**
     * Object's alignment in the X-axis.
     */
    x?: {
      /**
       * Object's alignment in the X-axis, applied on desktop screens only.
       */
      desktop?: HorizontalAlignment

      /**
       * Object's alignment in the X-axis, applied on mobile screens only.
       */
      mobile?: HorizontalAlignment
    }

    /**
     * Object's alignment in the Y-axis.
     */
    y?: {
      /**
       * Object's alignment in the Y-axis, applied on desktop screens only.
       */
      desktop?: VerticalAlignment

      /**
       * Object's alignment in the Y-axis, applied on mobile screens only.
       */
      mobile?: VerticalAlignment
    }
  }
}

/**
 * Checks if the passed-in object correctly implements the `CanBeAlignedInGrid.alignment` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isAlignment = (subject: any): subject is CanBeAlignedInGrid['alignment'] => {
  if (!isPlainObject(subject)) {
    return false
  }

  if ('x' in subject) {
    if (!isPlainObject(subject.x)) {
      return false
    }

    if ('desktop' in subject.x && !isValidEnumValue(subject.x.desktop, HorizontalAlignment)) {
      return false
    }

    if ('mobile' in subject.x && !isValidEnumValue(subject.x.mobile, HorizontalAlignment)) {
      return false
    }
  }

  if ('y' in subject) {
    if (!isPlainObject(subject.y)) {
      return false
    }

    if ('desktop' in subject.y && !isValidEnumValue(subject.y.desktop, VerticalAlignment)) {
      return false
    }

    if ('mobile' in subject.y && !isValidEnumValue(subject.y.mobile, VerticalAlignment)) {
      return false
    }
  }

  return true
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanBeResizable {
  /**
   * Determines whether the element is resizable (can be resized).
   */
  isResizable: boolean
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type CanHaveMaxSize = Partial<HasMaxSize>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type CanHaveMinSize = Partial<HasMinSize>

/**
 * Interface that **HAS TO** be implemented by every module
 * that fetches the related data asynchronously.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FetchesRelatedAsync {
  /**
   * Fetches the related data.
   *
   * Calling this single method **HAS TO** populate the component's state
   * in a way that allows it to render itself (i.e. no data is further needed).
   */
  fetchRelated (): Promise<void>
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasMaxSize {
  /**
   * Determines the **maximum** number of grid **columns**
   * that the module should span.
   */
  maxColSpan: number

  /**
   * Determines the **maximum** number of grid **rows**
   * that the module should span.
   */
  maxRowSpan: number
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasMinSize {
  /**
   * Determines the **minimum** number of grid **columns**
   * that the module should span.
   */
  minColSpan: number

  /**
   * Determines the **minimum** number of grid **rows**
   * that the module should span.
   */
  minRowSpan: number
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export type ModuleContent = AnyObject

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ModuleDriver {
  BlogFeed = 'blog_feed',
  Button = 'button',
  Card = 'card',
  Carousel = 'carousel',
  ContactPerson = 'contact_person',
  Departments = 'departments',
  Embed = 'embed',
  FloatingButton = 'floating_button',
  Footer = 'footer',
  GlobalSearchResults = 'global_search_results',
  HashtagWall = 'hashtag_wall',
  Heading = 'heading',
  Hero = 'hero',
  Image = 'image',
  JobOffers = 'job_offers',
  Labels = 'labels',
  Mosaic = 'mosaic',
  Navbar = 'navbar',
  Newsletter = 'newsletter',
  Quote = 'quote',
  SearchResults = 'search_results',
  Skiplinks = 'skiplinks',
  Stats = 'stats',
  Tabs = 'tabs',
  Text = 'text',
  Tile = 'tile',
  Timeline = 'timeline',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ModuleId = string

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ModuleSize extends CanBeResizable, CanHaveMaxSize, CanHaveMinSize {
  /**
   * How many columns the module should span
   */
  colSpan: number

  /**
   * How many rows the module should span
   */
  rowSpan: number
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ModuleVersion = string

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ModuleTitle = string

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasContent<Content extends ModuleContent> {
  /**
   * Main module's data
   */
  content: Content
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasDriver<Driver extends ModuleDriver> {
  /**
   * Driver associated with the module
   */
  driver: Driver
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanHaveVersion<Version = ModuleVersion> {
  /**
   * The version of the module
   */
  version?: Version
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type HasVersion<Version extends ModuleVersion> = Required<CanHaveVersion<Version>>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasTitle {
  /**
   * The title of the module.
   */
  title: ModuleTitle
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface HasVisibility {
  /**
   * Determines whether the module should be made visible.
   */
  isVisible: boolean
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type InjectionCallback = <Interface>(type: string | symbol) => Interface

/**
 * Base model of the Module.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
export type Module<
  Content extends ModuleContent,
  Driver extends ModuleDriver,
  Version = ModuleVersion
> = (
  HasContent<Content> &
  HasDriver<Driver> &
  HasId &
  HasTitle &
  HasVisibility &
  CanBeAlignedInGrid &
  CanHaveVersion<Version>
)

/**
 * @author Olga Milczek <ola.milczek@movecloser.pl>
 */
export interface ModuleImageRatio {
  desktop: ImageRatio
  mobile: ImageRatio
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ModuleBase<Module extends AnyModule> = Pick<Module, 'alignment' | 'content' | 'driver' | 'version'>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface ModuleFactory<Module extends AnyModule = AnyModule> {
  /**
   * Default module's size.
   */
  createInitialSize (): ModuleSize

  /**
   * Default module's config.
   */
  createModule (): ModuleBase<Module>

  /**
   * Get module's step.
   */
  getResizeStep (module: Module, axis: ResizeAxis): number
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleConstructor = AsyncComponent | VueConstructor

/**
 * Module with the `version` property set to 'required'.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ModuleWithVersion<
  Content extends ModuleContent,
  Driver extends ModuleDriver,
  Version extends ModuleVersion,
> = Module<Content, Driver> & HasVersion<Version>

/**
 * Axis in which module can be resizeable
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum ResizeAxis {
  X = 'x',
  Y = 'y'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ContentAlignment = CanBeAlignedInGrid['alignment']
