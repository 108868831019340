// Copyright © 2022 Move Closer

import { BootstrapImageProps, Link } from '@movecloser/ui-core'
import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { DescriptionOfBlogPost, ResolvedBlogPost } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single blog post.
 *
 * @see BlogFeedModule
 * @see BlogFeedModuleForm
 * @see BlogFeedModuleUi
 * @see BlogFeedModuleUiPresentation
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class BlogPostDriver extends AbstractTypeDriver<DescriptionOfBlogPost, ResolvedBlogPost> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {
    body: 'shortDesc',
    image: {
      type: MappingTypes.Function,
      value: item => ({
        alt: '',
        lazy: true,
        src: item.coverImage
      } as BootstrapImageProps)
    },
    link: {
      type: MappingTypes.Function,
      value: item => ({
        isExternal: true,
        newTab: true,
        target: item.url
      } as Link)
    }
  }

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.BlogPost
}
