// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfNavigation, navigationAdapterMap, NavigationItem } from '../../../models/navigation'
import { RelatedType } from '../../../contracts'

import { RelatedRecord } from '../related.contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single navigation.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class NavigationDriver extends AbstractTypeDriver<DescriptionOfNavigation, NavigationItem[]> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = navigationAdapterMap

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.Link]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.Navigation

  /**
   * @inheritDoc
   */
  public resolve (
    id: string,
    record: RelatedRecord
  ): NavigationItem[] {
    this.throwWhenMissing(id, record)

    const navigation: DescriptionOfNavigation = this.describe(id, record)

    // Check if the output is as expected.
    if (!Array.isArray(navigation.links)) {
      throw new Error(
        `NavigationDriver.resolve(): Failed to resolve items for the [${this.key}] of a given ID [${id}]!`
      )
    }

    return navigation.links
  }
}
