// Copyright © 2021 Move Closer

import { debounce } from 'lodash'
import { DirectiveBinding, DirectiveOptions } from 'vue/types/options'

/**
 * `max-height` transition duration (in ms).
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const TRANSITION_DURATION: number = 100

/**
 * Calculates the value for the element's `max-height` CSS property.
 *
 * @param el - Target element.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const calculateMaxHeight = (el: HTMLElement): string => {
  if (typeof el === 'undefined' || typeof el.scrollHeight !== 'number') {
    return '0'
  }

  return `${el.scrollHeight}px`
}

/**
 * Directive that controls the value of the element's `max-height` CSS property.
 *
 * @example usage:
 *   <div v-collapse="isElementCollapsed">...</div>
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const collapse: DirectiveOptions = {
  inserted (el, binding): void {
    if (typeof el === 'undefined') {
      return
    }

    el.style.overflow = 'hidden'
    el.style.transition = `max-height ${TRANSITION_DURATION}ms ease-in-out`

    updateMaxHeight(el, binding)
    updateMaxHeightOnElResize(el, binding)
  },

  update (el, binding): void {
    updateMaxHeight(el, binding)
  }
}

/**
 * Updates the value of the element's `max-height` CSS property.
 *
 * @param el - Target element.
 * @param binding - `DirectiveBinding` object.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const updateMaxHeight = (el: HTMLElement, binding: DirectiveBinding): void => {
  if (typeof el === 'undefined' || typeof binding === 'undefined') {
    return
  }

  let isCollapsed: boolean

  if (typeof binding.value === 'boolean') {
    isCollapsed = binding.value
  } else if (typeof binding.value.isCollapsed === 'boolean') {
    isCollapsed = binding.value.isCollapsed
  } else {
    throw new Error('v-collapse -> updateMaxHeight(): Unsupported [binding.value]! Expected [binding.value] to ba a [boolean] or an [object] with the [isCollapsed] boolean key!')
  }

  el.style.maxHeight = isCollapsed ? '0' : calculateMaxHeight(el)

  if (typeof binding.value.transitionCallback === 'function') {
    setTimeout(() => {
      binding.value.transitionCallback()
    }, TRANSITION_DURATION + 50)
  }
}

/**
 * Updates the element's `max-height` when the size of the element changes.
 *
 * @param el - The `HTMLElement` to observe.
 * @param binding - `DirectiveBinding` object.
 */
const updateMaxHeightOnElResize = (el: HTMLElement, binding: DirectiveBinding): void => {
  const resizeHandler = debounce(() => {
    updateMaxHeight(el, binding)
    resizeObserver.disconnect()
  }, 100)

  const resizeObserver = new ResizeObserver(resizeHandler)
  resizeObserver.observe(el)
}
