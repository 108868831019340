







import { Component, Prop, Vue } from 'vue-property-decorator'

import { JobOffer } from '../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Table>({
  name: 'Table',
  components: {
    JobOffersTable: () => import(
      /* webpackChunkName: "molecules/JobOffersTable" */
      '../../../../../molecules/JobOffersTable/JobOffersTable.vue'
    )
  }
})
export class Table extends Vue {
  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffer.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: true })
  public readonly openLinkInNewTab!: JobOffer['openLinkInNewTab']

  /**
   * Determines whether the component has been provided with the correct `offers` @Prop.
   */
  public get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }
}

export default Table
