// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { NavbarModule, NavbarModuleContent } from './Navbar.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const navbarContentFactory = (): NavbarModuleContent => ({
  bottomNav: null,
  brandImage: undefined,
  brandLink: undefined,
  brandText: '',
  searchResultsPage: null,
  topLeftNav: null,
  topRightNav: null
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const navbarFactory: ModuleFactory<NavbarModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 2
    }
  },
  createModule (): ModuleBase<NavbarModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: navbarContentFactory(),
      driver: ModuleDriver.Navbar
    }
  }
}
