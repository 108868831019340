// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'
import { ContainerWidth } from '../../atoms'

import { HeroModule, HeroModuleBasicVersionContent, HeroModuleVersion } from './Hero.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const heroContentFactory = (): HeroModuleBasicVersionContent => ({
  addons: [],
  backgroundImage: null,
  containerWidth: ContainerWidth.Normal,
  showBreadcrumbs: false,
  hasBottomSpacing: false
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const heroFactory: ModuleFactory<HeroModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<HeroModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.Hero,
      content: heroContentFactory(),
      version: HeroModuleVersion.Basic
    }
  }
}
