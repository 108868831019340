





























import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { Heading, HeadingLevel } from '../../../contracts'

import { FormFieldset } from '../../atoms'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited 10.11.2021)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component({
  name: 'HeadingForm',
  components: { FormFieldset }
})
export class HeadingForm extends Vue {
  @PropSync('heading', { type: Object, required: false, default: null })
  public _model!: Heading | null

  @Prop({ type: Boolean, required: false, default: false })
  public withoutFieldset!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public small!: boolean

  public get level (): HeadingLevel | undefined {
    if (!this._model) {
      return
    }
    return this._model.level
  }

  public set level (level: HeadingLevel | undefined) {
    if (!level) {
      if (!this._model) {
        return
      }

      this._model.level = 0
      return
    }

    if (!this._model) {
      this._model = {
        level: level,
        text: ''
      }
      return
    }

    this._model.level = level
  }

  public get text (): string {
    if (!this._model) {
      return ''
    }
    return this._model.text
  }

  public set text (text: string) {
    if (!text) {
      if (!this._model) {
        return
      }

      this._model.text = ''
      return
    }

    if (!this._model) {
      this._model = {
        level: HeadingLevel.H1,
        text: text
      }
      return
    }

    this._model.text = text
  }

  public get headingOptions (): DashmixSelectItem[] {
    const options = Object.entries(HeadingLevel).map(([name, value]) => {
      return {
        label: this.$t(`formHelpers.headingLevel.options.${name}`).toString(),
        value
      }
    }).filter(option => typeof option.value === 'number')

    options.push({
      label: this.$t('formHelpers.headingLevel.options.none').toString(),
      value: ''
    })

    return options
  }

  @Watch('_model')
  private onModelChange (model: Heading | null): void {
    if (model === null) {
      return
    }

    if (!model.level && !model.text) {
      this._model = null
    }
  }
}

export default HeadingForm
