// Copyright © 2021 Move Closer

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Modal {
  AddDataset = 'AddDataset',
  AddSetItem = 'addSetItem',
  Confirm = 'confirm',
  ConfirmAcceptance = 'confirmAcceptance',
  ContentLists = 'contentLists',
  CropImage = 'cropImage',
  DirectoryCreate = 'directoryCreate',
  DirectoryEdit = 'directoryEdit',
  DirectoryMove = 'directoryMove',
  FileCreate = 'fileCreate',
  FileEdit = 'fileEdit',
  FilesMove = 'filesMove',
  Footnote = 'footnote',
  ForceChangeSite = 'forceChangeSite',
  LockConflict = 'lockConflict',
  Login = 'loginModal',
  MarkdownEditorGuide = 'markdownEditorGuide',
  MetaAddonModal = 'metaAddon',
  PickFile = 'pickFile',
  PickGallery = 'pickGallery',
  PickLink = 'pickLink',
  PickSet = 'pickSet',
  PreventLoosingData = 'preventLoosingDataModal',
  QuoteFormModal = 'quoteFormModal',
  RemoveConflictModal = 'removeConflictModal',
  ResetListItemsModal = 'resetListItemsModal',
  SelectModuleModal = 'selectModuleModal',
  SiteSelection = 'siteSelection',
  SortContent = 'sortContent',
  Tag = 'tagModal',
  VariantPreviewDecision = 'variantPreviewDecision'
}
