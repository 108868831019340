// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { MachineState } from './NewsletterForm.contracts'

/**
 * Registry that binds the `MachineState` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const newsletterFormMachineStateComponentRegistry: Record<MachineState, AsyncComponent> = {
  [MachineState.DataEntry]: () => import(
    /* webpackChunkName: "molecules/NewsletterForm" */
    './partials/states/DataEntry.vue'
  ),
  [MachineState.Error]: () => import(
    /* webpackChunkName: "molecules/NewsletterForm" */
    './partials/states/Error.vue'
  ),
  [MachineState.Success]: () => import(
    /* webpackChunkName: "molecules/NewsletterForm" */
    './partials/states/Success.vue'
  )
}
