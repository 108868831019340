// Copyright © 2022 Move Closer

import 'reflect-metadata'
import { ComponentOptions, VueConstructor } from 'vue'
import { createDecorator } from 'vue-class-component'
import { getCurrentInstance } from '@vue/composition-api'
import { Interfaces } from '@movecloser/front-core'

let Vue: VueConstructor

/**
 * Install function.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
function __install (_Vue: VueConstructor): void {
  if (Vue && _Vue === Vue) {
    if (process.env.VUE_APP_ENV !== 'production') {
      throw new Error('[inversify] already installed.')
    }
  } else {
    _Vue.mixin({
      beforeCreate (this: Vue) {
        if (this.$options.container) {
          this.$container = this.$options.container
        } else if (this.$options.parent && this.$options.parent.$container) {
          this.$container = this.$options.parent.$container
        }
      }
    })
  }
}

/**
 * Inject instance of given identifier.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * @param identifier
 */
export const Inject = (identifier?: Interfaces.ServiceIdentifier<unknown>) => (proto: Vue, key: string): void => {
  let Type: unknown

  if (typeof Reflect !== 'undefined' && typeof Reflect.getMetadata === 'function') {
    Type = Reflect.getMetadata('design:type', proto, key)
  }

  return createDecorator((options: ComponentOptions<Vue>, key) => {
    options.computed = options.computed || {}
    options.computed[key] = function (this: Vue) {
      // @ts-expect-error - TODO: Please, provide the justification for this `@ts-expect-error` usage.
      return this.$container.get(identifier || Type)
    }
  })(proto, key)
}

/**
 * Install container plugin.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * @param vue
 */
export const installContainer = (vue: VueConstructor): void => {
  vue.use({
    install: __install
  })
}

/**
 * This function resolve dependency based on it's identifier.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @param identifier
 */

export const resolve = <T> (identifier: symbol): T => {
  const app = getCurrentInstance()
  if (app?.proxy.$container) {
    return app.proxy.$container.get(identifier)
  }
  return null as unknown as T
}
