// Copyright © 2021 Move Closer

import { isFinite, isPlainObject, isString } from 'lodash'

import { Description, Identifier } from '../contracts'
import { log } from '../support'
import { MediaType } from '@movecloser/ui-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export type DescriptionOfFile<Type extends FileType> = Description & File<Type>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export interface File<Type extends FileType> {
  id: Identifier
  mime: string
  size: string
  type: Type
  url: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum FileType {
  Attachment = 'attachment',
  Image = 'image',
  Video = 'video'
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface VideoFile extends File<FileType.Video> {
  fileDescription?: string
  title?: string
}

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface IFile {
  thumbnail?: string
  type: MediaType
}

/**
 * Determines whether the passed-in `input` object correctly implements the `File` interface.
 *
 * @param input - The object to verify.
 * @param [type] - Exact type of the file. If specified, the type guard will verify if the value of the input
 * object's `type` property is exactly equal to the value of this argument.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isFile = (input: any, type?: FileType): input is File<FileType> => {
  // Validate the type of the input.
  if (!isPlainObject(input)) {
    const message: string = `isFile(): Expected the [input] to be a plain object, but got [${typeof input}]!`
    log([message, input], 'error')
    return false
  }

  // Validate the presence of the required keys.
  const requiredKeys = ['id', 'mime', 'size', 'type', 'url']
  for (const requiredKey of requiredKeys) {
    if (typeof input[requiredKey] === 'undefined') {
      const message: string = `isFile(): [${requiredKey}] key is missing!`
      log([message, input], 'error')
      return false
    }
  }

  // Validate the type of the number keys.
  const numberKeys = ['id']
  for (const numberKey of numberKeys) {
    if (!isFinite(input[numberKey])) {
      const message: string = `isFile(): Expected [input.${numberKey}] to be a [number], but got [${typeof input[numberKey]}]!`
      log([message, input], 'error')
      return false
    }
  }

  // Validate the type of the string keys.
  const stringKeys = ['mime', 'size', 'url']
  for (const stringKey of stringKeys) {
    if (!isString(input[stringKey])) {
      const message: string = `isFile(): Expected [input.${stringKey}] to be a [string], but got [${typeof input[stringKey]}]!`
      log([message, input], 'error')
      return false
    }

    if (input[stringKey].length === 0) {
      const message: string = `isFile(): Value of the [input.${stringKey}] can NOT be empty!`
      log([message, input], 'error')
      return false
    }
  }

  // Validate the value of the `type` key.
  if (typeof type !== 'undefined') {
    if (input.type !== type) {
      const message: string = `isFile(): Expected the value of the [input.type] to be exactly equal to the [${type}], but got [${input.type}]!`
      log([message, input], 'error')
      return false
    }
  } else {
    const validFileTypes = Object.values(FileType)
    const hasValidFileType: boolean = validFileTypes.reduce<boolean>((matchAlreadyFound, curr) => (
      matchAlreadyFound ? true : input.type === curr
    ), false)
    if (!hasValidFileType) {
      const message: string = `isFile(): Invalid value of the [input.type]! Valid values are [${validFileTypes.join(', ')}], but got [${input.type}]!`
      log([message, input], 'error')
      return false
    }
  }

  return true
}
