// Copyright © 2022 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { DescriptionOfFile, FileType } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single file.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class FileDriver extends AbstractTypeDriver<DescriptionOfFile<FileType>> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {
    size: {
      type: MappingTypes.Function,
      value: item => item.toString()
    }
  }

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.File
}
