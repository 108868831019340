// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { ContactPersonModule, ContactPersonModuleContent } from './ContactPerson.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const contactPersonContentFactory = (): ContactPersonModuleContent => ({
  company: '',
  bottomLinks: [],
  mainLink: null,
  image: null,
  name: '',
  role: '',
  region: ''
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const contactPersonFactory: ModuleFactory<ContactPersonModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<ContactPersonModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.ContactPerson,
      content: contactPersonContentFactory()
    }
  }
}
