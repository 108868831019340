// Copyright © 2021 Move Closer

/**
 * Static values for the `<BootstrapPagination>`.
 *
 * @see BootstrapPaginationProps
 * @see https://bootstrap-vue.org/docs/components/pagination#comp-ref-b-pagination-props
 */
export const BOOTSTRAP_PAGINATION_PROPS: {
  /**
   * Alignment of the page buttons: 'start' (or 'left'), 'center', 'end' (or 'right'), or 'fill'.
   */
  align: 'center' | 'end' | 'fill' | 'left' | 'right' | 'start'

  /**
   * Display first page number instead of Goto First button.
   */
  firstNumber: boolean

  /**
   * Hides the goto first and goto last page buttons.
   */
  hideGotoEndButtons: boolean

  /**
   * Display last page number instead of Goto Last button.
   */
  lastNumber: boolean

  /**
   * Maximum number of buttons to show (including ellipsis if shown, but excluding the bookend buttons).
   */
  limit: number

  /**
   * Number of rows per page.
   */
  perPage: number
} = {
  align: 'center',
  firstNumber: true,
  hideGotoEndButtons: true,
  lastNumber: true,
  limit: 5,
  perPage: 10
}
