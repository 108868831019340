// Copyright © 2021 Move Closer

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum LayoutBreakpoint {
  XS = 'xs',
  LG = 'lg'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum LayoutContainer {
  Bottom = 'bottom',
  Middle = 'middle',
  Top = 'top'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum LayoutSlot {
  Bottom = 'bottom',
  Main = 'main',
  Top = 'top'
}
