// Copyright © 2021 Move Closer

import { AnyObject, MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function fixRecordIds (toMap: Record<string, AnyObject> | null): Record<string, unknown> {
  if (toMap === null) {
    return {}
  }

  const record = { ...toMap }
  for (const id of Object.keys(toMap)) {
    record[id].id = id
  }

  return record
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function mapToRecord (toMap: AnyObject[] | null): Record<string, unknown> {
  const modules: Record<string, unknown> = {}
  for (const m of (toMap ?? [])) {
    if (!Object.prototype.hasOwnProperty.call(m, 'id')) {
      continue
    }
    modules[m.id] = m
  }

  return modules
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const dataStructureAdapterMap: MappingConfig = {
  containers: {
    type: MappingTypes.Function,
    value: item => Array.isArray(item.containers) ? mapToRecord(item.containers) : fixRecordIds(item.containers)
  },
  modules: {
    type: MappingTypes.Function,
    value: item => Array.isArray(item.modules) ? mapToRecord(item.modules) : fixRecordIds(item.modules)
  },
  structure: {
    type: MappingTypes.Function,
    value: item => {
      if (typeof item !== 'object' || item === null) {
        return {}
      }

      return item.structure ?? {}
    }
  }
}
