// Copyright © 2022 Move Closer

/**
 * Method to get proper pluralize option in English
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movwcloser.pl>
 */
export const pluralizationRulesEN = (choice: number, choicesLength: number): number => {
  if (choice === 0) {
    return 0
  }

  if (choice === 1) {
    return 1
  }

  if (choice > 1 && choice < 5) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}
