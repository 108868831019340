// Copyright © 2022 Move Closer

import { CanHaveColor, Module, ModuleContent, ModuleDriver, ResolvedLink, UnresolvedLink } from '../../../contracts'

import { BUTTON_MODULE_THEME } from './Button.config'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ButtonModule = Module<ButtonModuleContent, ModuleDriver.Button>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum ButtonLinkType {
  Auto = 'auto',
  Input = 'input'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ButtonModuleContent extends ModuleContent, CanHaveColor {
  /**
   * Link associated with the button.
   */
  link: ResolvedLink | UnresolvedLink | null

  /**
   * Determines whether the button should be outlined (as opposed to "filled").
   */
  outline?: boolean

  /**
   * The button's color theme.
   */
  theme?: ButtonModuleTheme

  /**
   * Determines whether the button text should be uppercase
   */
  uppercase?: boolean
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ButtonModuleTheme = typeof BUTTON_MODULE_THEME[keyof typeof BUTTON_MODULE_THEME]

/**
 * `ButtonModuleContent` with all related data already resolved.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ResolvedButtonModuleContent extends Omit<ButtonModuleContent, 'link'> {
  /**
   * Link associated with the button.
   */
  link: ResolvedLink | null
}
