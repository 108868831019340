// Copyright © 2022 Move Closer

import { HeadingLevel, ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { CardModule, CardModuleContent } from './Card.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const cardContentFactory = (): CardModuleContent => ({
  body: '',
  cropImage: false,
  heading: {
    text: '',
    level: HeadingLevel.H5
  },
  image: null,
  labels: [],
  link: null,
  showReadMoreBtn: false,
  withBorder: false
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const cardFactory: ModuleFactory<CardModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 4,
      isResizable: true,
      minColSpan: 4,
      minRowSpan: 3,
      maxColSpan: 12,
      maxRowSpan: 12,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<CardModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: cardContentFactory(),
      driver: ModuleDriver.Card
    }
  }
}
