// Copyright © 2021 Move Closer

import { QueryParams } from '@movecloser/front-core'

import { GlobalSearchResult } from '../../models/global-search-result'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const GlobalSearchRepositoryType = Symbol.for('IGlobalSearchRepository')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface IGlobalSearchRepository {
  /**
   * Fetches the search results from the API.
   *
   * @param query - Query params entered by the User.
   */
  load (query?: QueryParams): Promise<GlobalSearchResult[]>
}
