// Copyright © 2021 Move Closer

import { BootstrapImageProps } from '@movecloser/ui-core'
import { SrcSet } from '@movecloser/ui-core/lib/abstract/components/Image/Image.contracts'
import { uniqBy } from 'lodash'

import { ImageFile, ImageFileVariant, ImageRatio } from '../../models'

/**
 * Maps the passed-in `ImageFile` to an object implementing the `BootstrapImageProps` interface.
 *
 * @param imageFile - The `ImageFile` to map.
 * @param ratio - image ratio form predefined map
 * @param maxWidth {number} - image max width in pixels
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const toBootstrapImageProps = (imageFile: ImageFile, ratio: ImageRatio | null = null): BootstrapImageProps => {
  // Prepare the fallback object in case of any errors.
  let errorReturn = { alt: '', author: '', caption: '', src: '', srcset: '', lazy: true }

  // Check if the provided `imageFile` object contains its `srcset` property.
  if (
    typeof imageFile === 'undefined' ||
    imageFile === null
    // (
    //   imageFile.preset !== ImageFilePreset.Logo &&
    //   // FIXME - commented because condition form line 70 secure this conditions and otherwise can't get image preview in CMS PageBuilder
    //   (!Object.prototype.hasOwnProperty.call(imageFile, 'srcset') ||
    //   typeof imageFile.srcset !== 'object' ||
    //   imageFile.srcset === null ||
    //   Object.keys(imageFile.srcset).length === 0)
    // )
  ) {
    console.error('toBootstrapImageProps(): Broken [imageFile] object!\nFull [imageFile] object:', imageFile)
    return errorReturn
  }

  // Extract the required keys.
  const { alt = '', author = '', caption = '' } = imageFile

  // Update the fallback object with new data.
  errorReturn = { ...errorReturn, alt, author, caption }

  // // Resolve the current screen resolution.
  // const screenResolution: number = typeof window === 'undefined' ? 1920 : window.innerWidth
  //
  // // Resolve the available resolutions for the given image.
  // let availableResolutions: number[]
  // try {
  //   availableResolutions = Object.keys(imageFile.srcset).map(_ => +_)
  // } catch (error) {
  //   console.error('prepareImage(): Failed to resolve the list of available screen/image resolutions!', error)
  //   return errorReturn
  // }
  //
  // // If the `ImageFile` has no resolutions specified,
  // // return the `Image` with an empty `src` property.
  // if (availableResolutions.length === 0) {
  //   console.warn(
  //     `prepareImage(): The [srcset] property of the [ImageFile] of ID [${id}] is EMPTY!\nFull [ImageFile] object:`, imageFile
  //   )
  //   return errorReturn
  // }
  //
  // // Resolve the nearest available resolution to the current screen resolution.
  // // @see https://stackoverflow.com/a/19277804/11869579
  // // @author Joe Grund <grundjoseph@gmail.com>
  // const nearestResolution: number = availableResolutions.reduce((prev, curr) => {
  //   return (Math.abs(curr - screenResolution) < Math.abs(prev - screenResolution) ? curr : prev)
  // })

  /**
   * Value for the `<img>'s` `[src]` attribute.
   */
  let src: string

  if (
    typeof imageFile.srcset === 'undefined' ||
    !Array.isArray(imageFile.srcset) ||
    imageFile.srcset.length === 0
  ) {
    try {
      src = imageFile.url
    } catch (e) {
      console.warn(e)
      src = ''
    }

    // Return the complete object.
    return { alt, author, caption, src, lazy: true }
  }

  // Let's found a specific ratio.
  let found: ImageFileVariant[] = []
  if (ratio !== null) {
    found = imageFile.srcset.filter(v => {
      return (v.ratio || ImageRatio.Original) === ratio
    })
  }

  const sources: ImageFileVariant[] = found.length > 0 ? found : imageFile.srcset

  try {
    const middle: ImageFileVariant = sources[Math.floor(sources.length / 3)]
    src = middle.url
  } catch (error) {
    console.error('toBootstrapImageProps(): Failed to resolve the applicable [src]!', error)
    return errorReturn
  }

  // Resolve the value for the `<img>'s` `[srcset]` attribute.
  let srcset: SrcSet = {}

  try {
    srcset = uniqBy(sources ?? [], 'width')
      .reduce<SrcSet>((acc, curr) => ({
        ...acc, [curr.width]: curr.url
      }), {})
  } catch (error) {
    console.error('toBootstrapImageProps(): Failed to construct the [srcset]!', error)
  }

  // Return the complete object.
  return { alt, author, caption, src, srcset, lazy: true }
}
