// Copyright © 2021 Move Closer

import { BootstrapTheme } from '@movecloser/ui-core'

/**
 * Default alert's colour theme.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const UI_ALERT_DEFAULT_THEME: BootstrapTheme = BootstrapTheme.Info
