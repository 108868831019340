// Copyright © 2022 Move Closer

import { BootstrapImageProps } from '@movecloser/ui-core'

import { HasId } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ContainerBackgroundImage extends BootstrapImageProps {
  /**
   * Position/alignment of the image.
   * Should be a valid value for the `object-position` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/object-position
   */
  position?: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerBase {
  /**
   * Value for the `background-color` CSS property.
   */
  backgroundColor?: string

  /**
   * Determines whether the container should be active only on desktop-like screens.
   *
   * @default false
   */
  fullwidthOnMobile?: boolean

  /**
   * Container's grid gap.
   */
  gridGap: {
    /**
     * @default DEFAULT_CONTAINER_COLUMN_GAP
     * @see DEFAULT_CONTAINER_COLUMN_GAP
     */
    column: ContainerColumnGap

    /**
     * @default DEFAULT_CONTAINER_ROW_GAP
     * @see DEFAULT_CONTAINER_ROW_GAP
     */
    row: ContainerRowGap
  }

  /**
   * Container's vertical margins.
   */
  margin: {
    /**
     * @default DEFAULT_CONTAINER_MARGIN
     * @see DEFAULT_CONTAINER_MARGIN
     */
    bottom: ContainerMargin

    /**
     * @default DEFAULT_CONTAINER_MARGIN
     * @see DEFAULT_CONTAINER_MARGIN
     */
    top: ContainerMargin
  }

  /**
   * Container's vertical padding.
   */
  padding: {
    /**
     * @default DEFAULT_CONTAINER_PADDING
     * @see DEFAULT_CONTAINER_PADDING
     */
    bottom: ContainerPadding

    /**
     * @default DEFAULT_CONTAINER_PADDING
     * @see DEFAULT_CONTAINER_PADDING
     */
    top: ContainerPadding
  }

  /**
   * Determines whether the container is a wrapper for the 2x2 grid.
   *
   * @default false
   */
  twoByTwoLayout?: boolean

  /**
   * The width/type/mode of the container.
   *
   * @default DEFAULT_CONTAINER_WIDTH
   * @see DEFAULT_CONTAINER_WIDTH
   */
  width: ContainerWidth
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ContainerColumnGap {
  Large = 'large',
  None = 0,
  Unset = '',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerData extends ContainerBase, HasId {
  /**
   * Background image configuration.
   */
  backgroundImage?: ContainerBackgroundImage
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ContainerMargin {
  None = 0,
  Spacer1 = 1,
  Spacer1Negative = -1,
  Spacer2 = 2,
  Spacer2Negative = -2,
  Spacer3 = 3,
  Spacer3Negative = -3,
  Spacer4 = 4,
  Spacer4Negative = -4,
  Spacer5 = 5,
  Spacer5Negative = -5,
  Spacer6 = 6,
  Spacer6Negative = -6,
  Spacer7 = 7,
  Spacer7Negative = -7,
  Spacer8 = 8,
  Spacer8Negative = -8,
  Spacer9 = 9,
  Spacer9Negative = -9,
  Spacer10 = 10,
  Spacer10Negative = -10,
  Unset = '',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ContainerPadding {
  None = 0,
  Spacer1 = 1,
  Spacer2 = 2,
  Spacer3 = 3,
  Spacer4 = 4,
  Spacer5 = 5,
  Spacer6 = 6,
  Spacer7 = 7,
  Spacer8 = 8,
  Spacer9 = 9,
  Spacer10 = 10,
  Unset = '',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ContainerRowGap {
  None = 0,
  Spacer1 = 1,
  Spacer2 = 2,
  Spacer3 = 3,
  Spacer4 = 4,
  Spacer5 = 5,
  Spacer6 = 6,
  Spacer7 = 7,
  Spacer8 = 8,
  Spacer9 = 9,
  Spacer10 = 10,
  Unset = '',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum ContainerWidth {
  ExtraNarrow = 'extraNarrow',
  Narrow = 'narrow',
  Normal = 'normal',
  Wide = 'wide',
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/object-position
 */
export enum ImagePosition {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  BottomRight = 'bottom right',
  BottomLeft = 'bottom left',
  CenterRight = 'center right',
  TopRight = 'top right',
  TopLeft = 'top left',
  CenterLeft = 'center left',
  CenterTop = 'center top',
  CenterBottom = 'bottom center'
}
