// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { SearchResultsModule, SearchResultsModuleContent } from './SearchResults.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const searchResultsContentFactory = (): SearchResultsModuleContent => ({
  noResultsInfo: {
    heading: undefined,
    intro: undefined
  }
})

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const searchResultsFactory: ModuleFactory<SearchResultsModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<SearchResultsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.SearchResults,
      content: searchResultsContentFactory()
    }
  }
}
