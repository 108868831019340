// Copyright © 2022 Move Closer

import { Link } from '@movecloser/ui-core'

import { Heading, HorizontalAlignment } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum TileBehavior {
  Collapse = 'collapse',
  Link = 'link',
  None = 'none',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum TileIconColor {
  Green = 'green',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum TileIconPlacement {
  Left = 'left',
  Top = 'top',
}

/**
 * Interface describing the props object that the `<Tile>` molecule expects.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export interface TileProps {
  /**
   * Determines the component's target behavior.
   */
  behavior: string

  /**
   * Main content, with Markdown support (will be parsed to HTML).
   */
  body?: string

  /**
   * Determines whether the component should have its own border.
   */
  border: boolean

  /**
   * Determines whether the tile's content should be centered horizontally.
   */
  contentAlignment?: HorizontalAlignment

  /**
   * Configuration of the data circle.
   */
  dataCircle?: {
    /**
     * Text to render inside the circle.
     */
    content: string

    /**
     * Percentage of the circle to fill with color.
     *
     * @default 100
     */
    fillPercentage?: number
  }

  /**
   * Additional content, shown when the User presses the "read more" button
   * (with Markdown support (will be parsed to HTML)).
   */
  extendedBody?: string

  /**
   * Tile's heading.
   */
  heading: Heading

  /**
   * Heading's horizontal alignment.
   */
  headingAlignment?: HorizontalAlignment

  /**
   * Color of the icon.
   */
  iconColor?: TileIconColor

  /**
   * Name of the icon that is to be rendered.
   */
  iconName?: string

  /**
   * Defines the placement (position) of the icon.
   */
  iconPlacement: TileIconPlacement

  /**
   * Determines whether the component should be rendered
   * with a slightly larger `font-size` and more spacing inside.
   */
  large: boolean

  /**
   * Target link associated with the component.
   */
  link?: Link

  /**
   * CSS class that is to be attached to the link element.
   */
  linkClassName?: string

  /**
   * Determines whether the component should have rounded corners.
   */
  rounded: boolean

  /**
   * Determines whether the component should have its own shadow.
   */
  shadow: boolean

  /**
   * Determines the HTML tag that should be used as a component's root element.
   */
  tag?: string

  /**
   * Determines whether the component should have a transparent background.
   */
  transparent: boolean
}
