// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { JobOffersModuleVersion } from '../JobOffers.contracts'

/**
 * Registry that binds the `JobOffersModuleVersion` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const jobOffersModuleVersionComponentRegistry: Record<JobOffersModuleVersion, AsyncComponent> = {
  [JobOffersModuleVersion.Carousel]: () => import(
    /* webpackChunkName: "modules/shared" */
    './partials/Carousel/Carousel.vue'
  ),
  [JobOffersModuleVersion.List]: () => import(
    /* webpackChunkName: "modules/shared" */
    './partials/List/List.vue'
  ),
  [JobOffersModuleVersion.Table]: () => import(
    /* webpackChunkName: "modules/shared" */
    './partials/Table/Table.vue'
  )
}
