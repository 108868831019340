// Copyright © 2021 Move Closer

import { isFinite, isPlainObject, isString, isSymbol } from 'lodash'

import { log } from './log'

/**
 * Helper class that makes the usage of various registries a little bit safer.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export class Registry<Enum extends string | number | symbol, Value> {
  /**
   * Default key that should be used if the User-provided one hasn't been found in the registry.
   */
  private readonly defaultKey: Enum

  /**
   * The registry itself. Binds the `Enum` with the corresponding `Value`s.
   */
  public readonly registry: Record<Enum, Value>

  /**
   * @param registry - The registry itself. Binds the `Enum` with the corresponding `Values`.
   * @param defaultKey - Default key that should be used if the User-provided one hasn't been found in the registry.
   */
  constructor (
    registry: Record<Enum, Value>,
    defaultKey: Enum
  ) {
    if (!this.isValidRegistry(registry)) {
      throw new Error(`Provided [registry] is not valid! Received: ${JSON.stringify(registry)}`)
    }
    this.registry = registry

    if (!this.isValidKey(defaultKey)) {
      throw new Error(`Provided [defaultKey] is not valid! Received: ${defaultKey}`)
    }
    this.defaultKey = defaultKey
  }

  /**
   * Returns the value associated with the passed-in `key`.
   *
   * @param key - The key which value we want to get.
   */
  public getValue (key: Enum): Value {
    if (!this.isValidKey(key)) {
      log(`Provided [key] is not valid! Received: ${key}. Falling back to the default key (${this.defaultKey}).`, 'warn')
      key = this.defaultKey
    }

    return this.registry[key]
  }

  /**
   * Determines whether the passed-in `key` is a valid key
   * that can be used to key the current registry (#keyception or #keynosis, I know).
   *
   * @param key - The key to validate.
   */
  private isValidKey (key: unknown): key is Enum {
    return isString(key) || isFinite(key) || isSymbol(key)
  }

  /**
   * Determines whether the passed-in `registry` is valid.
   *
   * @param registry - The registry to validate.
   */
  private isValidRegistry (registry: unknown): registry is Record<Enum, Value> {
    return isPlainObject(registry)
  }
}
