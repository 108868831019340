










import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { DashmixBoxTabItem, DashmixIconName, ImageType } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../_abstract'
import { EmbedContent, EmbedModule, EmbedVersion } from '../Embed.contracts'
import { FormErrors } from '../../../molecules/FormErrors'
import { ModuleDriver, Picker, Related, RelatedType } from '../../../../contracts'
import { FileType, IFile, VideoFile } from '../../../../models'
import { EmbedYouTubePartialForm } from './versions'

/**
 * Component that contains a complete questionnaire needed to **EDIT** the `Embed`'s contents.
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<EmbedModuleForm>({
  name: 'EmbedModuleForm',
  components: {
    FormErrors
  },

  mounted (): void {
    this.$nextTick(() => {
      switch (this._version) {
        case EmbedVersion.YouTube:
          if (typeof this._content.autoplay === 'undefined') {
            this.toggleAutoplay(true)
          }
          this.findImageRelated()
          break
        // case EmbedVersion.Video:
        //   this.describeVideoFileRelated()
        //   this.findImageRelated()
      }
    })
  }
})
export class EmbedModuleForm extends AbstractModuleForm<EmbedModule> {
  /**
   * This property is responsible for disabling tabs if EmbedToken in MDE is editing
   * This property is passed from MDE Modal, but not used in PB
   *
   * @private
   */
  @Prop({ type: Boolean, required: false, default: false })
  private isEditMode!: boolean

  public readonly DashmixIconName = DashmixIconName

  /**
   * TODO: Move these regExp's to their own version forms
   * RegularExpressions to identify embed source
   */
  public readonly youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch?))\??v?=?([^#&?"]*).*/
  /**
   * @inheritDoc
   * @override
   */
  protected initialContent: EmbedContent = EmbedModuleForm.getInitialContent()

  /**
   * Title of the image.
   */
  public imgTitle: string = ''

  /**
   * Image.
   */
  public thumbnail: IFile | null = null

  /**
   * Active tab according to `_version`
   */
  public activeTab: string = 'youtube'

  /**
   * Selected related video
   */
  public video: VideoFile | null = null

  /**
   * Tabs
   *
   * @see DashmixBoxTabItem
   */
  public get embedOptions (): DashmixBoxTabItem[] {
    return [
      {
        component: EmbedYouTubePartialForm,
        props: {
          content: this._content,
          imgTitle: this.imgTitle,
          onAutoplayToggle: this.toggleAutoplay,
          onSelection: this.findThumbnailSource,
          onRemove: this.removeThumbnail,
          onVideoIdChange: this.onVideoIdChange,
          thumbnail: this.thumbnail
        },
        tab: {
          id: 'youtube',
          disabled: this.isTabDisabled,
          label: 'YouTube'
        }
      }
      // {
      //   component: EmbedVideoPartialForm,
      //   props: {
      //     content: this._content,
      //     onSelection: this.findVideoFileSource,
      //     onThumbnailSelection: this.findThumbnailSource,
      //     onRemove: this.removeVideoFile,
      //     onThumbnailRemove: this.removeThumbnail,
      //     file: this.video || {},
      //     imgTitle: this.imgTitle,
      //     thumbnail: this.thumbnail
      //   },
      //   tab: {
      //     id: 'video',
      //     disabled: this.isTabDisabled,
      //     label: 'Video'
      //   }
      // }
    ]
  }

  /**
   * Check whether module is editing and disable tab
   */
  public get isTabDisabled (): boolean {
    return (this.isEditMode && this._content.version === this._version)
  }

  /**
   * TODO: Description.
   */
  public checkType (data: string): void {
    if (!data) {
      return
    }

    const youtubeMatch = data.match(this.youtubeRegex)
    if (youtubeMatch) {
      this._version = EmbedVersion.YouTube
      this._content = {
        ...this._content,
        iframe: '',
        videoId: youtubeMatch[7]
      }
    } else {
      this._content.videoId = ''
      this._content.iframe = data
    }
  }

  /**
   * TODO: Description.
   */
  public findThumbnailSource (): void {
    this.pickRelated(Picker.File, this.setThumbnailSource)
  }

  public findVideoFileSource (): void {
    this.pickRelated(
      Picker.File,
      this.setVideoFileSource,
      this._content.video,
      { allowedMediaType: FileType.Video }
    )
  }

  /**
   * @inheritDoc
   * @override
   */
  public static getInitialConfig (): EmbedContent {
    return {
      driver: ModuleDriver.Embed,
      size: {
        colSpan: 2,
        rowSpan: 2,
        isResizable: true,
        minColSpan: 1,
        minRowSpan: 1,
        maxColSpan: 6
      },
      version: EmbedVersion.YouTube
    }
  }

  /**
   * @inheritDoc
   * @override
   */
  public static getInitialContent (): EmbedContent {
    return {
      autoplay: false,
      description: '',
      thumbnail: {} as Related<RelatedType.File>,
      video: {} as Related<RelatedType.File>
    }
  }

  /**
   * TODO: Description.
   */
  public hasImage (): boolean {
    return !!this._content.thumbnail && typeof this._content.thumbnail.value !== 'undefined'
  }

  /**
   * Check is obj is promise.
   */
  public isPromise (obj: any) {
    return obj instanceof Promise
  }

  /**
   * TODO: Description.
   */
  public removeThumbnail (): void {
    this._content.thumbnail = {} as Related<RelatedType.File>
  }

  public removeVideoFile (): void {
    this._content.video = {} as Related<RelatedType.File>
  }

  public onVideoIdChange (id: string) {
    this._content = {
      ...this._content,
      videoId: id
    }
  }

  public toggleAutoplay (auto: boolean): void {
    const content = this._content
    content.autoplay = auto
    this._content = content
  }

  protected getImageFromRelated (related: AnyObject) {
    if (Object.prototype.hasOwnProperty.call(related, 'alt')) {
      this.imgTitle = related.title
    }

    if (Object.prototype.hasOwnProperty.call(related, 'url')) {
      this.thumbnail = {
        type: ImageType.JPG,
        thumbnail: related.url
      }

      if (Object.prototype.hasOwnProperty.call(related, 'mime')) {
        this.thumbnail.type = related.mime
      }
    }
  }

  /**
   * Describes Video's related
   * @protected
   */
  protected describeVideoFileRelated (): void {
    if (!this._content.video || typeof this._content.video.value === 'undefined' ||
      typeof this._content.video.type === 'undefined') {
      return
    }
    const promise = this.relatedService.resolve(this._content.video)

    if (!this.isPromise(promise)) {
      const file = promise as AnyObject
      this.video = {
        ...file as any,
        fileDescription: file.title || file.caption || file.alt
      }
      return
    }

    promise.then((file: AnyObject) => {
      this.video = {
        ...file,
        fileDescription: file.title || file.url
      } as any
    }).catch((e) => {
      console.warn(e)
    })
  }

  /**
   * TODO: Description.
   */
  protected findImageRelated () {
    if (!this._content.thumbnail || typeof this._content.thumbnail.value === 'undefined' ||
      typeof this._content.thumbnail.type === 'undefined') {
      return
    }
    const related = this.relatedService.resolve(this._content.thumbnail, {})

    if (!this.isPromise(related)) {
      this.getImageFromRelated(related)
      return
    }

    related.then((related: AnyObject) => {
      this.getImageFromRelated(related)
    }).catch(error => {
      console.debug(error)
    })
  }

  /**
   * TODO: Description.
   */
  protected setThumbnailSource (source: Related<RelatedType.File>): void {
    this._content.thumbnail = source

    this.$nextTick(() => {
      this.findImageRelated()
    })
  }

  protected setVideoFileSource (source: Related<RelatedType.File>): void {
    this._content.video = source

    this.$nextTick(() => {
      this.describeVideoFileRelated()
    })
  }
}

export default EmbedModuleForm
