






























































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixIconName, Link } from '@movecloser/ui-core'
import { v4 as uuid } from 'uuid'

import {
  DescriptionOfLink,
  LinkFromForm,
  Picker,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../contracts'

import { RelatedPartial } from '../_abstract/RelatedPartial'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<LinkForm>({ name: 'LinkForm' })
export class LinkForm extends RelatedPartial<DescriptionOfLink> {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly canChangeType!: boolean

  /**
   * TODO: Documentation.
   */
  @PropSync('link', { type: Object, required: false, default: null })
  public _link!: LinkFromForm

  public readonly icons = DashmixIconName

  /**
   * TODO: Documentation.
   */
  public get isEmpty (): boolean {
    return this._link === null
  }

  /**
   * TODO: Documentation.
   */
  public get isRelatedLink (): boolean {
    return this._link !== null && 'relatedTarget' in this._link &&
      typeof this._link.relatedTarget !== 'undefined'
  }

  /**
   * TODO: Documentation.
   */
  public get isRelatedSet (): boolean {
    return this._link !== null && 'relatedTarget' in this._link &&
      this._link.relatedTarget !== null
  }

  /**
   * TODO: Documentation.
   */
  public get linkUUID (): string {
    return uuid().toString().substr(-6, 6)
  }

  public addLink (): void {
    this._link = LinkForm.createLink()
  }

  /**
   * TODO: Documentation.
   */
  public addRelated (): void {
    this._link = LinkForm.createRelatedLink()
  }

  /**
   * TODO: Documentation.
   */
  public addRelatedTarget (): void {
    if (this.isEmpty || !this.isRelatedLink) {
      return
    }

    this.pickRelated(Picker.Link, this.setRelatedLink, this.getRelatedSource())
  }

  /**
   * TODO: Documentation.
   */
  public clearLink (): void {
    this._link = null
    this.$emit('clear')
  }

  /**
   * TODO: Documentation.
   */
  public toggleLinkType (): void {
    const preserve = {
      label: this._link?.label,
      newTab: this._link?.newTab
    }

    if (this.isRelatedLink) {
      this._link = LinkForm.createLink(preserve)
    } else {
      this._link = LinkForm.createRelatedLink(preserve)
    }
  }

  /**
   * TODO: Documentation.
   */
  public static createLink (preserve: Partial<Link> = {}): Link {
    return {
      label: '',
      target: '',
      ...preserve
    }
  }

  public static createRelatedLink (preserve: Partial<Link> = {}): UnresolvedLink {
    return {
      label: '',
      relatedTarget: null,
      ...preserve
    }
  }

  protected getRelatedSource (): Related<RelatedType.Link> {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._link.relatedTarget
  }

  protected setRelatedLink (selected: Related<RelatedType.Link>): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._link.relatedTarget = selected
    this.resolveRelated()
  }
}

export default LinkForm
