// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { HorizontalAlignment } from '../../contracts'
import { log } from '../../support'

/**
 * Extendable component that's capable of receiving the `align` @Prop
 * and transforming it into the target CSS class using the specified enum-classname registry.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Alignmentable>({ name: 'Alignmentable' })
export class Alignmentable<Alignment extends string = HorizontalAlignment> extends Vue {
  /**
   * Determines the object's alignment.
   */
  @Prop({ type: String, required: false, default: HorizontalAlignment.Center })
  public readonly align!: Alignment

  /**
   * Determines the object's alignment on **desktop** screens.
   */
  @Prop({ type: String, required: false })
  public readonly alignDesktop?: Alignment

  /**
   * Registry that binds the `HorizontalAlignment` with the applicable CSS class.
   */
  public readonly alignmentClassNameRegistry: {
    base: { [key: string]: string }
    desktop: { [key: string]: string }
  } = {
    base: {
      [HorizontalAlignment.Center]: 'text-center',
      [HorizontalAlignment.Justify]: 'text-justify',
      [HorizontalAlignment.Left]: 'text-left',
      [HorizontalAlignment.Right]: 'text-right'
    },
    desktop: {
      [HorizontalAlignment.Center]: 'text-lg-center',
      [HorizontalAlignment.Justify]: 'text-lg-justify',
      [HorizontalAlignment.Left]: 'text-lg-left',
      [HorizontalAlignment.Right]: 'text-lg-right'
    }
  }

  /**
   * CSS class that handles the object's alignment.
   */
  public get alignmentClassName (): string {
    if (typeof this.align !== 'string') {
      return ''
    }

    const classNameBase: string = this.alignmentClassNameRegistry.base[this.align]
    const classNameDesktop: string = this.alignmentClassNameRegistry.desktop[this.alignDesktop ?? this.align]

    if (typeof classNameBase === 'undefined' && typeof classNameDesktop === 'undefined') {
      log(`Alignmentable.alignmentClassName(): There's no CSS class binding for the neither [base.${this.align}] nor [desktop.${this.alignDesktop}] alignment! Returning empty string!`, 'warn')
      return ''
    }

    return `${classNameBase} ${classNameDesktop}`
  }
}
