// Copyright © 2021 Move Closer

import { isFinite, isString } from 'lodash'

import { Description } from '../contracts'
import { log } from '../support'

import { File, FileType, isFile } from './file'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type DescriptionOfImageFile = Description & ImageFile

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export interface ImageFile extends File<FileType.Image> {
  /**
   * Alternative text for the image.
   */
  alt?: string

  /**
   * The author of the image (e.g. photographer).
   */
  author?: string

  /**
   * Longer description of the image.
   */
  caption?: string

  /**
   * Map that matches different screen resolutions
   * with the URLs of the corresponding image files.
   */
  srcset: ImageFileVariant[]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export interface ImageFileVariant {
  /**
   * Height of the image file (in px).
   */
  height: number|null

  /**
   * Define ratio of an image.
   */
  ratio?: ImageRatio

  /**
   * Absolute URL address of the image file.
   */
  url: string

  /**
   * Width of the image file (in px).
   */
  width: number
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum ImageRatio {
  HeroMobile = 'hero_mobile',
  HeroDesktop = 'hero_desktop',
  Original = 'original',
  Square = 'square'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 *
 * Note!: This map is only for informational purposes. Finale size of images if given rations are determines in API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImageRatioSizes: Record<ImageRatio, Array<Array<number>>> = {
  [ImageRatio.HeroDesktop]: [[1440, 550]],
  [ImageRatio.HeroMobile]: [[375, 300]],
  [ImageRatio.Original]: [],
  [ImageRatio.Square]: [[1100, 1100], [500, 500], [100, 100]]
}

/**
 * Determines whether the passed-in `input` object correctly implements the `ImageFile` interface.
 *
 * @param input - The object to verify.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isImageFile = (input: any): input is ImageFile => {
  // Validate the basic `File` interface compliance.
  if (!isFile(input, FileType.Image)) {
    const message: string = 'isImageFile(): The input object did NOT pass the [isFile()] type guard!'
    log([message, input], 'error')
    return false
  }

  // Remove (override) the type inference that came from the `isFile()` type guard.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input = input as any

  // Validate the presence of the required keys (not covered by the `isFile()` type guard).
  const requiredKeys = ['srcset']
  for (const requiredKey of requiredKeys) {
    if (typeof input[requiredKey] === 'undefined') {
      const message: string = `isImageFile(): [${requiredKey}] key is missing!`
      log([message, input], 'error')
      return false
    }
  }

  // Validate the value type of the `srcset` key.
  if (!Array.isArray(input.srcset)) {
    const message: string = `isImageFile(): Expected the [input.srcset] to be an [array], but got [${typeof input.srcset}]!`
    log([message, input], 'error')
    return false
  }

  // Validate the value of every item in the `input.srcset` array.
  for (const [index, item] of input.srcset.entries()) {
    // Validate the string keys.
    const stringKeys = ['url']
    for (const stringKey of stringKeys) {
      if (!isString(item[stringKey])) {
        const message: string = `isImageFile(): Expected [input.srcset[${index}].${stringKey}] to be a [string], but got [${typeof item[stringKey]}]!`
        log([message, input], 'error')
        return false
      }

      if (item[stringKey].length === 0) {
        const message: string = `isImageFile(): Value of the [input.srcset[${index}].${stringKey}] can NOT be empty!`
        log([message, input], 'error')
        return false
      }
    }

    // Validate the number keys.
    const numberKeys = ['height', 'width']
    for (const numberKey of numberKeys) {
      const isValidNumber: boolean = isFinite(item[numberKey])
      const isNull: boolean = item[numberKey] === null

      if (!isValidNumber && !isNull) {
        const message: string = `isImageFile(): Expected [input.srcset[${index}].${numberKey}] to be a [number], but got [${typeof item[numberKey]}]!`
        log(message, 'error')
        log([`[input.srcset[${index}]:`, JSON.parse(JSON.stringify(item))])
        return false
      }
    }

    // Validate the `width` key.
    if (item.width <= 0) {
      const message: string = `isImageFile(): Value of the [input.srcset[${index}].width] can NOT be less than or equal [0]!`
      log(message, 'error')
      log([`[input.srcset[${index}]:`, JSON.parse(JSON.stringify(item))])
      return false
    }
  }

  return true
}
