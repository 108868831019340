// Copyright © 2022 Move Closer

import { SwiperOptions } from 'swiper'

import { ModuleContent, ModuleDriver, ModuleWithVersion } from '../../../contracts'

import { TileModuleContent } from '../Tile'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type CarouselModule = ModuleWithVersion<
  CarouselModuleContent<TileModuleContent>,
  ModuleDriver.Carousel,
  CarouselModuleVersion
>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum CarouselModuleBreakpoint {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CarouselModuleContent<T> extends ModuleContent {
  /**
   * Array of slides to render.
   */
  slides: T[]

  /**
   * Swiper configuration.
   */
  swiperOptions?: {
    /**
     * @see SwiperOptions.breakpoints
     */
    breakpoints?: {
      [width: number]: Partial<Pick<SwiperOptions, 'slidesPerGroup' | 'slidesPerView'>>
    }

    /**
     * Color for swiper bullets.
     */
    bulletColor: CarouselBulletColor
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum CarouselModuleVersion {
  Tiles = 'tiles',
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum CarouselBulletColor {
  Primary = 'primary',
  White = 'white'
}
