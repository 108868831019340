
































import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { JobOffer } from '../../../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Offer>({
  name: 'Offer',
  components: { BootstrapButton, BootstrapLink }
})
export class Offer extends Vue {
  /**
   * @see JobOffer.departments
   */
  @Prop({ type: Array, required: false })
  public readonly departments?: JobOffer['departments']

  /**
   * @see JobOffer.city
   */
  @Prop({ type: String, required: false })
  public readonly city?: JobOffer['city']

  /**
   * @see JobOffer.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: true })
  public readonly openLinkInNewTab!: JobOffer['openLinkInNewTab']

  /**
   * @see JobOffer.title
   */
  @Prop({ type: String, required: true })
  public readonly title!: JobOffer['title']

  /**
   * @see JobOffer.url
   */
  @Prop({ type: String, required: true })
  public readonly url!: JobOffer['url']

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Inject({ from: 'isMobile', default: false })
  private readonly isMobile!: boolean

  /**
   * Determines whether the component has been provided with the correct `city` @Prop.
   */
  public get hasCities (): boolean {
    return typeof this.city !== 'undefined' && this.city.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `departments` @Prop.
   */
  public get hasDepartments (): boolean {
    return typeof this.departments !== 'undefined' &&
      Array.isArray(this.departments) &&
      this.departments.length > 0
  }

  /**
   * Determines whether links will be open in new tab.
   */
  public get newTab (): boolean {
    return !this.isMobile && !!this.openLinkInNewTab
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasTitle && this.hasUrl
  }

  /**
   * Determines whether the component has been provided with the correct `title` @Prop.
   */
  private get hasTitle (): boolean {
    return typeof this.title === 'string' && this.title.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `url` @Prop.
   */
  private get hasUrl (): boolean {
    return typeof this.url === 'string' && this.url.length > 0
  }
}

export default Offer
