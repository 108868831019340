
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { BootstrapButton, BootstrapIcon, BootstrapLink } from '@movecloser/ui-core'

import { isLink } from '../../../../../../../../support'

import { JobOffersModuleContent } from '../../../../../JobOffers.contracts'

/**
 *
 * Component to render for EmptyJobOffers for JobOffersModuleVersion.List
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'EmptyJobOffersListPlaceholder',
  components: {
    BootstrapIcon,
    BootstrapButton,
    BootstrapLink,
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../../../atoms/UiHeading/UiHeading.vue'
    )
  }
})
export class EmptyJobOffersListPlaceholder extends Vue {
  /**
   * @see JobOffersModuleContent.readMoreLink
   */
  @Prop({ type: Object, required: false })
  public readonly readMoreLink?: JobOffersModuleContent['readMoreLink']

  /**
   * Determines whether the component has been provided with the correct `readMoreLink` @Prop.
   */
  public get hasReadMoreLink (): boolean {
    return isLink(this.readMoreLink)
  }
}
export default EmptyJobOffersListPlaceholder
