// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfJobOffer, DescriptionOfJobOffersFeed, jobOfferAdapterMap } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractSetDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a set of job offers.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class JobOffersFeedDriver extends AbstractSetDriver<DescriptionOfJobOffersFeed, DescriptionOfJobOffer> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = jobOfferAdapterMap

  /**
   * @inheritDoc
   */
  protected childType: RelatedType = RelatedType.JobOffer

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.JobOffer]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.JobOffersFeed
}
