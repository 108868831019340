
























































































































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'
import { merge } from 'lodash'

import { DescriptionOfImageFile } from '../../../models'
import { isRelated, ResolvesRelatedAsync } from '../../../services'
import {
  Identifier,
  InjectionCallback,
  PickerCallback,
  Related,
  RelatedType,
  UnresolvedImage
} from '../../../contracts'

import { ImageForm, MapSelector } from '../../molecules'
import { RelatedPartial } from '../../molecules/_abstract'
import {
  ContainerColumnGap,
  ContainerMargin,
  ContainerPadding,
  ContainerRowGap,
  ContainerWidth,
  FormFieldset,
  ImagePosition
} from '../../atoms'

import { BackgroundColorPalette } from './Container.helpers'
import { ContainerContent } from './Container.contracts'
import { createContainerContent } from './Container.factory'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component<ContainerForm>({
  name: 'ContainerForm',
  components: { ImageForm, MapSelector, FormFieldset },
  created (): void {
    this.setInitialContent()
  },
  mounted () {
    this.isImgPositionPredefined()

    if (isRelated(this._content.backgroundImage)) {
      this.resolveRelated()
    }
  }
})
export class ContainerForm extends RelatedPartial<DescriptionOfImageFile> {
  /**
   * Container's configuration.
   */
  @PropSync('content', { type: Object, required: true })
  public _content!: ContainerContent

  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Function capable of resolving the injections from the IoC container.
   */
  @Prop({ type: Function, required: true })
  private readonly resolveInjection!: InjectionCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync

  public initialContent = createContainerContent()

  public readonly BackgroundColorPalette = BackgroundColorPalette
  public readonly ContainerColumnGap = ContainerColumnGap
  public readonly ContainerMargin = ContainerMargin
  public readonly ContainerPadding = ContainerPadding
  public readonly ContainerRowGap = ContainerRowGap
  public readonly ContainerWidth = ContainerWidth
  public readonly ImagePosition = ImagePosition

  public hasPredefinedImgPosition = true

  public get hasImage (): boolean {
    return !(!this._content.backgroundImage || !isRelated(this._content.backgroundImage))
  }

  public get image (): UnresolvedImage | null {
    if (!this._content.backgroundImage) {
      return null
    }
    return {
      image: this._content.backgroundImage
    }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy.backgroundImage
      this._content = contentCopy
      return
    }
    this._content = {
      ...this._content,
      backgroundImage: image.image
    }

    this.$nextTick(() => {
      this.resolveRelated()
    })
  }

  /**
   * @inheritDoc
   */
  protected getRelatedSource (): Related<RelatedType.File> | undefined {
    if (this._content === null || this._content.backgroundImage === null ||
      typeof this._content.backgroundImage === 'undefined') {
      return undefined
    }

    return this._content.backgroundImage
  }

  protected isImgPositionPredefined (): void {
    if (!this._content.backgroundImagePosition) {
      return
    }

    const predefinedList = Object.values(ImagePosition)

    this.hasPredefinedImgPosition = predefinedList.includes(
      (this._content.backgroundImagePosition as ImagePosition)
    )
  }

  /**
   * Merges `this.initialContent` with the current value of `this._content`.
   */
  protected setInitialContent (): void {
    this._content = { ...merge(this.initialContent, this._content) }
  }

  public sortBackgroundColorOptions (_: DashmixSelectItem, second: DashmixSelectItem): number {
    if (second.value !== BackgroundColorPalette.None) {
      return -1
    }
    return 1
  }

  public sortMarginOptions (first: DashmixSelectItem, second: DashmixSelectItem): number {
    if (!first.value && !second.value) {
      return 0
    } else if (!first.value) {
      return -1
    } else if (!second.value) {
      return 1
    }

    if ((second.value as Identifier) < 0 || (first.value as Identifier) < 0) {
      return (second.value as Identifier) - (first.value as Identifier)
    }

    return (first.value as Identifier) - (second.value as Identifier)
  }
}

export default ContainerForm
