// Copyright © 2021 Move Closer

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanBeAlignedHorizontally<Alignment = HorizontalAlignment> {
  /**
   * Determines the horizontal alignment of the given "object".
   */
  align?: Alignment

  /**
   * Determines the horizontal alignment of the given "object" on the **desktop** screens.
   */
  alignDesktop?: Alignment
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CanBeAlignedVertically<Alignment = VerticalAlignment> {
  /**
   * Determines the vertical alignment of the given "object".
   */
  align?: Alignment

  /**
   * Determines the vertical alignment of the given "object" on the **desktop** screens.
   */
  alignDesktop?: Alignment
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum HorizontalAlignment {
  Center = 'center',
  Justify = 'justify',
  Left = 'left',
  Right = 'right',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum VerticalAlignment {
  Bottom = 'bottom',
  Center = 'center',
  Stretch = 'stretch',
  Top = 'top',
}
