// Copyright © 2021 Move Closer

import { Heading, Module, ModuleContent, ModuleDriver } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface DividerEntryData {
  /**
   * @see Divider.content
   */
  content: string

  /**
   * @see Divider.headingLevel
   */
  headingLevel?: number

  /**
   * @see Divider.widthMultiplier
   */
  widthMultiplier?: number
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface Entry {
  /**
   * Data to be rendered by the entry's component.
   */
  data: DividerEntryData | EventEntryData

  /**
   * The type of the entry.
   */
  type: EntryType
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum EntryType {
  Divider = 'divider',
  Event = 'event',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface EventEntryData {
  /**
   * @see Event.body
   */
  body?: string

  /**
   * @see Event.heading
   */
  heading?: Heading
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type TimelineModule = Module<TimelineModuleContent, ModuleDriver.Timeline>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface TimelineModuleContent extends ModuleContent {
  /**
   * Array of entries to render inside the timeline.
   */
  entries: Entry[]
}
