// Copyright © 2021 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion } from '../../../contracts'

import { UiDataCircleSize } from '../../atoms'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type StatsModule = ModuleWithVersion<
  StatsModuleCircleVersionContent | StatsModuleTextVersionContent,
  ModuleDriver.Stats,
  StatsModuleVersion
>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface StatsModuleBaseContent extends ModuleContent {
  /**
   * Label for the presented value.
   *
   * @see value
   */
  label: string

  /**
   * The value being presented. Main field of the module.
   */
  value: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface StatsModuleCircleVersionContent extends StatsModuleBaseContent {
  /**
   * The size of the circle.
   *
   * @default UiDataCircleSize.Small
   */
  size?: UiDataCircleSize
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface StatsModuleTextVersionContent extends StatsModuleBaseContent {
  /**
   * Data source. Will be rendered as a smaller text and surrounded with a parentheses.
   */
  source?: string

  /**
   * Position of the value.
   *
   * @default 'top'
   */
  valuePosition?: 'left' | 'top'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum StatsModuleVersion {
  Circle = 'circle',
  Text = 'text',
}
