// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { BlogFeedModule, BlogFeedModuleContent } from './BlogFeed.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const blogFeedContentFactory = (): BlogFeedModuleContent => ({ posts: null })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const blogFeedFactory: ModuleFactory<BlogFeedModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<BlogFeedModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: blogFeedContentFactory(),
      driver: ModuleDriver.BlogFeed
    }
  }
}
