// Copyright © 2022 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { Link } from '@movecloser/ui-core'

import { Description } from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type DescriptionOfNavigation = Description & Navigation

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface Navigation {
  name: string
  slot: string
  links: NavigationItem[]
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface NavigationItem extends Link, Description {
  children: NavigationItem[]
  icon?: string
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface NavigationRawItem {
  icon?: string
  label: string
  strategy?: OpenStrategy
  target: NavigationTarget | null
  children: NavigationRawItem[]
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface NavigationTarget {
  type: Target
  link: string
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum OpenStrategy {
  Blank = 'blank',
  Self = 'self'
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum Target {
  External = 'external',
  Internal = 'internal'
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
const mapLinks = (links?: NavigationRawItem[]): NavigationItem[] => {
  if (!links || links.length === 0) {
    return []
  }

  return links.map((linkItem: NavigationRawItem) => ({
    isExternal: linkItem.target?.type === Target.External,
    label: linkItem.label,
    icon: linkItem.icon,
    target: linkItem.target?.link,
    newTab: linkItem.strategy === OpenStrategy.Blank,
    children: mapLinks(linkItem.children)
  } as NavigationItem))
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const navigationAdapterMap: MappingConfig = {
  name: 'name',
  slot: 'slot',
  links: {
    type: MappingTypes.Function,
    value: item => mapLinks(item.links)
  }
}
