














































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  BootstrapBadge,
  BootstrapButton,
  BootstrapImage,
  BootstrapImageProps,
  BootstrapLink,
  BootstrapTheme,
  Link
} from '@movecloser/ui-core'

import { clamp } from '../../../extensions'

import { UiHeading, UiMarkdown } from '../../atoms'
import { CardBackgroundColor } from '../../modules/Card'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<Card>({
  name: 'Card',
  components: {
    BootstrapBadge,
    BootstrapButton,
    BootstrapImage,
    BootstrapLink,
    UiHeading,
    UiMarkdown
  },
  directives: { clamp }
})
export class Card extends Vue {
  /**
   * Value for the `background` CSS property of the root `<figure>` element.
   */
  @Prop({ type: String, required: false, default: 'transparent' })
  public readonly background!: CardBackgroundColor

  /**
   * Card's body (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: true })
  public body!: string

  /**
   * Determines whether the image should be cropped to the default shape.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly expandable!: boolean

  /**
   * Determines whether the image should be cropped to the default shape.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly cropImage!: boolean

  /**
   * Card's heading.
   */
  @Prop({ type: String, required: false })
  public readonly heading?: string

  /**
   * Card's heading.
   */
  @Prop({ type: Number, required: false, default: 4 })
  public readonly headingLevel!: number

  /**
   * Card's image.
   */
  @Prop({ type: Object, required: false })
  public readonly image?: BootstrapImageProps

  /**
   * Array of labels to render.
   */
  @Prop({ type: Array, required: false })
  public readonly labels?: string[]

  /**
   * Link associated with the card.
   * User will be redirected to this target when he clicks the card.
   */
  @Prop({ type: Object, required: true })
  public readonly link!: Link

  /**
   * Determines whether link should have noFollow prop.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isNoFollowLink!: Link

  /**
   * Determines body's inner text max number of lines
   */
  @Prop({ type: Number, required: false })
  public readonly maxBodyLines?: number

  /**
   * Determines whether the card's body should end with the "read more" button.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showReadMoreBtn!: boolean

  /**
   * Determines whether the card's body should be wrapped around with a border.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly withBorder!: boolean

  /**
   * Determines whether to render v-html in heading
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly renderHTMLHeading!: boolean

  /**
   * Determines boostrap theme.
   */
  public readonly BootstrapTheme = BootstrapTheme

  public get backgroundClass (): string | undefined {
    switch (this.background) {
      case CardBackgroundColor.Black:
        return '--bg-black'
      case CardBackgroundColor.Gray:
        return '--bg-gray'
      case CardBackgroundColor.Transparent:
        return
      case CardBackgroundColor.White:
        return '--bg-white'
    }
  }

  public get headingLevelComponent (): string {
    return `h${this.headingLevel}`
  }
}

export default Card
