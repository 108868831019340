// Copyright © 2022 Move Closer

import { assign, createMachine, Typestate } from 'xstate'

import { MachineContext, MachineEvent, MachineEventObject, MachineState } from './NewsletterForm.contracts'

/**
 * Simple action that updates the properties in the machine's context using the invoked event's payload.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const updateContext = assign<MachineContext, MachineEventObject>(
  (context, event) => ({
    errors: event.errors ?? context.errors,
    formData: event.formData ?? context.formData
  })
)

/**
 * Finite state machine capable of controlling the state of the `<NewsletterForm>` molecule.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const newsletterFormMachine = createMachine<
  MachineContext,
  MachineEventObject,
  Typestate<MachineContext> & { value: MachineState }
>({
  id: 'newsletterFormMachine',
  context: {
    errors: null,
    formData: null
  },
  initial: MachineState.DataEntry,
  states: {
    [MachineState.DataEntry]: {
      on: {
        [MachineEvent.SubmissionAccepted]: MachineState.Success,
        [MachineEvent.ValidationFailed]: MachineState.DataEntry,
        [MachineEvent.SubmissionRejected]: MachineState.Error
      },
      exit: updateContext
    },
    [MachineState.Error]: {
      on: {
        [MachineEvent.TryAgain]: MachineState.DataEntry
      },
      exit: updateContext
    },
    [MachineState.Success]: {}
  }
}, {
  actions: { updateContext }
})
