// Copyright © 2021 Move Closer

import { Identifier } from '../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 * @author Michał Wawer <michal.wawer@movecloser.pl>
 */
export interface Label {
  color: LabelColor
  id: Identifier
  name: string
  slug: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export enum LabelColor {
  Black = 'black',
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}
