// Copyright © 2022 Move Closer

/**
 * Injection key that should be used to inject/provide the search callback function.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const SEARCH_CALLBACK_INJECTION_KEY = Symbol.for('NavbarModuleUiSearchCallback')

/**
 * Event fired by the EventBus service when the navbar gets toggled (changes its state from hidden to visible and vice versa)
 *
 * @see NavbarDesktop.onStateChanged
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const TOGGLE_NAVBAR_STATE_EVENT = 'ui:navbar.stateChanged'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type LocalesHiddenTextType = { [key: string]: string }

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum LocaleText {
  Polish = 'pl',
  English = 'en'
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const localesHiddenText: LocalesHiddenTextType = {
  [LocaleText.English]: 'English',
  [LocaleText.Polish]: 'Polski'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const flagsRegistry: Record<string, NodeRequire> = {
  [LocaleText.Polish]: require('./partials/NavbarDesktop/assets/poland-flag.png'),
  [LocaleText.English]: require('./partials/NavbarDesktop/assets/english-flag.png')
}
