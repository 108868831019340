










import { AbstractSelectControlOption, BootstrapImageProps, Link } from '@movecloser/ui-core'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { NavigationItem } from '../../../../models'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { Identifier } from '../../../../contracts'

/**
 * Presentational component for the `NavbarModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NavbarModuleUiPresentation>({
  name: 'NavbarModuleUiPresentation',
  components: {
    NavbarDesktop: () => import(
      /* webpackChunkName: "frame" */
      './partials/NavbarDesktop/NavbarDesktop.vue'
    ),
    NavbarMobile: () => import(
      /* webpackChunkName: "frame" */
      './partials/NavbarMobile/NavbarMobile.vue'
    )
  }
})
export class NavbarModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Links to render on the bottom of the component.
   */
  @Prop({ type: Array, required: false })
  public readonly bottomNav?: NavigationItem[]

  /**
   * Image to be rendered as the "navbar brand" (the logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: BootstrapImageProps

  /**
   * The text to be rendered next to the "navbar brand" (the logotype).
   */
  @Prop({ type: String, required: false })
  public readonly brandText?: string

  /**
   * Link associated with the "navbar brand" (the logotype).
   */
  @Prop({ type: Object, required: false })
  public readonly brandLink?: Link

  /**
   * Link to the erecruiter form
   */
  @Prop({ type: Object, required: false })
  public readonly erecruiterLink!: Link

  /**
   * Currently-active locale.
   */
  @PropSync('currentLocale', { type: Number, required: true })
  public _currentLocale!: Identifier

  /**
   * An instance of the `EventBus` service.
   */
  @Prop({ type: Object, required: true })
  private readonly eventBus!: IEventbus

  /**
   * Available locales for the User to choose from.
   */
  @Prop({ type: Array, required: false })
  public readonly locales?: AbstractSelectControlOption[]

  /**
   * Links to render on the top of the component, on the left side.
   */
  @Prop({ type: Array, required: false })
  public readonly topLeftNav?: NavigationItem[]

  /**
   * Links to render on the top of the component, on the right side.
   */
  @Prop({ type: Array, required: false })
  public readonly topRightNav?: NavigationItem[]
}

export default NavbarModuleUiPresentation
