// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { LabelsModule, LabelsModuleContent } from './Labels.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const labelsContentFactory = (): LabelsModuleContent => ({ labels: [] })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const labelsFactory: ModuleFactory<LabelsModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<LabelsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: labelsContentFactory(),
      driver: ModuleDriver.Labels
    }
  }
}
