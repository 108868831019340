// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'
import { EmbedContent, EmbedModule, EmbedVersion } from './Embed.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const embedContentFactory = (): EmbedContent => ({
  autoplay: undefined,
  description: undefined,
  thumbnail: undefined,
  video: undefined,
  videoId: undefined
})

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const embedFactory: ModuleFactory<EmbedModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      rowSpan: 1
    }
  },
  createModule (): ModuleBase<EmbedModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: embedContentFactory(),
      driver: ModuleDriver.Embed,
      version: EmbedVersion.YouTube
    }
  }
}
