



































































import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { DashmixIconName, IFile } from '@movecloser/ui-core'

import { EmbedVersionAbstractForm } from '../version.abstract.form'

/**
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */

@Component({
  name: 'EmbedVideoPartialForm'
})
export class EmbedVideoPartialForm extends EmbedVersionAbstractForm {
  @Prop({ type: String, required: false, default: '' })
  public imgTitle!: string

  @Prop({ type: Function, required: true })
  public onSelection!: () => void

  @Prop({ type: Function, required: true })
  public onThumbnailRemove!: () => void

  @Prop({ type: Function, required: true })
  public onThumbnailSelection!: () => void

  @Prop({ type: Function, required: true })
  public onRemove!: () => void

  @Prop({ type: Object, required: true, default: {} })
  public file!: AnyObject

  @Prop({ type: Object, required: false, default: null })
  public thumbnail!: IFile | null

  /**
   * Regular expression to identify file type
   *
   * @private
   */
  protected readonly regex = /\.(mov|avi|wmv|flv|3gp|mp4|mpg|ogg)$/

  public readonly icons = DashmixIconName

  /**
   * Determines whether selected file matches with constrained regex
   */
  public get isVideo (): boolean {
    if (!this.file || !this.file.url) {
      return false
    }

    return this.file.url.match(this.regex) !== null
  }

  /**
   * Checks whether content contains related file
   */
  public get hasImage (): boolean {
    return !!this._content.thumbnail && typeof this._content.thumbnail.value !== 'undefined'
  }

  /**
   * Checks if file has some key/value
   */
  public get hasFile (): boolean {
    return Object.prototype.hasOwnProperty.call(this.file, 'fileDescription') ||
      Object.prototype.hasOwnProperty.call(this.file, 'type')
  }

  /**
   * Checks whether pdf related is present in `content`
   */
  public get hasVideo (): boolean {
    return !!this._content.video && typeof this._content.video.value !== 'undefined'
  }
}

export default EmbedVideoPartialForm
