// Copyright © 2021 Move Closer

import { isPlainObject } from 'lodash'

import { Identifier, Related, RelatedType } from '../../contracts'
import { isValidEnumValue } from '../../support'

/**
 * Returns the `Related` object that does **NOT** point to any actual data.
 *
 * ⚠️ Please, be aware that this function has been created to serve mainly as a workaround
 * to swiftly bypass TypeScript's type checking (e.g. when you want to **unset** a particular related data).
 *
 * @param type - The type of the related data you want to get in the return.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getNonExistentRelated = <RType extends RelatedType>(type: RType): Related<RType> => {
  return { type, value: -1 }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const isIdentifier = (value: unknown): value is Identifier => {
  return typeof value === 'number' || (typeof value === 'string' && !isNaN(parseInt(value)))
}

/**
 * Determines whether the passed-in `input` correctly implements the `Related` interface.
 *
 * @param input - The input to check.
 * @param [type] - The strictly defined `RelatedType` to validate the input against.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isRelated = (input: any, type?: RelatedType): input is Related => {
  if (!isPlainObject(input)) {
    return false
  }

  if (
    !Object.prototype.hasOwnProperty.call(input, 'type') ||
    typeof input.type !== 'string' ||
    input.type.length === 0 ||
    !isValidEnumValue(input.type, RelatedType)
  ) {
    return false
  }

  if (typeof type !== 'undefined' && input.type !== type) {
    return false
  }

  return !(!Object.prototype.hasOwnProperty.call(input, 'value') || !isIdentifier(input.value))
}
