// Copyright © 2022 Move Closer

import { DirectiveOptions, VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

let handleOutsideClick: (e: Event) => void

/**
 * Vue-closable 0.0.3
 *
 * Copyright 2019, Taha Shashtari
 */
export const closable: DirectiveOptions = {
  bind (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e: Event) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude = [] } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach((refName: string) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl && vnode.context) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]

          if (excludedEl) {
            // Excluded element is Array of elements
            // So we need to check if its contains e.target
            if (Array.isArray(excludedEl)) {
              // eslint-disable-next-line
              // @ts-ignore
              if (excludedEl.contains(e.target as Node)) {
                clickedOnExcludedEl = true
              }
              // Excluded element is Vue Component
              // So we need to check if it is the same as target
            } else if ('$el' in excludedEl && excludedEl.$el === e.target) {
              clickedOnExcludedEl = true
              // Excluded element is HTML element
              // So we need to check if it is the same as target
            } else if (excludedEl === e.target) {
              clickedOnExcludedEl = true
            }
          }
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target as Node) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        if (typeof handler === 'function') {
          handler()
        } else if (vnode.context) {
          // eslint-disable-next-line
          // @ts-ignore
          vnode.context[handler]()
        }
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    // document.addEventListener('touchend', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    // document.removeEventListener('touchend', handleOutsideClick)
  }
}
