// Copyright © 2021 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type TabsModule = ModuleWithVersion<TabsModuleContent, ModuleDriver.Tabs, TabsModuleVersion>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type TabsModuleContainersRegistry = { [id: string]: HTMLDivElement }

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface TabsModuleContent extends ModuleContent {
  /**
   * Array of tabs to render.
   */
  tabs: Array<{
    /**
     * ID of the container that the module will be in control of.
     */
    containerId: string

    /**
     * Tab's label.
     */
    label: string
  }>
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum TabsModuleVersion {
  Scrollspy = 'scrollspy',
  Switch = 'switch',
}
