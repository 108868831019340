// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfSocialPost } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single social post.
 *
 * @see HashtagWallModule
 * @see HashtagWallModuleForm
 * @see HashtagWallModuleUi
 * @see HashtagWallModuleUiPresentation
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class SocialPostDriver extends AbstractTypeDriver<DescriptionOfSocialPost, DescriptionOfSocialPost> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.SocialPost
}
