// Copyright © 2021 Move Closer

import { HorizontalAlignment, VerticalAlignment } from '../../../contracts'
import { Registry } from '../../../support'

/**
 * Default module's alignment within the grid's X-axis.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_MODULE_HORIZONTAL_ALIGNMENT: HorizontalAlignment = HorizontalAlignment.Justify

/**
 * Default module's alignment within the grid's Y-axis.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_MODULE_VERTICAL_ALIGNMENT: VerticalAlignment = VerticalAlignment.Top

/**
 * Registry the binds the `HorizontalAlignment` with the applicable values for the `justify-self` CSS property.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const horizontalAlignmentJustifySelfValueRegistry = new Registry<HorizontalAlignment, string>({
  [HorizontalAlignment.Center]: 'center',
  [HorizontalAlignment.Justify]: 'stretch',
  [HorizontalAlignment.Left]: 'start',
  [HorizontalAlignment.Right]: 'end'
}, DEFAULT_MODULE_HORIZONTAL_ALIGNMENT)

/**
 * Registry the binds the `VerticalAlignment` with the applicable values for the `align-self` CSS property.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const verticalAlignmentAlignSelfValueRegistry = new Registry<VerticalAlignment, string>({
  [VerticalAlignment.Bottom]: 'end',
  [VerticalAlignment.Center]: 'center',
  [VerticalAlignment.Stretch]: 'stretch',
  [VerticalAlignment.Top]: 'start'
}, DEFAULT_MODULE_VERTICAL_ALIGNMENT)
