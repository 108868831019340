// Copyright © 2021 Move Closer

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@moveclosep.pl>
 */
import { iconsRegistry } from '../../../config/icons'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@moveclosep.pl>
 */
export const SocialMediaOptions = {
  FacebookF: iconsRegistry.FacebookF,
  YouTube: iconsRegistry.YouTube,
  LinkedIn: iconsRegistry.LinkedIn
}
