// Copyright © 2022 Move Closer

import { Injectable } from '@movecloser/front-core'

import { AnyDescription, Description, Related, RelatedType } from '../../contracts'

import {
  AggregatesRelated,
  ExposesRelatedRecord,
  PossibleTypeDriver,
  RelatedRecord,
  RelatedTypeDriverRegistry
} from './related.contracts'
import { isRelated } from './related.helpers'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Injectable()
export abstract class AbstractRelatedService implements AggregatesRelated, ExposesRelatedRecord {
  /**
   * Record of `RelatedTypeDrivers`.
   */
  protected drivers: RelatedTypeDriverRegistry = {}

  /**
   * Record of all related data.
   */
  protected record: RelatedRecord = {}

  constructor (drivers: RelatedTypeDriverRegistry, record?: RelatedRecord) {
    this.drivers = drivers

    if (record) {
      this.record = record
    }
  }

  /**
   * @inheritDoc
   */
  public getRecord (): RelatedRecord {
    return this.record
  }

  /**
   * @inheritDoc
   */
  public storeRelated (record: RelatedRecord): void {
    for (const [key, value] of Object.entries(record)) {
      if (key in this.record && typeof this.record[key as RelatedType] !== 'undefined') {
        this.record[key as RelatedType] = { ...this.record[key as RelatedType], ...value }
      } else {
        this.record[key as RelatedType] = value
      }
    }
  }

  /**
   * Resolves driver based on the passed-in `RelatedType`.
   *
   * @param type - `RelatedType` associated with the desired driver.
   */
  protected resolveDriver <DataDescription extends (Description | Description[]) = AnyDescription> (
    type: RelatedType
  ): PossibleTypeDriver<DataDescription> {
    const found = this.drivers[type]

    if (typeof found === 'undefined') {
      throw new Error(`RelatedService.resolveDriver(): Unsupported related type [${type}] given.`)
    }

    // @ts-expect-error - TMP
    return found
  }

  /**
   * Validates the passed-in `related` argument against the `Related` interface.
   *
   * @param related - The related object to verify.
   *
   * @throws Error - if the passed-in object fails the verification.
   *
   * @see isRelated
   */
  protected validateRelated (related: Related): void {
    if (!isRelated(related)) {
      throw new Error(`Provided [related] argument is NOT compliant with the [Related] interface! Value of the [related] argument: ${JSON.parse(JSON.stringify(related))}.`)
    }
  }
}
