// Copyright © 2022 Move Closer

import { Color, ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'

import { BUTTON_MODULE_THEME } from './Button.config'
import { ButtonModule, ButtonModuleContent } from './Button.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const buttonContentFactory = (): ButtonModuleContent => ({
  color: Color.Body,
  link: null,
  outline: false,
  theme: BUTTON_MODULE_THEME.Primary
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const buttonFactory: ModuleFactory<ButtonModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<ButtonModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: buttonContentFactory(),
      driver: ModuleDriver.Button
    }
  }
}
