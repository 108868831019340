// Copyright © 2021 Move Closer

import { mapModel, MappingConfig } from '@movecloser/front-core'

import { Description, DescriptionOfSet, Identifier, RelatedType } from '../../../contracts'

import { DriverConfig, RelatedRecord, RelatedTypeDriver, ResolvesDriver } from '../related.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export abstract class AbstractTypeDriver<
  DataDescription extends Description,
  ResolvedData = DataDescription
> implements RelatedTypeDriver<DataDescription, ResolvedData> {
  /**
   * Adapter that is used by a driver.
   */
  protected abstract adapterMap: MappingConfig

  /**
   * Types that a driver depends on.
   */
  protected abstract dependencies: RelatedType[]

  /**
   * Key that stores RelatedType.
   */
  protected abstract key: RelatedType

  /**
   * @inheritDoc
   */
  public canDescribe (id: string, record: RelatedRecord): boolean {
    if (!this.hasTypeEntries(record)) {
      return false
    }

    return this.hasRelated(id, record)
  }

  /**
   * @inheritDoc
   */
  public dependentTypes (): RelatedType[] {
    return this.dependencies
  }

  /**
   * @inheritDoc
   */
  public describe (id: string, record: RelatedRecord): DataDescription {
    this.throwWhenMissing(id, record)

    return mapModel<DataDescription>(
      { ...record[this.key]?.[`${id}`] },
      this.adapterMap,
      Object.keys(this.adapterMap).length === 0
    )
  }

  /**
   * @inheritDoc
   *
   * @param id
   * @param record
   * @param config - Has to be defined (even if it's not used at all)
   *   for the later compatibility with the extending classes.
   * @param driverResolver - Has to be defined (even if it's not used at all)
   *   for the later compatibility with the extending classes.
   */
  public resolve (
    id: string,
    record: RelatedRecord,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    config: DriverConfig,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    driverResolver?: ResolvesDriver
  ): ResolvedData {
    this.throwWhenMissing(id, record)

    return mapModel<ResolvedData>(
      { ...record[this.key]?.[`${id}`] },
      this.adapterMap,
      Object.keys(this.adapterMap).length === 0
    )
  }

  /**
   * Determine if there's a related in the record.
   * @param id
   * @param record
   * @protected
   */
  protected hasRelated (id: string, record: RelatedRecord): boolean {
    const dictionary = record[this.key]
    if (!dictionary) {
      return false
    }

    return (dictionary[`${id}`] && dictionary[`${id}`] !== null)
  }

  /**
   * Determine of there are entries of given type.
   * @param record
   * @protected
   */
  protected hasTypeEntries (record: RelatedRecord): boolean {
    return !!record[this.key]
  }

  /**
   * Throws when related not found.
   * @param id
   * @param record
   * @protected
   */
  protected throwWhenMissing (id: string, record: RelatedRecord): void {
    if (!this.hasTypeEntries(record)) {
      throw new Error(
        `RelatedService: There are no entries for the [${this.key}] type in the record!`
      )
    }

    if (!this.hasRelated(id, record)) {
      throw new Error(
        `RelatedService: There are no entries for the [${id}] ID & [${this.key}] in the record!`
      )
    }
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export abstract class AbstractSetDriver<SetDescription extends DescriptionOfSet, ResolvedData>
  extends AbstractTypeDriver<SetDescription, ResolvedData[]> {
  /**
   * Type of children element of set.
   */
  protected abstract childType: RelatedType

  /**
   * @inheritDoc
   */
  public resolve (
    id: string,
    record: RelatedRecord,
    config: DriverConfig,
    driverResolver: ResolvesDriver
  ): ResolvedData[] {
    this.throwWhenMissing(id, record)

    const set: SetDescription = this.describe(id, record)

    // Check if the output is as expected.
    if (!Array.isArray(set.items)) {
      throw new Error(
        `AbstractSetDriver.resolve(): Failed to resolve items for the [${this.key}] of a given ID [${id}]!`
      )
    }

    const childDriver = driverResolver(this.childType)

    return set.items.reduce<ResolvedData[]>((acc: ResolvedData[], curr: Identifier) => {
      if (!childDriver.canDescribe(`${curr}`, record)) {
        return acc
      }

      return [
        ...acc,
        mapModel<ResolvedData>(
          childDriver.resolve(`${curr}`, record, config, driverResolver),
          this.adapterMap,
          Object.keys(this.adapterMap).length === 0
        )
      ]
    }, [])
  }
}
