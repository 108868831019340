// Copyright © 2022 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion, Related, RelatedType } from '../../../contracts'

import { UiContainer } from '../../atoms'

import { HeadingAddon, LinkAddon, ResolvedLinkAddon, ResolvedSearchAddon, SearchAddon, TextAddon } from './addons'
import { BootstrapImageProps } from '@movecloser/ui-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ChangeImagePayload {
  index: number
  deleted: BootstrapImageProps
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type HeroAddons = TextAddon | HeadingAddon | LinkAddon | SearchAddon

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type HeroModule = ModuleWithVersion<
  HeroModuleBasicVersionContent | HeroModuleSliderVersionContent | HeroModuleAnimationVersionContent,
  ModuleDriver.Hero,
  HeroModuleVersion
>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HeroModuleAddon<Type = HeroModuleAddonType> {
  /**
   * The type of the addon.
   */
  type: Type
  id: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum HeroModuleAddonType {
  Heading = 'heading',
  Link = 'link',
  Search = 'search',
  Text = 'text',
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface HeroModuleAnimationContent {
  /**
   * Array of addons to render.
   */
  addons: HeroModuleAddon[]

  /**
   * Duration of animation in seconds
   */
  animationDuration?: number

  /**
   * Number of columns in animated background
   */
  columns?: number

  /**
   * Photo gallery which will be used for animated module background.
   */
  gallery: Related<RelatedType.Gallery> | null

  /**
   * Number of rows in animated background
   */
  rows?: number
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type HeroModuleAnimationGridSetup = Omit<HeroModuleAnimationContent, 'gallery'| 'addons'>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HeroModuleBaseContent extends ModuleContent {
  /**
   * The width of the inner container.
   *
   * @see UiContainer.width
   */
  containerWidth: UiContainer['width']

  /**
   * Determines whether the breadcrumbs should be shown.
   */
  showBreadcrumbs: boolean

  /**
   * Determines whether module should have extra space in bottom
   */
  hasBottomSpacing?: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type HeroModuleAnimationVersionContent = HeroModuleBaseContent & HeroModuleAnimationContent

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type HeroModuleBasicVersionContent = HeroModuleBaseContent & HeroModuleSlideContent

/**
 * Interface that describes the contents of a single slide in the `Hero` module.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HeroModuleSlideContent {
  /**
   * Array of addons to render.
   */
  addons: HeroModuleAddon[]

  /**
   * Slide's background image.
   */
  backgroundImage: Related<RelatedType.File> | null
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HeroModuleSliderVersionContent extends HeroModuleBaseContent {
  /**
   * Array of slides to render.
   */
  slides: HeroModuleSlideContent[]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum HeroModuleVersion {
  Basic = 'basic',
  Slider = 'slider',
  Animation = 'animation'
}

/**
 * `HeroModuleAddons` with all related data already resolved.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type ResolvedHeroModuleAddon = HeadingAddon | TextAddon | ResolvedLinkAddon | ResolvedSearchAddon

/**
 * `HeroModuleSlideContent` with all related data already resolved.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ResolvedHeroModuleSlideContent {
  /**
   * @see HeroModuleSlideContent.addons
   */
  addons: ResolvedHeroModuleAddon[]

  /**
   * @see HeroModuleSlideContent.backgroundImage
   */
  backgroundImage: BootstrapImageProps
}
