// Copyright © 2021 Move Closer

import { Description, HasIdentifier } from '../contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface DescriptionOfSocialPost extends Description, SocialPost {}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export type ResolvedSocialPost = DescriptionOfSocialPost

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum SocialPostSource {
  Facebook = 'facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Twitter = 'twitter',
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
export interface SocialPost extends HasIdentifier {
  /**
   * Direct URL address of the post's cover image.
   */
  coverImage: string

  /**
   * Post's description.
   */
  desc: string

  /**
   * Post's source social media.
   */
  source: SocialPostSource

  /**
   * Post's title.
   */
  title: string

  /**
   * URL address of the post.
   */
  url: string
}
