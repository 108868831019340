// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { UiDataCircleSize } from '../../atoms'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import {
  StatsModule,
  StatsModuleCircleVersionContent,
  StatsModuleTextVersionContent,
  StatsModuleVersion
} from './Stats.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const statsContentFactory = (): StatsModuleCircleVersionContent | StatsModuleTextVersionContent => ({
  label: '',
  size: UiDataCircleSize.Big,
  value: ''
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const statsFactory: ModuleFactory<StatsModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<StatsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: statsContentFactory(),
      driver: ModuleDriver.Stats,
      version: StatsModuleVersion.Circle
    }
  }
}
