









import { AsyncComponent } from 'vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { interpret } from 'xstate'

import { Colorable } from '../../../extensions'

import { MachineEvent, MachineEventData, MachineState } from './NewsletterForm.contracts'
import { newsletterFormMachine } from './NewsletterForm.machine'
import { newsletterFormMachineStateComponentRegistry } from './NewsletterForm.config'

/**
 * @emits submit - When the User submits the form.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NewsletterForm>({
  name: 'NewsletterForm',
  created (): void {
    this.startMachine()
  }
})
export class NewsletterForm extends Mixins<Colorable>(Colorable) {
  /**
   * Optional heading.
   */
  @Prop({ type: String, required: false })
  public readonly heading?: string

  /**
   * Optional intro text (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: false })
  public readonly intro?: string

  /**
   * Determines whether legend is visible.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLegendHidden?: boolean

  /**
   * Determines legend text.
   */
  @Prop({ type: String, required: false, default: '' })
  public readonly legendText?: string

  /**
   * An instance of the finite state machine.
   */
  private readonly machine = interpret(newsletterFormMachine)

  /**
   * Current state of the machine.
   */
  private state = newsletterFormMachine.initialState

  /**
   * Vue component that should be rendered.
   */
  public get component (): AsyncComponent {
    return newsletterFormMachineStateComponentRegistry[this.state.value as MachineState]
  }

  /**
   * Sends a new event to the state machine.
   *
   * @param event - The type of the event being fired.
   * @param [payload] - Additional event payload.
   */
  public send (event: MachineEvent, payload?: MachineEventData): void {
    this.machine.send(event, payload)
  }

  /**
   * Starts the finite state machine.
   */
  private startMachine (): void {
    this.machine
      .onTransition(state => { this.state = state })
      .start()
  }
}

export default NewsletterForm
