













import { DashmixSelectItem } from '@movecloser/ui-core'
import { compereFunction } from './MapSelector.contracts'
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component({ name: 'MapSelector' })
export class MapSelector extends Vue {
  @PropSync('value')
  public _value!: string | number | boolean

  @Prop({ type: String, required: false, default: '' })
  public readonly label!: string

  @Prop({ type: String, required: true })
  public readonly name!: string

  @Prop({ type: Object, required: true })
  public readonly options!: Record<string, unknown>

  @Prop({ type: String, required: false, default: '' })
  public readonly placeholder!: string

  @Prop({ type: String, required: false, default: '' })
  public readonly translationKey!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly smallLabel!: boolean

  @Prop({ type: Function, required: false })
  public readonly sortMethod?: compereFunction

  public get id (): string {
    return `map-selector-${this.name}`
  }

  public get optionsMap (): DashmixSelectItem[] {
    const options = Object.entries(this.options)
      .filter(([name]) => isNaN(Number(name)))
      .map(([name, value]) => ({
        label: this.translationKey ? this.$t(`${this.translationKey}.${name}`).toString() : name,
        value
      }))

    if (!this.sortMethod) {
      return options
    }

    return options.sort(this.sortMethod)
  }
}

export default MapSelector
