// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { log } from '../../../support'
import { PickerCallback, Related } from '../../../contracts'
import { ResolvesRelated, ResolvesRelatedAsync } from '../../../services'

import { FormAddBtn } from '../../atoms'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<RelatedPartial<unknown>>({
  components: { FormAddBtn },
  created () {
    if (this.isRelatedSet) {
      this.describeRelated()
      this.resolveRelated()
    }
  }
})
export class RelatedPartial<Data> extends Vue {
  /**
   * Handles picker selection
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Related Service instance
   */
  @Prop({ type: Object, required: true })
  protected readonly relatedService!: ResolvesRelated | ResolvesRelatedAsync

  /**
   * TODO: Documentation.
   */
  @Prop({ type: Boolean, required: false, default: true })
  protected readonly showControls!: boolean

  /**
   * Resolved related
   */
  public resolved: Data | null = null

  /**
   * Description of passed-in related
   */
  public described: Data | null = null

  /**
   * Checks where related is set
   */
  public get isRelatedSet (): boolean {
    return false
  }

  /**
   * State of related (resolved/unresolved)
   */
  public get isRelatedResolved (): boolean {
    return this.resolved !== null
  }

  /**
   * Describes related
   */
  protected describeRelated (): void {
    const relatedSource = this.getRelatedSource()

    if (typeof relatedSource === 'undefined') {
      log('RelatedPartial.describeRelated(): [relatedSource] is [undefined]!', 'error')
      return
    }

    // @ts-expect-error - These signatures are not compatible with each other, that's true, but at the same time we
    // provide applicable paths handling both of the possible cases (@see - `if/else` statement below).
    const result = this.relatedService.describe<Data>(relatedSource)

    if (result instanceof Promise) {
      result.then(content => { this.described = content })
    } else {
      this.described = result
    }
  }

  /**
   * TODO: Documentation.
   */
  protected resolveRelated (): void {
    const relatedSource = this.getRelatedSource()

    if (typeof relatedSource === 'undefined') {
      log('RelatedPartial.resolveRelated(): [relatedSource] is [undefined]!', 'error')
      return
    }

    // @ts-expect-error - These signatures are not compatible with each other, that's true, but at the same time we
    // provide applicable paths handling both of the possible cases (@see - `if/else` statement below).
    const result = this.relatedService.resolve(relatedSource)

    if (result instanceof Promise) {
      result.then(content => { this.resolved = content })
    } else {
      this.resolved = result
    }
  }

  /**
   * TODO: Documentation.
   */
  protected getRelatedSource (): Related | undefined {
    throw new Error('RelatedPartial: There\'s no implementation of [getRelatedSource] method!')
  }
}
