// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { QuoteModule, QuoteModuleContent } from './Quote.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const quoteContentFactory = (): QuoteModuleContent => ({ body: '' })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const quoteFactory: ModuleFactory<QuoteModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<QuoteModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: quoteContentFactory(),
      driver: ModuleDriver.Quote
    }
  }
}
