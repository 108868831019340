// Copyright © 2022 Move Closer

/**
 * Logs the passed-in message to the console.
 *
 * @param message - The content (message) to be logged.
 * @param [level="info"] - Information level.
 * @param [logOnServer=true] - Whether the output should be visible in the Node server's console, too.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 */
export const log = (message: unknown, level: LoggerLevel = 'info', logOnServer?: boolean): void => {
  if (
    process.env.VUE_APP_DEBUG_CONSOLE !== 'true' ||
    (typeof window === 'undefined' && !logOnServer)
  ) {
    return
  }

  if (Array.isArray(message)) {
    // eslint-disable-next-line no-console
    console[level](...message)
  } else {
    // eslint-disable-next-line no-console
    console[level](message)
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type LoggerLevel = 'debug' | 'error' | 'info' | 'log' | 'warn'

// /**
//  * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
//  */
// export type Logger = (message: unknown, level?: LoggerLevel, logOnServer?: boolean) => void/**/

// /**
//  * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
//  */
// enum Level {
//   Debug = 'debug',
//   Error = 'error',
//   Info = 'info',
//   Log = 'log',
//   Warn = 'warn'
// }

// /**
//  * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
//  */
// const levelsMap = {
//   [Level.Debug]: [...Object.values(Level)],
//   [Level.Error]: [Level.Error],
//   [Level.Info]: [Level.Error, Level.Info, Level.Warn],
//   [Level.Log]: [Level.Error, Level.Info, Level.Log, Level.Warn],
//   [Level.Warn]: [Level.Error, Level.Warn]
// }
