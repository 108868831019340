// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'
import { log } from '../../support'

/**
 * Breakpoints generated by Bootstrap.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
enum Breakpoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

/**
 * Extendable component that's capable of reading the current screen's width
 * and determining whether the current device is mobile- or desktop-like.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Responsive>({
  name: 'Responsive',
  mounted (): void {
    this.readBreakpoints()
  }
})
export class Responsive extends Vue {
  /**
   * Registry that binds the breakpoint name with the screen width associated with it.
   */
  public breakpointNameWidthRegistry: Record<Breakpoint, number> | null = null

  /**
   * Determines whether the current screen width falls within the desktop-like dimensions.
   */
  public get isDesktop (): boolean {
    if (!this.isSafe) {
      return false
    }

    // @ts-expect-error // The null-checking is handled by the `hasBreakpoints()` getter.
    return window.innerWidth >= this.breakpointNameWidthRegistry[Breakpoint.LG]
  }

  /**
   * Determines whether the current screen width falls within the mobile-like dimensions.
   */
  public get isMobile (): boolean {
    if (!this.isSafe) {
      return true
    }

    return !this.isDesktop
  }

  /**
   * Determines whether the current screen width falls within the phone-like dimensions.
   */
  public get isPhone (): boolean {
    if (!this.isSafe) {
      return true
    }

    // @ts-expect-error // The null-checking is handled by the `hasBreakpoints()` getter.
    return window.innerWidth < this.breakpointNameWidthRegistry[Breakpoint.MD]
  }

  /**
   * Determines whether the current screen width falls within the tablet-like dimensions.
   */
  public get isTablet (): boolean {
    if (!this.isSafe) {
      return false
    }

    // @ts-expect-error // The null-checking is handled by the `hasBreakpoints()` getter.
    return window.innerWidth >= this.breakpointNameWidthRegistry[Breakpoint.MD] &&
      // @ts-expect-error // The null-checking is handled by the `hasBreakpoints()` getter.
      window.innerWidth < this.breakpointNameWidthRegistry[Breakpoint.LG]
  }

  /**
   * Resolves the value of the CSS property for the passed-in breakpoint.
   *
   * @param breakpoint - The breakpoint which value we want to get from CSS.
   */
  private static getBreakpointValueFromCSS (breakpoint: string): number {
    const propertyName = `--breakpoint-${breakpoint}`

    let width: string = getComputedStyle(document.documentElement).getPropertyValue(propertyName)

    if (width.length === 0) {
      throw new Error(`Responsive.getBreakpointValueFromCSS(): Failed to get the value for the [${propertyName}] CSS property!`)
    }

    width = width.replace('px', '')
    return +width
  }

  /**
   * Determines whether the breakpoints have been successfully resolved from the CSS.
   */
  private get hasBreakpoints (): boolean {
    return this.breakpointNameWidthRegistry !== null
  }

  /**
   * Determines whether it's safe for the component to work
   * with the `window` object and the breakpoints registry.
   */
  private get isSafe (): boolean {
    return !this.$isServer && this.hasBreakpoints
  }

  /**
   * Reads the Bootstrap's CSS variables and stores them under the `breakpoints` property.
   */
  private readBreakpoints (): void {
    if (this.$isServer) {
      return
    }

    try {
      this.breakpointNameWidthRegistry = Object.values(Breakpoint)
        .reduce<Record<Breakpoint, number>>((acc, curr) => {
          return { ...acc, [curr]: Responsive.getBreakpointValueFromCSS(curr) }
        }, {} as Record<Breakpoint, number>)
    } catch (error) {
      log('Responsive.readBreakpoints(): Failed to construct the [breakpointNameWidthRegistry]!', 'error')
      log(['Error details', error], 'error')
    }
  }
}
