// Copyright © 2022 Move Closer

import {
  AnyModule,
  CanBeAlignedInGrid,
  HorizontalAlignment,
  ModuleBase,
  ModuleFactory,
  ModuleSize,
  VerticalAlignment
} from '../../../contracts'

/**
 * The abstract factory.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const abstractModuleFactory: ModuleFactory = {

  /**
   * @inheritDoc
   */
  createInitialSize (): ModuleSize {
    return {
      colSpan: 3,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 12,
      minColSpan: 1,
      minRowSpan: 1,
      rowSpan: 1
    }
  },

  /**
   * @inheritDoc
   */
  createModule (): ModuleBase<AnyModule> {
    throw new Error('AbstractModuleForm.createModule(): This field **HAS TO** be overridden by the subclass!')
  },

  /**
   * @inheritDoc
   */
  getResizeStep (): number {
    return 1
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export const getDefaultModuleAlignment = (): CanBeAlignedInGrid['alignment'] => ({
  x: {
    mobile: HorizontalAlignment.Justify,
    desktop: HorizontalAlignment.Justify
  },
  y: {
    mobile: VerticalAlignment.Top,
    desktop: VerticalAlignment.Top
  }
})
