





























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Navigation, Pagination, Swiper } from 'swiper'

import { JobOffer } from '../../../../../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Carousel>({
  name: 'Carousel',
  components: {
    Offer: () => import(
      /* webpackChunkName: "modules/shared" */
      './partials/Offer/Offer.vue'
    )
  },
  mounted (): void {
    // We have to use `setTimeout` here, because for some reason Swiper initialised immediately
    // lacks some styles. This may be caused by the page's own styles still being loaded at
    // the time, what causes the page's contents to "jump". Swiper relies on the bounding boxes
    // of the elements it operated on, so when they're constantly changing their position
    // and dimensions, Swiper's initialisation can't be performed safely and with success.
    setTimeout(() => { this.initSwiper() }, 100)
  }
})
export class Carousel extends Vue {
  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffer.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: true })
  public readonly openLinkInNewTab!: JobOffer['openLinkInNewTab']

  /**
   * Reference to the `.swiper-container` HTML element.
   */
  @Ref('swiperContainer')
  private readonly swiperContainerRef!: HTMLDivElement | null

  /**
   * Swiper instance.
   */
  private swiper: Swiper | null = null

  /**
   * Determines whether the component has been provided with the correct `offers` @Prop.
   */
  public get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }

  /**
   * Initialises the Swiper.
   */
  private initSwiper (): void {
    if (typeof window === 'undefined' || this.swiperContainerRef === null) {
      return
    }

    Swiper.use([Navigation, Pagination])

    this.swiper = new Swiper(this.swiperContainerRef, {
      breakpoints: {
        0: {
          slidesPerView: 1.05,
          spaceBetween: 50
        },
        768: {
          slidesPerView: 2.7,
          spaceBetween: -50,
          allowTouchMove: false
        },
        992: {
          slidesPerView: 4.2,
          spaceBetween: -200,
          allowTouchMove: false
        }
      },
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        renderBullet: (index, className) => {
          return `<li class="${className} position-relative">
               <button class="bullet-btn --small"><span class="sr-only">Slajd ${index + 1}</span></button>
            </li>`
        },
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets'
      }
    })
  }
}

export default Carousel
