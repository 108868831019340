// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfJobOffer } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single job offer.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class JobOfferDriver extends AbstractTypeDriver<DescriptionOfJobOffer> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.JobOffer
}
