// Copyright © 2021 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { GlobalSearchResultsModule } from './GlobalSearchResults.contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'

export const globalSearchResultsFactory: ModuleFactory<GlobalSearchResultsModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<GlobalSearchResultsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: {},
      driver: ModuleDriver.GlobalSearchResults
    }
  },
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      rowSpan: 12,
      isResizable: false
    }
  }
}
