


































import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { isLink } from '../../../../../../support'
import { JobOffer } from '../../../../../../models'

import { JobOffersModuleContent } from '../../../JobOffers.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<List>({
  name: 'List',
  components: {
    BootstrapButton,
    BootstrapLink,
    Offer: () => import(
      /* webpackChunkName: "modules/shared" */
      './partials/Offer/Offer.vue'
    ),
    Empty: () => import(
      /* webpackChunkName: "modules/shared" */
      './partials/Empty/Empty.vue'
    ),
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class List extends Vue {
  /**
   * @see JobOffersModuleContent.headingContent
   */
  @Prop({ type: String, required: false })
  public readonly headingContent?: JobOffersModuleContent['headingContent']

  /**
   * @see JobOffersModuleContent.headingLevel
   */
  @Prop({ type: Number, required: false })
  public readonly headingLevel?: JobOffersModuleContent['headingLevel']

  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffer.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: true })
  public readonly openLinkInNewTab!: JobOffer['openLinkInNewTab']

  /**
   * @see JobOffersModuleContent.readMoreLink
   */
  @Prop({ type: Object, required: false })
  public readonly readMoreLink?: JobOffersModuleContent['readMoreLink']

  /**
   * Determines whether the component has been provided with the correct `headingContent` @Prop.
   */
  public get hasHeadingContent (): boolean {
    return typeof this.headingContent === 'string' && this.headingContent.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `headingLevel` @Prop.
   */
  public get hasHeadingLevel (): boolean {
    return typeof this.headingLevel === 'number' && this.headingLevel >= 1 && this.headingLevel <= 6
  }

  /**
   * Determines whether the component has been provided with the correct `offers` @Prop.
   */
  public get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `readMoreLink` @Prop.
   */
  public get hasReadMoreLink (): boolean {
    return isLink(this.readMoreLink)
  }
}

export default List
