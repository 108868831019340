










































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { AnyModule } from '../../../contracts'

import { ContentAlignment, VersionSelector } from './partials'
import { ModuleVersions, nameOfModule } from './ModuleSettings.contracts'

/**
 * Component capable to render module's form along with its:
 * version, title, visibility and alignment
 *
 * Component to be used only in `FormDrawer`
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'ModuleSettings',
  components: {
    ContentAlignment,
    VersionSelector
  }
})
export class ModuleSettings extends Vue {
  @PropSync('module', { type: Object, required: true })
  public _module!: AnyModule

  /**
   * List of errors to render
   */
  @Prop({ type: Array, required: false })
  public readonly errors?: string[]

  /**
   * Registry of versions of any module
   */
  @Prop({ type: Object, required: true })
  public readonly moduleVersionsRegistry!: ModuleVersions<string>

  /**
   * Check whether module has errors to render
   */
  public get hasErrors (): boolean {
    return typeof this.errors !== 'undefined' && Array.isArray(this.errors) && this.errors.length > 0
  }

  /**
   * Check whether module should have version
   */
  public get hasVersion (): boolean {
    return Object.prototype.hasOwnProperty.call(this._module, 'version') && this._module.version !== ''
  }

  /**
   * Gets appropriate name module based on driver
   */
  public get moduleName (): string {
    return nameOfModule(this._module.driver)
  }
}
export default ModuleSettings
