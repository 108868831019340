// Copyright © 2021 Move Closer

import { ICollection, QueryParams } from '@movecloser/front-core'

import { JobOffer } from '../../models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const JobOffersRepositoryType = Symbol.for('IJobOffersRepository')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface IJobOffersRepository {
  /**
   * Fetches the job offers from the API.
   *
   * @param query - Query params entered by the User.
   */
  load (query?: QueryParams): Promise<ICollection<JobOffer>>
}
