// Copyright © 2022 Move Closer

import { Registry } from '../../../support'

import {
  ContainerColumnGap,
  ContainerMargin,
  ContainerPadding,
  ContainerRowGap,
  ContainerWidth
} from './UiContainer.contracts'

/**
 * Injection key used to bind the `@Provide` and `@Inject` decorators that share and control the information about
 * the containers' mounting status.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ALL_CONTAINERS_MOUNTED_INJECTION_KEY = Symbol.for('ALL_CONTAINERS_MOUNTED_INJECTION_KEY')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_CONTAINER_COLUMN_GAP: ContainerColumnGap = ContainerColumnGap.Unset

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_CONTAINER_MARGIN: ContainerMargin = ContainerMargin.None

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_CONTAINER_PADDING: ContainerPadding = ContainerPadding.Spacer3

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_CONTAINER_ROW_GAP: ContainerRowGap = ContainerRowGap.Unset

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_CONTAINER_WIDTH: ContainerWidth = ContainerWidth.Normal

/**
 * Registry that binds the `ContainerColumnGap` with the applicable CSS class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const containerColumnGapClassNameRegistry = new Registry<ContainerColumnGap, string>({
  [ContainerColumnGap.Large]: '--column-gap-large',
  [ContainerColumnGap.None]: '--column-gap-0',
  [ContainerColumnGap.Unset]: ''
}, DEFAULT_CONTAINER_COLUMN_GAP)

/**
 * Registry that binds the `ContainerMargin` with the applicable CSS class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const containerMarginClassNameRegistry = new Registry<
  ContainerMargin, { bottom: string; top: string }
>({
  [ContainerMargin.None]: { bottom: 'mb-0', top: 'mt-0' },
  [ContainerMargin.Spacer1]: { bottom: 'mb-1', top: 'mt-1' },
  [ContainerMargin.Spacer1Negative]: { bottom: 'mb-n1', top: 'mt-n1' },
  [ContainerMargin.Spacer2]: { bottom: 'mb-2', top: 'mt-2' },
  [ContainerMargin.Spacer2Negative]: { bottom: 'mb-n2', top: 'mt-n2' },
  [ContainerMargin.Spacer3]: { bottom: 'mb-3', top: 'mt-3' },
  [ContainerMargin.Spacer3Negative]: { bottom: 'mb-n3', top: 'mt-n3' },
  [ContainerMargin.Spacer4]: { bottom: 'mb-4', top: 'mt-4' },
  [ContainerMargin.Spacer4Negative]: { bottom: 'mb-n4', top: 'mt-n4' },
  [ContainerMargin.Spacer5]: { bottom: 'mb-5', top: 'mt-5' },
  [ContainerMargin.Spacer5Negative]: { bottom: 'mb-n5', top: 'mt-n5' },
  [ContainerMargin.Spacer6]: { bottom: 'mb-6', top: 'mt-6' },
  [ContainerMargin.Spacer6Negative]: { bottom: 'mb-n6', top: 'mt-n6' },
  [ContainerMargin.Spacer7]: { bottom: 'mb-7', top: 'mt-7' },
  [ContainerMargin.Spacer7Negative]: { bottom: 'mb-n7', top: 'mt-n7' },
  [ContainerMargin.Spacer8]: { bottom: 'mb-8', top: 'mt-8' },
  [ContainerMargin.Spacer8Negative]: { bottom: 'mb-n8', top: 'mt-n8' },
  [ContainerMargin.Spacer9]: { bottom: 'mb-9', top: 'mt-9' },
  [ContainerMargin.Spacer9Negative]: { bottom: 'mb-n9', top: 'mt-n9' },
  [ContainerMargin.Spacer10]: { bottom: 'mb-10', top: 'mt-10' },
  [ContainerMargin.Spacer10Negative]: { bottom: 'mb-n10', top: 'mt-n10' },
  [ContainerMargin.Unset]: { bottom: '', top: '' }
}, DEFAULT_CONTAINER_MARGIN)

/**
 * Registry that binds the `ContainerPadding` with the applicable CSS class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const containerPaddingClassNameRegistry = new Registry<
  ContainerPadding, { bottom: string; top: string }
>({
  [ContainerPadding.None]: { bottom: 'pb-0', top: 'pt-0' },
  [ContainerPadding.Spacer1]: { bottom: 'pb-1', top: 'pt-1' },
  [ContainerPadding.Spacer2]: { bottom: 'pb-2', top: 'pt-2' },
  [ContainerPadding.Spacer3]: { bottom: 'pb-3', top: 'pt-3' },
  [ContainerPadding.Spacer4]: { bottom: 'pb-4', top: 'pt-4' },
  [ContainerPadding.Spacer5]: { bottom: 'pb-5', top: 'pt-5' },
  [ContainerPadding.Spacer6]: { bottom: 'pb-6', top: 'pt-6' },
  [ContainerPadding.Spacer7]: { bottom: 'pb-7', top: 'pt-7' },
  [ContainerPadding.Spacer8]: { bottom: 'pb-8', top: 'pt-8' },
  [ContainerPadding.Spacer9]: { bottom: 'pb-9', top: 'pt-9' },
  [ContainerPadding.Spacer10]: { bottom: 'pb-10', top: 'pt-10' },
  [ContainerPadding.Unset]: { bottom: '', top: '' }
}, DEFAULT_CONTAINER_PADDING)

/**
 * Registry that binds the `ContainerRowGap` with the applicable CSS class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const containerRowGapClassNameRegistry = new Registry<ContainerRowGap, string>({
  [ContainerRowGap.None]: '--row-gap-0',
  [ContainerRowGap.Spacer1]: '--row-gap-1',
  [ContainerRowGap.Spacer2]: '--row-gap-2',
  [ContainerRowGap.Spacer3]: '--row-gap-3',
  [ContainerRowGap.Spacer4]: '--row-gap-4',
  [ContainerRowGap.Spacer5]: '--row-gap-5',
  [ContainerRowGap.Spacer6]: '--row-gap-6',
  [ContainerRowGap.Spacer7]: '--row-gap-7',
  [ContainerRowGap.Spacer8]: '--row-gap-8',
  [ContainerRowGap.Spacer9]: '--row-gap-9',
  [ContainerRowGap.Spacer10]: '--row-gap-10',
  [ContainerRowGap.Unset]: ''
}, DEFAULT_CONTAINER_ROW_GAP)

/**
 * Registry that binds the `ContainerWidth` with the applicable CSS class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const containerWidthClassNameRegistry = new Registry<ContainerWidth, string>({
  [ContainerWidth.ExtraNarrow]: 'container-extra-narrow',
  [ContainerWidth.Narrow]: 'container-narrow',
  [ContainerWidth.Normal]: 'container',
  [ContainerWidth.Wide]: 'container-wide'
}, DEFAULT_CONTAINER_WIDTH)

/**
 * Prefix for the `[id]` attribute value of the `<UiContainer>'s` root HTML element.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const UI_CONTAINER_ID_ATTR_PREFIX: string = 'container-'

/**
 * The name of the event emitted by the `<UiContainer>` component
 * to the `EventBus` service in its `mounted()` lifecycle hook.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME: string = 'ui:UiContainer.mounted'
