






import { Component, Prop, Vue } from 'vue-property-decorator'

import { Picker, PickerCallback } from '../../../contracts'

/**
 * Component to choose from picker
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<PickerButton>({
  name: 'PickerButton'
})
export class PickerButton extends Vue {
  @Prop({ type: String, required: true })
  public readonly picker!: Picker

  @Prop({ type: Function, required: false })
  public readonly onPickerCalled!: PickerCallback

  @Prop({ type: Function, required: false })
  public readonly onSelection!: (selected: string) => void

  public onPicked (): void {
    this.onPickerCalled(this.picker, (picked) => {
      this.onSelection(picked)
    })
  }
}

export default PickerButton
