// Copyright © 2022 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { DescriptionOfLink, RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single link.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export class LinkDriver extends AbstractTypeDriver<DescriptionOfLink> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {
    url: {
      type: MappingTypes.Function,
      value: item => (
        item.url.charAt(0) !== '/'
          ? `/${item.url}`
          : item.url
      )
    }
  }

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.Link
}
