// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

import * as icons from '../components/icons'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type IconDefinition = { label: string; component: VueConstructor }

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const iconsRegistry: Record<string, IconDefinition> = {
  Analysis: {
    label: 'Analysis',
    component: icons.IconAnalysis as VueConstructor
  },
  Audit: {
    label: 'Audit',
    component: icons.IconAudit as VueConstructor
  },
  Balance: {
    label: 'Balance',
    component: icons.IconBalance as VueConstructor
  },
  Bills: {
    label: 'Bills',
    component: icons.IconBills as VueConstructor
  },
  Building: {
    label: 'Building',
    component: icons.IconBuilding as VueConstructor
  },
  Certificate: {
    label: 'Certificate',
    component: icons.IconCertificate as VueConstructor
  },
  Chats: {
    label: 'Chats',
    component: icons.IconChats as VueConstructor
  },
  Check: {
    label: 'Check',
    component: icons.IconCheck as VueConstructor
  },
  City: {
    label: 'City',
    component: icons.IconCity as VueConstructor
  },

  // TODO: This icon is being used only in the `<NavbarMobile>` component (partial of the `Navbar` module).
  //   It uses **strokes** instead of **fill**, so the `<BootstrapIcon>` component from the `@movecloser/ui-core`
  //   package is unable to color it properly. There are to options here:
  //     A) translate this SVG from strokes to fill;
  //     B) remove this icon and replace it with some `::before`/`::after` combination inside the `<NavbarMobile>`.
  //   Knowing that the icon is being used only in one particular place in the whole system, it could be wise to choose
  //   the option **B)**.
  Close: {
    label: 'Close',
    component: icons.IconClose as VueConstructor
  },

  DoubleQuote: {
    label: 'DoubleQuote',
    component: icons.IconDoubleQuote as VueConstructor
  },
  Envelope: {
    label: 'Envelope',
    component: icons.IconEnvelope as VueConstructor
  },
  Error: {
    label: 'Error',
    component: icons.IconError as VueConstructor
  },
  FacebookF: {
    label: 'Facebook',
    component: icons.IconFacebookF as VueConstructor
  },
  FormatBrush: {
    label: 'FormatBrush',
    component: icons.IconFormatBrush as VueConstructor
  },
  FourLeaves: {
    label: 'FourLeaves',
    component: icons.IconFourLeaves as VueConstructor
  },
  Funds: {
    label: 'Funds',
    component: icons.IconFunds as VueConstructor
  },
  Goblet: {
    label: 'Goblet',
    component: icons.IconGoblet as VueConstructor
  },
  Heart: {
    label: 'Heart',
    component: icons.IconHeart as VueConstructor
  },
  Home: {
    label: 'Home',
    component: icons.IconHome as VueConstructor
  },
  KagiMap: {
    label: 'KagiMap',
    component: icons.IconKagiMap as VueConstructor
  },
  Leaf: {
    label: 'Leaf',
    component: icons.IconLeaf as VueConstructor
  },
  LinkedIn: {
    label: 'LinkedIn',
    component: icons.IconLinkedIn as VueConstructor
  },
  MapPin: {
    label: 'MapPin',
    component: icons.IconMapPin as VueConstructor
  },
  Menu: {
    label: 'Menu',
    component: icons.IconMenu as VueConstructor
  },
  Microphone: {
    label: 'Microphone',
    component: icons.IconMicrophone as VueConstructor
  },
  Mood: {
    label: 'Mood',
    component: icons.IconMood as VueConstructor
  },
  Mortarboard: {
    label: 'Mortarboard',
    component: icons.IconMortarboard as VueConstructor
  },
  Offers: {
    label: 'Offers',
    component: icons.IconOffers as VueConstructor
  },
  Person: {
    label: 'Person',
    component: icons.IconPerson as VueConstructor
  },
  Phone: {
    label: 'Phone',
    component: icons.IconPhone as VueConstructor
  },
  PositiveDynamics: {
    label: 'PositiveDynamics',
    component: icons.IconPositiveDynamics as VueConstructor
  },
  RingingPhone: {
    label: 'RingingPhone',
    component: icons.IconRingingPhone as VueConstructor
  },
  Search: {
    label: 'Search',
    component: icons.IconSearch as VueConstructor
  },
  SPA: {
    label: 'SPA',
    component: icons.IconSPA as VueConstructor
  },
  Table: {
    label: 'Table',
    component: icons.IconTable as VueConstructor
  },
  ThumbsUp: {
    label: 'ThumbsUp',
    component: icons.IconThumbsUp as VueConstructor
  },
  Wallet: {
    label: 'Wallet',
    component: icons.IconWallet as VueConstructor
  },
  Warning: {
    label: 'Warning',
    component: icons.IconWarning as VueConstructor
  },
  WarningAlt: {
    label: 'WarningAlt',
    component: icons.IconWarningAlt as VueConstructor
  },
  WarningOutline: {
    label: 'WarningOutline',
    component: icons.IconWarningOutline as VueConstructor
  },
  Work: {
    label: 'Work',
    component: icons.IconWork as VueConstructor
  },
  YouTube: {
    label: 'YouTube',
    component: icons.IconYouTube as VueConstructor
  }
}
