// Copyright © 2021 Move Closer

import { Link } from '@movecloser/ui-core'

/**
 * Checks if the passed-in object correctly implements the `Link` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isLink = (subject: any): subject is Link => {
  if (typeof subject !== 'object' || subject === null) {
    return false
  }

  if (
    (
      typeof subject.target !== 'object' &&
      typeof subject.target !== 'string'
    ) ||
    subject.target === null
  ) {
    return false
  }

  if (typeof subject.target === 'object') {
    if (typeof subject.target.path !== 'string' && typeof subject.target.name !== 'string') {
      return false
    }
  }

  return true
}
