











import { Component, Provide } from 'vue-property-decorator'

import { IUserComService, UserComServiceType } from '../../../../services'

import { AbstractModuleUi } from '../../_abstract'

import { NewsletterModule } from '../Newsletter.contracts'

/*
 * Container component for the `NewsletterModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NewsletterModuleUi>({
  name: 'NewsletterModuleUi',
  components: {
    ModuleUnavailablePlaceholder: () => import(
      /* webpackChunkName: "cms/frame" */
      '../../../molecules/ModuleUnavailablePlaceholder/ModuleUnavailablePlaceholder.vue'
    ),
    NewsletterModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Newsletter" */
      './Newsletter.ui.presentation.vue'
    )
  }
})
export class NewsletterModuleUi extends AbstractModuleUi<NewsletterModule> {
  /**
   * An instance of the `UserComService`.
   */
  @Provide()
  public get userComService (): IUserComService | undefined {
    if (this.isCMSApp) {
      return
    }

    let resolved

    try {
      resolved = this.resolveInjection<IUserComService>(UserComServiceType)
    } catch (e) {
      console.log(e)
    }

    return resolved
  }
}

export default NewsletterModuleUi
