// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfBlogPostsFeed, ResolvedBlogPost } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractSetDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a set of blog posts.
 *
 * @see BlogFeedModule
 * @see BlogFeedModuleForm
 * @see BlogFeedModuleUi
 * @see BlogFeedModuleUiPresentation
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export class BlogPostsFeedDriver extends AbstractSetDriver<DescriptionOfBlogPostsFeed, ResolvedBlogPost> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected childType: RelatedType = RelatedType.BlogPost

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.BlogPost]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.BlogPostsFeed
}
