// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { TabsModule, TabsModuleContent, TabsModuleVersion } from './Tabs.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const tabsContentFactory = (): TabsModuleContent => ({ tabs: [] })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const tabsFactory: ModuleFactory<TabsModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 12,
      minColSpan: 4,
      minRowSpan: 4,
      rowSpan: 1
    }
  },
  createModule (): ModuleBase<TabsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: tabsContentFactory(),
      driver: ModuleDriver.Tabs,
      version: TabsModuleVersion.Switch
    }
  }
}
