// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { FooterModule, FooterModuleContent } from './Footer.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const footerContentFactory = (): FooterModuleContent => ({
  top: {
    nav: null
  },
  middle: {
    bottomNav: null,
    mainNav: null,
    socialNav: null,
    contactSection: {
      heading: '',
      iconNav: null,
      phones: {
        mainPhone: '',
        otherPhones: {
          commercialPhone: '',
          nonCommercialDesc: '',
          nonCommercialPhone: '',
          commercialDesc: ''
        }
      }
    }
  },
  bottom: {
    disclaimer: '',
    brandImage: null,
    brandLink: null,
    motto: '',
    nav: null
  }
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const footerFactory: ModuleFactory<FooterModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<FooterModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: footerContentFactory(),
      driver: ModuleDriver.Footer
    }
  }
}
