











import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { CanHaveColor, Color } from '../../../contracts'
import { Selectable } from '../../../extensions/mixins'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ColorSchemaSelector'
})
export class ColorSchemaSelector extends Mixins<Selectable<CanHaveColor>>(Selectable) {
  @Prop({ type: Object, required: false, default: () => (Color) })
  protected readonly colors!: AnyObject

  protected elementId: string = 'schema-color-selector'
  protected elementLabel: string = 'formHelpers.colorSchema.label'
  protected elementPlaceholder: string = 'formHelpers.colorSchema.placeholder'

  public get model (): CanHaveColor['color'] {
    return this._content.color
  }

  public set model (newAlign: CanHaveColor['color']) {
    const contentCopy = { ...this._content }

    contentCopy.color = newAlign
    this._content = contentCopy
  }

  public get options (): DashmixSelectItem[] {
    return Object.entries(this.colors).map(([name, value]) => {
      return {
        label: this.$t(`formHelpers.colorSchema.options.${name}`).toString(),
        value
      }
    })
  }
}

export default ColorSchemaSelector
