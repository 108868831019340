// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'
import { TileModuleContent } from '../Tile'

import { CarouselBulletColor, CarouselModule, CarouselModuleContent, CarouselModuleVersion } from './Carousel.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export const carouselContentFactory = (): CarouselModuleContent<TileModuleContent> => ({
  slides: [],
  swiperOptions: {
    bulletColor: CarouselBulletColor.Primary
  }
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const carouselFactory: ModuleFactory<CarouselModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      minColSpan: 4,
      minRowSpan: 1,
      maxColSpan: 12,
      maxRowSpan: 12,
      rowSpan: 2
    }
  },
  createModule (): ModuleBase<CarouselModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: carouselContentFactory(),
      driver: ModuleDriver.Carousel,
      version: CarouselModuleVersion.Tiles
    }
  }
}
