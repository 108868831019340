

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { JobOffer } from '../../../models'
import { JobOffersModuleContent } from '../../modules/JobOffers'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<JobOffersTable>({
  name: 'JobOffersTable',
  components: {
    TableHeader: () => import(
      /* webpackChunkName: "molecules/JobOffersTable" */
      './partials/TableHeader/TableHeader.vue'
    ),
    TableRow: () => import(
      /* webpackChunkName: "molecules/JobOffersTable" */
      './partials/TableRow/TableRow.vue'
    )
  }
})
export class JobOffersTable extends Vue {
  /**
   * Determines whether the component should be but in the "loading" state.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffersModuleContent.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly openLinkInNewTab?: JobOffersModuleContent['openLinkInNewTab']

  /**
   * Determines whether the component has been provided with the correct `offers` @Prop.
   */
  public get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }

  /**
   * Number of offers.
   */
  public get offersCount (): string {
    return String(this.offers.length)
  }

  @Watch('offers', { deep: true })
  private onOffersUpdated (): void {
    const firstLink = this.$el.querySelector('.TableRow a')

    if (!firstLink) {
      return
    }

    firstLink.setAttribute('tabindex', '-1');
    (firstLink as HTMLLinkElement).focus()
  }
}

export default JobOffersTable
