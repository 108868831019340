// Copyright © 2022 Move Closer

import { I18nOptions } from 'vue-i18n'

import formsEn from '../locales/forms/en.json'
import formsPl from '../locales/forms/pl.json'
import messagesEn from '../locales/messages/en.json'
import messagesPl from '../locales/messages/pl.json'
import { pluralizationRulesEN, pluralizationRulesPL } from '../locales'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
export const i18nOptions: I18nOptions = {
  fallbackLocale: 'pl',
  locale: 'pl',
  pluralizationRules: { en: pluralizationRulesEN, pl: pluralizationRulesPL },
  messages: {
    en: {
      ...messagesEn,
      ...formsEn
    },
    pl: {
      ...messagesPl,
      ...formsPl
    }
  }
}
