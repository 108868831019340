// Copyright © 2021 Move Closer

/**
 * Service capable of communicating with the User.com UDP (User Data Platform).
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (edited)
 */
export interface IUserComService {
  /**
   * Subscribes new User to the newsletter.
   *
   * @param formData - Data entered by the User.
   */
  subscribe: (formData: NewsletterFormData) => Promise<void>
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface NewsletterFormData {
  /**
   * Array of selected (checked) agreements.
   */
  agreements: string[]

  /**
   * User's e-mail address.
   */
  email: string

  /**
   * Determines whether legend is hidden.
   */
  isLegendHidden?: boolean

  /**
   * Determines legend text.
   */
  legendText?: string

  /**
   * User's name.
   */
  name: string
}

/**
 * Configuration object required by the `UserComService`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface UserComConfig {
  /**
   * Unique API key that enables connection between the application and the User.com.
   */
  apiKey: string

  /**
   * The URL address from which the User.com JS script should be fetched from.
   */
  endpoint: string
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const UserComServiceType = Symbol.for('IUserComService')
