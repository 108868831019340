// Copyright © 2021 Move Closer

import { Component, Inject, Mixins, Prop } from 'vue-property-decorator'

import { CanBeAlignedInGrid, HorizontalAlignment, VerticalAlignment } from '../../../contracts'

import {
  DEFAULT_MODULE_HORIZONTAL_ALIGNMENT,
  DEFAULT_MODULE_VERTICAL_ALIGNMENT
} from './abstract-module.ui.presentation.config'
import { UseNonce } from '../../../extensions'

/**
 * The abstract class that every module's UI presentational component **HAS TO** extend.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<AbstractModuleUiPresentation>({ name: 'AbstractModuleUiPresentation' })
export class AbstractModuleUiPresentation extends Mixins<UseNonce>(UseNonce) {
  /**
   * Component's alignment within the grid.
   *
   * In fact, it will be the component's alignment within its parent HTML element,
   * which is also a grid container, but don't tell anyone ;)
   * It's a workaround, I know, but we wanted to keep the `<Viewer>` and `<Module>` components
   * as sterile and agnostic as possible. Ideally, this data would've been
   * passed up in the tree to the `<Module>` component which is the REAL grid item.
   * This would cost us development time and make the `<Module>` component more complicated.
   * We decided to go another way, which is a bit less elegant, but should produce similar results.
   */
  @Prop({ required: false })
  private readonly alignment?: CanBeAlignedInGrid['alignment']

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Inject({ from: 'isMobile', default: false })
  private readonly isMobile!: boolean

  /**
   * Value for the `:style` binding.
   */
  // public get style (): { alignSelf: string; justifySelf: string } {
  //   return {
  //     alignSelf: verticalAlignmentAlignSelfValueRegistry.getValue(
  //       this.alignment?.y?.[this.isMobile ? 'mobile' : 'desktop'] ?? DEFAULT_MODULE_VERTICAL_ALIGNMENT
  //     ),
  //     justifySelf: horizontalAlignmentJustifySelfValueRegistry.getValue(
  //       this.alignment?.x?.[this.isMobile ? 'mobile' : 'desktop'] ?? DEFAULT_MODULE_HORIZONTAL_ALIGNMENT
  //     )
  //   }
  // }

  private gridAlignmentClassNameRegistry (breakpoint?: string): {
    x: { [key: string]: string }
    y: { [key: string]: string }
  } {
    const prefix = breakpoint ? `-${breakpoint}` : ''

    return {
      x: {
        [HorizontalAlignment.Center]: `justify-self${prefix}-center`,
        [HorizontalAlignment.Justify]: `justify-self${prefix}-stretch`,
        [HorizontalAlignment.Left]: `justify-self${prefix}-start`,
        [HorizontalAlignment.Right]: `justify-self${prefix}-end`
      },
      y: {
        [VerticalAlignment.Center]: `align-self${prefix}-center`,
        [VerticalAlignment.Bottom]: `align-self${prefix}-end`,
        [VerticalAlignment.Stretch]: `align-self${prefix}-stretch`,
        [VerticalAlignment.Top]: `align-self${prefix}-start`
      }
    }
  }

  public get gridAlignmentClassName (): string {
    if (!this.alignment) {
      return ''
    }

    const classNameMobileX: string = this.gridAlignmentClassNameRegistry().x[this.alignment.x?.mobile ?? DEFAULT_MODULE_HORIZONTAL_ALIGNMENT]
    const classNameDesktopX: string = this.gridAlignmentClassNameRegistry('lg').x[this.alignment.x?.desktop ?? DEFAULT_MODULE_HORIZONTAL_ALIGNMENT]
    const classNameMobileY: string = this.gridAlignmentClassNameRegistry().y[this.alignment.y?.mobile ?? DEFAULT_MODULE_VERTICAL_ALIGNMENT]
    const classNameDesktopY: string = this.gridAlignmentClassNameRegistry('lg').y[this.alignment.y?.desktop ?? DEFAULT_MODULE_VERTICAL_ALIGNMENT]

    return [classNameMobileX, classNameDesktopX, classNameMobileY, classNameDesktopY].join(' ')
  }
}
