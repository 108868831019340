








import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Simple component capable of rendering the form's errors
 * passed as an array of strings.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FormErrors>({
  name: 'FormErrors'
})
export class FormErrors extends Vue {
  /**
   * List of errors to display.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public errors!: string[]
}

export default FormErrors
