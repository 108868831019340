

































import { BootstrapIcon, BootstrapImage } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable } from '../../../extensions'

import { UiHeading, UiMarkdown } from '../../atoms'

import { QuoteAuthor } from './Quote.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Quote>({
  name: 'Quote',
  components: { BootstrapIcon, BootstrapImage, UiHeading, UiMarkdown }
})
export class Quote extends Mixins<Alignmentable>(Alignmentable) {
  /**
   * Quote's author.
   */
  @Prop({ type: Object, required: false })
  public readonly author?: QuoteAuthor

  /**
   * Main quote's content (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: true })
  public readonly body!: string

  /**
   * Additional quote's heading.
   */
  @Prop({ type: String, required: false })
  public readonly heading?: string

  /**
   * The name of the icon that is to be rendered on top of the component.
   */
  @Prop({ type: String, required: false })
  public readonly icon?: string

  /**
   * Determines whether the assigned author has its image properly defined.
   */
  public get hasAuthorImage (): boolean {
    if (typeof this.author === 'undefined') {
      return false
    }

    return typeof this.author.image !== 'undefined'
  }
}

export default Quote
