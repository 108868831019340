// Copyright © 2021 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion, Related, RelatedType } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type DepartmentsModule = ModuleWithVersion<DepartmentsModuleContent,
  ModuleDriver.Departments,
  DepartmentsModuleVersion>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface DepartmentsModuleContent extends ModuleContent {
  /**
   * Departments to render.
   */
  departments: Related<RelatedType.DepartmentsFeed> | null
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum DepartmentsModuleVersion {
  Carousel = 'carousel',
  Tiles = 'tiles',
}
