// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'

import { Related, RelatedType } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Addon {
  // AdditionalTitles = 'additionalTitles',
  // Author = 'author',
  // Cover = 'cover',
  // CustomSets = 'customList',
  Delayed = 'delayed',
  // Labels = 'labels',
  Layout = 'layout',
  // Lead = 'lead',
  // LiveFeed = 'liveFeed',
  Meta = 'meta',
  // Premium = 'premium',
  // RelatedArticles = 'relatedArticles',
  // SmartRelated = 'smartRelated',
  // Tags = 'tags'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type AddonData = AnyObject | string

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ClientAddon = Exclude<Addon, NonClientAddon>
export type NonClientAddon = Addon.Delayed | Addon.Layout

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 * @author Michał Wawer <michal.wawer@movecloser.pl>
 */
export interface ContentMeta {
  description?: string
  keywords?: string
  ogDescription?: string
  ogImage?: Related<RelatedType.File>
  ogTitle?: string
  robots?: string
  title?: string
  twitterDescription?: string
  twitterImage?: Related<RelatedType.File>
  twitterImageAlt?: string
  twitterTitle?: string
}
