








import { AsyncComponent } from 'vue'
import { Component, Prop, Provide } from 'vue-property-decorator'

import { JobOffer } from '../../../../models'
import { log } from '../../../../support'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { JobOffersModuleContent, JobOffersModuleVersion } from '../JobOffers.contracts'

import { jobOffersModuleVersionComponentRegistry } from './JobOffers.ui.config'

/**
 * Presentational component for the `JobOffersModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<JobOffersModuleUiPresentation>({
  name: 'JobOffersModuleUiPresentation'
})
export class JobOffersModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see JobOffersModuleContent.headingContent
   */
  @Prop({ type: String, required: false })
  public readonly headingContent?: JobOffersModuleContent['headingContent']

  /**
   * @see JobOffersModuleContent.headingLevel
   */
  @Prop({ type: Number, required: false })
  public readonly headingLevel?: JobOffersModuleContent['headingLevel']

  /**
   * Array of job offers to render.
   */
  @Prop({ type: Array, required: true })
  public readonly offers!: JobOffer[]

  /**
   * @see JobOffersModuleContent.openLinkInNewTab
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly openLinkInNewTab?: JobOffersModuleContent['openLinkInNewTab']

  /**
   * @see JobOffersModuleContent.readMoreLink
   */
  @Prop({ type: Object, required: false })
  public readonly readMoreLink?: JobOffersModuleContent['readMoreLink']

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the offers.
   *
   * @see JobOffersModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: JobOffersModuleVersion

  /**
   * Determines current site domain.
   */
  @Provide('linkFollowedDomains')
  public followedDomains: string[] = [process.env.VUE_APP_BNP_MAIN_PAGE ?? '']

  /**
   * Vue component that should be used to render the job offers.
   */
  public get component (): AsyncComponent | undefined {
    const component = jobOffersModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(
        `JobOffersModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] JobOffersModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    if (this.version === JobOffersModuleVersion.List) {
      return typeof this.offers !== 'undefined' && typeof this.component === 'function'
    }

    return this.hasOffers && typeof this.component === 'function'
  }

  /**
   * Determines whether the component has been provided with the correct `offers` @Prop.
   */
  private get hasOffers (): boolean {
    return typeof this.offers !== 'undefined' &&
      Array.isArray(this.offers) &&
      this.offers.length > 0
  }
}

export default JobOffersModuleUiPresentation
