// Copyright © 2022 Move Closer

import { DirectiveBinding } from 'vue/types/options'
import { DirectiveOptions } from 'vue'

import { log } from '../../support'

// @ts-expect-error - The clamp file is js file.
import { clamp as _clamp } from './partials/clamp'

/**
 * Clamps the element's inner text to the specified number of lines.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const clamp: DirectiveOptions = {
  inserted (el: HTMLElement, binding: DirectiveBinding): void {
    // Beware of the SSR.
    if (typeof window === 'undefined') {
      return
    }

    let linesToClamp: number = 3

    if (typeof binding.value === 'number') {
      linesToClamp = binding.value
    } else if (typeof binding.value === 'string') {
      linesToClamp = +binding.value
    } else {
      return
    }

    try {
      _clamp(el, { clamp: linesToClamp })
    } catch (error) {
      log(error, 'error')
    }
  },

  update (el: HTMLElement, binding: DirectiveBinding): void {
    // Beware of the SSR.
    if (typeof window === 'undefined') {
      return
    }

    let linesToClamp: number = 3

    if (typeof binding.value === 'number') {
      linesToClamp = binding.value
    } else if (typeof binding.value === 'string') {
      linesToClamp = +binding.value
    } else {
      return
    }

    try {
      _clamp(el, { clamp: linesToClamp })
    } catch (error) {
      log(error, 'error')
    }
  }
}

export default clamp
