// Copyright © 2021 Move Closer

import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Inject, Prop, Watch } from 'vue-property-decorator'

import { DescriptionOfImageFile, ImageRatio, isImageFile } from '../../../models'
import { Enum, isLink, isValidEnumValue, log, toBootstrapImageProps } from '../../../support'
import { isRelated } from '../../../services'
import {
  AnyModule,
  DescriptionOfLink,
  isUnresolvedLink,
  LinkWithLabel,
  ModuleImageRatio,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../contracts'
import { NavigationItem } from '../../../models/navigation'

import { AbstractModule } from './abstract-module'

/**
 * The abstract class that every module's UI container component **HAS TO** extend.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Component<AbstractModuleUi<AnyModule>>({
  name: 'AbstractModuleUi',
  async prefetch (): Promise<void> {
    await this.fetchRelated()
  },
  beforeMount (): void {
    if (AbstractModuleUi.isStorybook) this.fetchRelated()
  },
  mounted (): void {
    if (!this.isVisible) {
      this.$emit('decorate', '--with-overlay')
    }
  }
})
export class AbstractModuleUi<Module extends AnyModule> extends AbstractModule {
  /**
   * Module's data.
   */
  @Prop({ type: Object, required: true })
  public readonly data!: Module

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Inject({ from: 'isMobile', default: false })
  private readonly isMobile!: boolean

  /**
   * Enum describing the possible values for `this.data.version`.
   */
  protected versionEnum?: Enum

  /**
   * Determines what image ratio should be used in particular module.
   */
  protected imageRatios: ModuleImageRatio = {
    desktop: ImageRatio.Original,
    mobile: ImageRatio.Original
  }

  /**
   * Determines whether `this.data.content` is specified.
   */
  public get hasContent (): boolean {
    if (typeof this.data === 'undefined') {
      const message: string = 'AbstractModuleUi.hasContent(): [this.data] is [undefined]!'
      log(message, 'error')
      return false
    }

    if (typeof this.data.content === 'undefined') {
      const message: string = 'AbstractModuleUi.hasContent(): [this.data.content] is [undefined]!'
      log(message, 'error')
      return false
    }

    return true
  }

  /**
   * Determines whether the current environment is the CMS App.
   */
  public get isCMSApp (): boolean {
    return process.env?.VUE_APP_NAME === 'CMS App'
  }

  /**
   * Determines whether component is visible.
   */
  public get isVisible (): boolean {
    return this.data.isVisible
  }

  /**
   * Determines whether `this.data.version` is a valid value from `this.versionEnum` enum.
   */
  public get hasValidVersion (): boolean {
    if (typeof this.data.version === 'undefined') {
      return false
    }

    if (typeof this.versionEnum === 'undefined') {
      const message: string = 'AbstractModule.hasValidVersion(): [this.versionEnum] is [undefined]! You probably forgot to override this property in the subclass.'
      log(message, 'warn')
      return false
    }

    return isValidEnumValue(this.data.version, this.versionEnum)
  }

  /**
   * Determines image ratio which should be used.
   */
  protected get imageRatio (): ImageRatio {
    if (this.imageRatios === null) {
      throw new Error('AbstractModuleUi.imageRatio(): [this.imageRatios] is [null], but its value should be set from/inside the subclass!')
    }

    if (this.isMobile) {
      return this.imageRatios.mobile
    }

    return this.imageRatios.desktop
  }

  /**
   * Fetches the specified unresolved image from the `RelatedService`.
   *
   * @param unresolvedImage - The image to resolve.
   *
   * @returns - Object compliant with the `BootstrapImageProps` interface,
   * ready to use inside the presentational components.
   *
   * @throws Error - if the provided `unresolvedImage` argument is not compliant with the `Related` interface.
   * @throws Error - if the resolved data is not compliant with the `ImageFile` interface.
   */
  protected async fetchImage (unresolvedImage: Related<RelatedType.File>): Promise<BootstrapImageProps> {
    if (!isRelated(unresolvedImage)) {
      throw new Error(`AbstractModuleUi.fetchImage(): Provided [unresolvedImage] argument is NOT compliant with the [Related] interface!\n[unresolvedImage] value: ${JSON.parse(JSON.stringify(unresolvedImage))}.`)
    }

    const imageFile: DescriptionOfImageFile =
      await this.relatedService.resolve<DescriptionOfImageFile>(unresolvedImage)

    if (!isImageFile(imageFile)) {
      throw new Error('AbstractModuleUi.fetchImage(): Resolved data is NOT compliant with the [ImageFile] interface!')
    }

    return toBootstrapImageProps(imageFile, this.imageRatio)
  }

  /**
   * Fetches the specified unresolved link from the `RelatedService`.
   *
   * @param unresolvedLink - The link to resolve.
   *
   * @returns - Object compliant with the `Link` interface, ready to use inside the presentational components.
   *
   * @throws Error - if the provided `unresolvedLink` argument is not compliant with the `UnresolvedLink` interface.
   * @throws Error - if the resolved data is not compliant with the `Link` interface.
   */
  protected async fetchLink (unresolvedLink: UnresolvedLink): Promise<LinkWithLabel> {
    if (!isUnresolvedLink(unresolvedLink)) {
      throw new Error(`AbstractModuleUi.fetchLink(): Provided [unresolvedLink] argument is NOT compliant with the [UnresolvedLink] interface!\n[unresolvedLink] value: ${JSON.parse(JSON.stringify(unresolvedLink))}.`)
    }

    const descriptionOfLink: DescriptionOfLink = await this.relatedService.resolve<DescriptionOfLink>(
      // We can safely use the `as` keyword here, because the `unresolvedAddon.link.relatedTarget`
      // has been checked against the `Related<RelatedType.Link>` interface compliance inside
      // the above `isUnresolvedLink()` type guard.
      unresolvedLink.relatedTarget as Related<RelatedType.Link>
    )

    const resolvedLink: LinkWithLabel = {
      ...unresolvedLink,
      label: unresolvedLink.label || descriptionOfLink.title || this.$t('_.see-more'),
      target: descriptionOfLink.url
    }

    if (!isLink(resolvedLink)) {
      throw new Error('AbstractModuleUi.fetchLink(): Resolved data is NOT compliant with the [Link] interface!')
    }

    return resolvedLink
  }

  /**
   * Fetches the specified unresolved navigation from the `RelatedService`.
   *
   * @param unresolvedNavigation - The navigation to resolve.
   *
   * @returns - List of NavigationItem ready to use inside the presentational components.
   *
   * @throws Error - if the provided `unresolvedNavigation` argument is not compliant with the `Related` interface.
   * @throws Error - if the resolved data is not compliant with the `NavigationItem` interface.
   */
  protected async fetchNavigation (unresolvedNavigation: Related<RelatedType.Navigation>): Promise<NavigationItem[]> {
    if (!isRelated(unresolvedNavigation)) {
      throw new Error(`AbstractModuleUi.fetchNavigation(): Provided [unresolvedNavigation] argument is NOT compliant with the [Related] interface!\n[unresolvedNavigation] value: ${JSON.parse(JSON.stringify(unresolvedNavigation))}.`)
    }

    const navigationItems: NavigationItem[] =
      await this.relatedService.resolve<NavigationItem[]>(unresolvedNavigation)

    if (!Array.isArray(navigationItems)) {
      throw new Error('AbstractModuleUi.fetchNavigation(): Resolved data is NOT compliant with the [NavigationItem[]] interface!')
    }

    return navigationItems
  }

  /**
   * Determines whether the current environment is the Storybook.js.
   */
  private static get isStorybook (): boolean {
    return process.env?.STORYBOOK === 'true'
  }

  /**
   * Watch isVisible property and emits event to decorate parent element
   */
  @Watch('isVisible')
  private onVisibilityChange (isVisible: boolean) {
    this.$emit('decorate', isVisible ? '' : '--with-overlay')
  }
}
