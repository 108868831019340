// Copyright © 2021 Move Closer

import { IModel, MagicModel, ModelPayload } from '@movecloser/front-core'
import { MetaPropertyProperty } from 'vue-meta'

import { Identifier, Label } from '../../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ISite extends IModel<SiteData> {
  getProperty (key: string, defaultValue?: SitePropertyTypes): SitePropertyTypes
  isMaintenanceMode (): boolean
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export interface ISiteService {
  getActiveSite (): ISiteModel
  getActiveSiteAddress (): string
  getActiveSiteBasePath (): string
  getActiveSiteLocale (): string
  getActiveSiteMeta (): MetaPropertyProperty[]
  /**
   * Resolves the list of all the enabled sites.
   */
  getSites (): ISiteModel[]
  getSiteById (id: Identifier): ISiteModel | undefined
  setActive (site: ISiteModel, searchLabels: Label[]): void
  setSites (sites: SitesMap, searchLabels: Label[]): void
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface SiteData {
  address: string
  basePath: string
  color: string
  favicon: Partial<Record<SiteFaviconType, string>>
  id: Identifier
  locale: string
  logo: Partial<Record<SiteLogoVariant, string>>
  name: string
  postfix: string
  properties: { [key: string]: SitePropertyTypes }

  /**
   * Various redirection settings.
   */
  redirections: {
    /**
     * Registry that binds the API error codes with the URL addresses
     * that the User should be redirected to.
     */
    errorPages: { [errorCode: number]: string }

    /**
     * URL address of the search results page that the User should be redirected to
     * when performing the search action from the navbar.
     */
    searchResults: string
  }

  searchLabels: Label[]
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum SiteFaviconType {
  Png = 'png',
  Svg = 'svg'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum SiteLogoVariant {
  Dark = 'dark',
  Light = 'light'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type SitesMap = Record<number, ModelPayload>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteMiddlewareType = Symbol.for('SiteMiddleware')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ISiteModel = MagicModel<SiteData, ISite>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type SitePropertyTypes = string | number | boolean | null

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteServiceType = Symbol.for('ISiteService')
