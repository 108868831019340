// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { TextModule, TextModuleContent } from './Text.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const textContentFactory = (): TextModuleContent => ({
  content: '',
  extendedContent: ''
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const textFactory: ModuleFactory<TextModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 6,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 12,
      minColSpan: 1,
      minRowSpan: 1,
      rowSpan: 1
    }
  },
  createModule (): ModuleBase<TextModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: textContentFactory(),
      driver: ModuleDriver.Text
    }
  }
}
