



































































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, IFile } from '@movecloser/ui-core'

import { EmbedVersionAbstractForm } from '../version.abstract.form'

/**
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'EmbedYouTubePartialForm'
})
export class EmbedYouTubePartialForm extends EmbedVersionAbstractForm {
  @Prop({ type: String, required: false, default: '' })
  public imgTitle!: string

  @Prop({ type: Function, required: true })
  public onAutoplayToggle!: () => void

  @Prop({ type: Function, required: true })
  public onSelection!: () => void

  @Prop({ type: Function, required: true })
  public onRemove!: () => void

  @Prop({ type: Function, required: true })
  public onVideoIdChange!: (id: string) => void

  @Prop({ type: Object, required: false, default: null })
  public thumbnail!: IFile | null

  public readonly DashmixIconName = DashmixIconName

  public readonly regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch?))\??v?=?([^#&?"]*).*/

  /**
   * Checks whether content contains related file
   */
  public get hasImage (): boolean {
    return !!this._content.thumbnail && typeof this._content.thumbnail.value !== 'undefined'
  }

  public get url (): string {
    if (!this._content || !this._content.videoId) {
      return ''
    } else {
      return `https://www.youtube.com/watch?v=${this._content.videoId}`
    }
  }

  public set url (url: string) {
    if (!url) {
      return
    }

    const youtubeMatch = url.match(this.regex)

    if (youtubeMatch) {
      this.onVideoIdChange(youtubeMatch[7])
    }
  }
}

export default EmbedYouTubePartialForm
