













import { VueConstructor } from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleUi } from '../../_abstract'
import { EmbedModule, EmbedVersion } from '../Embed.contracts'
import { EmbedYouTubeUi } from './versions'
import { Loading } from '../../../../extensions'

/**
 * Registry that binds the EmbedVersion with the applicable UI component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
const embedVersionUiComponentRegistry: Record<EmbedVersion, VueConstructor> = {
  [EmbedVersion.YouTube]: EmbedYouTubeUi
  // [EmbedVersion.Video]: EmbedVideoUi
}

/**
 * Component capable of rendering the `EmbedModule`'s contents.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<EmbedModuleUi>({
  name: 'EmbedModuleUi',
  mounted () {
    this.markAsReady()
  }
})
export class EmbedModuleUi extends Mixins<AbstractModuleUi<EmbedModule>, Loading>(
  AbstractModuleUi,
  Loading
) {
  /**
   * An instance of the `EventBus` service.
   */
  public get eventBus (): IEventbus {
    return this.resolveInjection<IEventbus>(EventbusType)
  }

  /**
   * Vue component that should be used to render the module's content.
   */
  public get component (): VueConstructor | undefined {
    if (!embedVersionUiComponentRegistry[this.data.version]) {
      return
    }

    return embedVersionUiComponentRegistry[this.data.version]
  }
}

export default EmbedModuleUi
