










import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'
import { TranslateResult } from 'vue-i18n'

@Component({
  name: 'VersionSelector'
})
export class VersionSelector extends Vue {
  @Prop({ type: String, required: false })
  protected readonly id!: string

  @Prop({ type: [String, Object], required: false, default: null })
  protected readonly label!: TranslateResult | string

  @Prop({ type: Object, required: true })
  protected readonly map!: Record<string, string>

  @Prop({ type: String, required: true })
  protected readonly module!: string

  @PropSync('version', { type: String, required: true })
  public _version!: string

  protected elementId: string = 'version-select'
  protected elementLabel: string = 'formHelpers.version.label'

  public get availableVersions (): DashmixSelectItem[] {
    return Object.entries(this.map).map<DashmixSelectItem>(([key, value]) => ({
      label: this.$t(`forms.${this.module}.versions.${key}`).toString(),
      value
    }))
  }

  public get resolvedId (): string {
    return this.id ? `${this.elementId}-${this.id}` : this.elementId
  }

  public get resolvedLabel (): string {
    if (this.label && typeof this.label === 'object') {
      return this.label.toString()
    }

    return this.$t(this.label ?? this.elementLabel).toString()
  }
}

export default VersionSelector
