// Copyright © 2022 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfDepartment } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a single department.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export class DepartmentDriver extends AbstractTypeDriver<DescriptionOfDepartment> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = []

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.Department
}
