// Copyright © 2022 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { ExposesRelatedRecord, ResolvesRelatedAsync } from '../../../services'
import { FetchesRelatedAsync, InjectionCallback } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Component<AbstractModule>({ name: 'AbstractModule' })
export class AbstractModule extends Vue implements FetchesRelatedAsync {
  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync & ExposesRelatedRecord

  /**
   * Function capable of resolving the injections from the IoC container.
   */
  @Prop({ type: Function, required: true })
  public readonly resolveInjection!: InjectionCallback

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    return Promise.resolve(undefined)
  }
}
