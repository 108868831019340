// Copyright © 2021 Move Closer

import { Enum } from './utility-types'
import { log } from './log'

/**
 * Determines whether the passed-in `subject` variable is a valid value from the specified `_enum` enum.
 *
 * @param subject - The object to verify.
 * @param _enum - The enum that the object should be verified against.
 *
 * @example {
 *   enum TestEnum {
 *     Foo = 'foo',
 *     Bar = 'bar',
 *   }
 *
 *   const testVariable1: string = 'foo'
 *   const testVariable2: TestEnum = TestEnum.Foo
 *   const testVariable3: string = 'Hello, World!'
 *
 *   isValidEnumValue(testVariable1, TestEnum) // expected output: true
 *   isValidEnumValue(testVariable2, TestEnum) // expected output: true
 *   isValidEnumValue(testVariable3, TestEnum) // expected output: false
 * }
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isValidEnumValue = (subject: Enum[keyof Enum], _enum: Enum): boolean => {
  const isValid: boolean = Object.values(_enum).includes(subject)

  if (!isValid) {
    const message: string = `isValidEnumValue(): Provided [subject] is NOT a valid value of the specified [_enum]!\n[subject] value: ${subject.toString()}.\n[_enum] value: ${_enum}.`
    log(message, 'error')
  }

  return isValid
}
