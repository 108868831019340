// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { FloatingButtonModule, FloatingButtonModuleContent } from './FloatingButton.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const floatingButtonContentFactory = (): FloatingButtonModuleContent => ({
  link: null
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const floatingButtonFactory: ModuleFactory<FloatingButtonModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<FloatingButtonModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: floatingButtonContentFactory(),
      driver: ModuleDriver.FloatingButton
    }
  }
}
