// Copyright © 2022 Move Closer

import {
  CanHaveHeading,
  LinkWithLabel,
  ModuleContent,
  ModuleDriver,
  ModuleWithVersion,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type JobOffersModule = ModuleWithVersion<JobOffersModuleContent, ModuleDriver, JobOffersModuleVersion>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface JobOffersModuleContent extends ModuleContent, CanHaveHeading {
  /**
   * Job offers to render.
   */
  offers: Related<RelatedType.JobOffersFeed> | null

  /**
   * "Read more" link to render on the bottom of the component.
   */
  readMoreLink: LinkWithLabel | UnresolvedLink | null

  /**
   * Determines the link will be open in new browser tab.
   */
  openLinkInNewTab?: boolean

  /**
   * Determines the amount of offers to be displayed.
   */
  selectedQuantity: number | undefined
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum JobOffersModuleVersion {
  Carousel = 'carousel',
  List = 'list',
  Table = 'table',
}
