var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasBehavior)?_c(_vm.tag,{tag:"component",staticClass:"Tile",class:[{
  '--expanded': !_vm.isExtendedBodyCollapsed,
  '--large': _vm.large,
  '--rounded': _vm.rounded,
  '--transparent': _vm.transparent,
  '--with-border': _vm.border,
  'shadow': _vm.shadow
}, _vm.contentAlignmentClassName]},[(_vm.hasDataCircle)?_c('div',{ref:"dataCircleRef",staticClass:"Tile__data-circle"},[_c('span',{staticClass:"Tile__data-circle__content"},[_vm._v(_vm._s(_vm.dataCircle.content))])]):_vm._e(),(_vm.hasHeading)?_c('div',{staticClass:"Tile__heading",class:[("--icon-" + _vm.iconPlacement), _vm.headingAlignmentClassName]},[(_vm.iconName)?_c('span',{directives:[{name:"collapse",rawName:"v-collapse",value:(_vm.isIconCollapsed),expression:"isIconCollapsed"}],staticClass:"Tile__heading-icon",attrs:{"id":_vm.$id('icon'),"aria-hidden":_vm.isIconCollapsed ? 'true' : 'false'}},[_c('BootstrapIcon',{attrs:{"name":_vm.iconName,"color":_vm.iconColor}})],1):_vm._e(),(_vm.heading.text)?_c('UiHeading',{staticClass:"Tile__heading-text",attrs:{"level":_vm.heading.level}},[_vm._v(" "+_vm._s(_vm.heading.text)+" ")]):_vm._e()],1):_vm._e(),(_vm.body)?[(_vm.isCollapsible)?_c('UiMarkdown',{staticClass:"Tile__body",attrs:{"content":_vm.body,"id":_vm.$id('body'),"aria-hidden":_vm.isExtendedBodyCollapsed ? 'false' : 'true'}}):_c('UiMarkdown',{staticClass:"Tile__body",attrs:{"content":_vm.body,"id":_vm.$id('body')}})]:_vm._e(),(_vm.isCollapsible && _vm.extendedBody)?_c('UiMarkdown',{directives:[{name:"collapse",rawName:"v-collapse",value:({
                isCollapsed: _vm.isExtendedBodyCollapsed,
                transitionCallback: _vm.vCollapseTransitionCallback
              }),expression:"{\n                isCollapsed: isExtendedBodyCollapsed,\n                transitionCallback: vCollapseTransitionCallback\n              }"}],staticClass:"Tile__extended-body",attrs:{"content":_vm.extendedBody,"id":_vm.$id('extended-body'),"aria-hidden":_vm.isExtendedBodyCollapsed ? 'true' : 'false'}}):_vm._e(),(_vm.hasLink || _vm.isCollapsible)?_c('div',{staticClass:"Tile__footer"},[(_vm.isCollapsible)?_c('BootstrapButton',{staticClass:"Tile__btn",attrs:{"link":true,"aria-controls":((_vm.$id('icon')) + " " + (_vm.$id('body')) + " " + (_vm.$id('extended-body')))},on:{"click":_vm.onBtnClick}},[_vm._v(" "+_vm._s(_vm.isExtendedBodyCollapsed ? _vm.$t('_.see-more') : _vm.$t('_.hide-details'))+" "),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.isExtendedBodyCollapsed ? ("" + (_vm.heading.text)) : ("" + (_vm.heading.text)))+" ")])]):(_vm.hasLink)?_c('BootstrapLink',_vm._b({class:['Tile__link', _vm.linkClassName]},'BootstrapLink',{ link: _vm.link },false)):_vm._e()],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }