// Copyright © 2021 Move Closer

import { BootstrapImageProps, Link } from '@movecloser/ui-core'

import {
  LinkFromForm,
  Module,
  ModuleContent,
  ModuleDriver,
  Related,
  RelatedType,
  UnresolvedKeysOf
} from '../../../contracts'
import { NavigationItem } from '../../../models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type AnySection = FooterModuleContentBottom | FooterModuleContentMiddle | FooterModuleContentTop

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type FooterModule = Module<FooterModuleContent, ModuleDriver.Footer>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FooterModuleContent extends ModuleContent {
  /**
   * Content for the **bottom** section of the module.
   */
  bottom?: FooterModuleContentBottom

  /**
   * Content for the **middle** section of the module.
   */
  middle?: FooterModuleContentMiddle

  /**
   * Content for the **top** section of the module.
   */
  top?: FooterModuleContentTop
}

/**
 * @see FooterModuleContent.bottom
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FooterModuleContentBottom {
  /**
   * Company disclaimer.
   */
  disclaimer?: string

  /**
   * Brand image (its logotype).
   */
  brandImage: Related<RelatedType.File> | null

  /**
   * Link associated with the brand's image (logotype).
   */
  brandLink: LinkFromForm

  /**
   * Text to be rendered next to the brand's image (logotype).
   */
  motto?: string

  /**
   * Copyright text.
   */
  copyright?: string

  /**
   * Bottom footer navigation.
   */
  nav: Related<RelatedType.Navigation> | null
}

/**
 * @see FooterModuleContent.middle
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FooterModuleContentMiddle {
  /**
   * Navigation rendered under mian (multilevel) navigation.
   */
  bottomNav: Related<RelatedType.Navigation> | null

  /**
   * Content for the "contact" section.
   */
  contactSection?: {
    /**
     * Navigation with links with icon render under contact section
     */
    iconNav: Related<RelatedType.Navigation> | null

    /**
     * Section's heading.
     */
    heading: string

    /**
     * Content for the "phones" subsection.
     */
    phones?: FooterPhones
  }

  /**
   * Navigation to render as te main content of Footer
   */
  mainNav: Related<RelatedType.Navigation> | null

  /**
   * Navigation with links to social medias.
   */
  socialNav?: Related<RelatedType.Navigation> | null
}

/**
 * @see FooterModuleContent.top
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FooterModuleContentTop {
  /**
   * Footer top navigation
   */
  nav: Related<RelatedType.Navigation> | null
}

export interface ResolvedContactSection extends Omit<
  FooterModuleContentMiddle['contactSection'], 'iconNav'> {
  iconNav?: NavigationItem[]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FooterModuleContactSectionPhone {
  /**
   * Actual phone number.
   */
  phone: string

  /**
   * Additional description (with Markdown support (will be parsed to HTML)).
   */
  description?: string
}

export interface FooterPhones {
  /**
   * Disclaimer text (shown below the phones list) (with Markdown support (will be parsed to HTML)).
   */
  disclaimer?: string

  /**
   * Additional heading for the subsection.
   */
  heading?: string

  /**
   * Emphasised phone number.
   */
  mainPhone?: string

  /**
   * Array of other phones to render.
   */
  otherPhones?: {
    /**
     * Phone for domestic and foreign calls
     */
    nonCommercialPhone?: string

    /**
     * Short description of nonCommercial phone
     */
    nonCommercialDesc?: string

    /**
     * Phone for organizations / firms / companies
     */
    commercialPhone?: string

    /**
     * Short description of commercial phone
     */
    commercialDesc?: string
  }
}

/**
 * Available footer sections
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum FooterSectionType {
 Top= 'top',
 Bottom = 'bottom',
 Middle = 'middle'
}

/**
 * `FooterModuleContent` with all related data already resolved.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ResolvedFooterModuleContent {
  /**
   * @see FooterModuleContent.bottom
   */
  bottom?: ResolvedFooterModuleContentBottom

  /**
   * @see FooterModuleContent.middle
   */
  middle?: ResolvedFooterModuleContentMiddle

  /**
   * @see FooterModuleContent.top
   */
  top?: ResolvedFooterModuleContentTop
}

/**
 * `FooterModuleContentBottom` with all related data already resolved.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ResolvedFooterModuleContentBottom extends Omit<
  FooterModuleContentBottom,
  UnresolvedKeysOf<FooterModuleContentBottom, 'brandImage' | 'brandLink' | 'nav'>
> {
  /**
   * @see FooterModuleContentBottom.brandImage
   */
  brandImage?: BootstrapImageProps

  /**
   * @see FooterModuleContentBottom.brandLink
   */
  brandLink?: Link

  /**
   * @see FooterModuleContentBottom.nav
   */
  nav?: NavigationItem[]
}

/**
 * `ResolvedFooterModuleContentMiddle` with all related data already resolved.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface ResolvedFooterModuleContentMiddle {
  /**
   * @see FooterModuleContentMiddle.bottomNav
   */
  bottomNav?: NavigationItem[]

  /**
   * @see FooterModuleContentMiddle.contactSection
   */
  contactSection?: ResolvedContactSection

  /**
   * @see FooterModuleContentMiddle.mainNav
   */
  mainNav: NavigationItem[]

  /**
   * @see FooterModuleContentMiddle.socialNav
   */
  socialNav?: NavigationItem[]
}

/**
 * `ResolvedFooterModuleContentTop` with all related data already resolved.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface ResolvedFooterModuleContentTop {
  /**
   * @see FooterModuleContentTop.nav
   */
  nav?: NavigationItem[]
}
