// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { DescriptionOfSocialPostsFeed, ResolvedSocialPost } from '../../../models'
import { RelatedType } from '../../../contracts'

import { AbstractSetDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a set of social posts.
 *
 * @see HashtagWallModule
 * @see HashtagWallModuleForm
 * @see HashtagWallModuleUi
 * @see HashtagWallModuleUiPresentation
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export class SocialPostsFeedDriver extends AbstractSetDriver<DescriptionOfSocialPostsFeed, ResolvedSocialPost> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected childType: RelatedType = RelatedType.SocialPost

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.SocialPost]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.SocialPostsFeed
}
