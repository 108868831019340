// Copyright © 2022 Move Closer

import { BootstrapTheme } from '@movecloser/ui-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const BUTTON_MODULE_THEME = {
  Black: 'black',
  Danger: BootstrapTheme.Danger,
  Dark: BootstrapTheme.Dark,
  Info: BootstrapTheme.Info,
  Light: BootstrapTheme.Light,
  Link: 'link',
  Primary: BootstrapTheme.Primary,
  Secondary: BootstrapTheme.Secondary,
  Success: BootstrapTheme.Success,
  Warning: BootstrapTheme.Warning,
  White: 'white'
}
