// Copyright © 2021 Move Closer

import {
  CanHaveHeading, LinkWithLabel,
  Module,
  ModuleContent,
  ModuleDriver,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type CardModule = Module<CardModuleContent, ModuleDriver.Card>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface CardModuleContent extends ModuleContent, CanHaveHeading {
  /**
   * Card's background (must be a valid CSS value).
   */
  background?: CardBackgroundColor

  /**
   * Card's body.
   */
  body: string

  /**
   * Determines whether the image should be cropped to the default shape.
   */
  cropImage?: boolean

  /**
   * Card's image.
   */
  image?: Related<RelatedType.File> | null

  /**
   * Array of labels to render.
   */
  labels?: string[]

  /**
   * Link associated with the card.
   * User will be redirected to this target when he clicks the card.
   */
  link: UnresolvedLink | LinkWithLabel | null

  /**
   * Determines whether the card's body should end with the "read more" button.
   */
  showReadMoreBtn?: boolean

  /**
   * Determines whether the card's body should be wrapped around with a border.
   */
  withBorder?: boolean
}

/**
 * TODO: Fill with necessary colors when palette is ready
 */
export enum CardBackgroundColor {
  Black = '#000000',
  Gray = '#cccccc',
  Transparent = 'transparent',
  White = '#ffffff'
}
