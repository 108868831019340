// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { ImageModule, ImageModuleContent } from './Image.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const imageContentFactory = (): ImageModuleContent => ({ image: null })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const imageFactory: ModuleFactory<ImageModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<ImageModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: imageContentFactory(),
      driver: ModuleDriver.Image
    }
  }
}
