// Copyright © 2022 Move Closer

import { AnimationOptions, PathDrawingOptions } from 'progressbar.js'

import { HorizontalAlignment } from '../../../contracts'

/**
 * Default options used when animating an instance of a `Circle` class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const CIRCLE_ANIMATION_OPTIONS: AnimationOptions = {
  easing: 'easeInOut'
}

/**
 * Default configuration used when creating a new instance of a `Circle` class.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const CIRCLE_PATH_DRAWING_OPTIONS: PathDrawingOptions = {
  color: '#398154',
  strokeWidth: 5,
  trailColor: '#DFDFDF',
  trailWidth: 5
}

/**
 * Default horizontal alignment of the Tile's content.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const DEFAULT_CONTENT_ALIGNMENT: HorizontalAlignment = HorizontalAlignment.Left

/**
 * Default horizontal alignment of the Tile's heading.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_HEADING_ALIGNMENT: HorizontalAlignment = HorizontalAlignment.Left
