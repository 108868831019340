// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { AnyDescription, Description, Related } from '../../contracts'

import { AbstractRelatedService } from './related.abstract'
import { DriverConfig, ResolvesRelated, ResolvesRelatedAsync } from './related.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Injectable()
export class AsyncRelatedService extends AbstractRelatedService implements ResolvesRelatedAsync {
  /**
   * @inheritDoc
   */
  public describe <T extends Description = AnyDescription> (related: Related): Promise<T> {
    this.validateRelated(related)

    const driver = this.resolveDriver<T>(related.type)

    try {
      const description = driver.describe(`${related.value}`, this.record)
      return Promise.resolve(description)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * @inheritDoc
   */
  public resolve <T extends (Description | Description[]) = AnyDescription> (
    related: Related, config?: DriverConfig
  ): Promise<T> {
    this.validateRelated(related)

    if (typeof config === 'undefined') {
      config = {}
    }

    const driver = this.resolveDriver<T>(related.type)

    try {
      const description = driver.resolve(`${related.value}`, this.record, config, this.resolveDriver.bind(this))
      return Promise.resolve(description)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Injectable()
export class RelatedService extends AbstractRelatedService implements ResolvesRelated {
  /**
   * @inheritDoc
   */
  public describe <T extends Description = AnyDescription> (related: Related): T {
    this.validateRelated(related)

    const driver = this.resolveDriver<T>(related.type)
    return driver.describe(`${related.value}`, this.record)
  }

  /**
   * @inheritDoc
   */
  public resolve <T extends (Description | Description[]) = AnyDescription> (
    related: Related, config?: DriverConfig
  ): T {
    this.validateRelated(related)

    if (typeof config === 'undefined') {
      config = {}
    }

    const driver = this.resolveDriver<T>(related.type)
    return driver.resolve(`${related.value}`, this.record, config, this.resolveDriver.bind(this))
  }
}
