





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { BootstrapButton, BootstrapIcon, BootstrapImage, BootstrapLink } from '@movecloser/ui-core'

import { FetchesRelatedAsync } from '../../../../../../contracts'
import { EmbedContent } from '../../../Embed.contracts'
import { Image, ImageFile, ImageRatio, VideoFile } from '../../../../../../models'
import { isRelated, ResolvesRelatedAsync } from '../../../../../../services'
import { toBootstrapImageProps } from '../../../../../../support'

/**
 * Component capable to render the `EmbedModule`
 * with the version set `EmbedVersion.Video`
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<EmbedVideoUi>({
  name: 'EmbedVideoUi',
  components: {
    BootstrapLink,
    BootstrapButton,
    BootstrapIcon,
    BootstrapImage
  },

  async prefetch (): Promise<void> {
    await this.fetchRelated()
  },

  mounted (): void {
    this.$emit('ready')
  }
})
export class EmbedVideoUi extends Vue implements FetchesRelatedAsync {
  /**
   * Additional description of the embedded content.
   */
  @Prop({ type: String, required: false })
  public readonly description?: EmbedContent['description']

  /**
   * Additional description of the embedded content.
   */
  @Prop({ type: String, required: true })
  public readonly imageRatio!: ImageRatio

  /**
   * Additional video cover.
   */
  @Prop({ type: Object, required: false })
  public readonly thumbnail?: EmbedContent['thumbnail']

  /**
   * Actual Related pdf to resolve
   */
  @Prop({ type: Object, required: false })
  public readonly video?: EmbedContent['video']

  /**
   * Service capable of resolving the related data.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync

  /**
   * Determines whether the YouTube player has been initialised.
   */
  public isPlayerInitialised: boolean = false

  /**
   * Resolved video file
   */
  public resolvedVideo: VideoFile | null = null

  /**
   * Ready to use `Image` object, resolved using `RelatedService`.
   */
  public resolvedThumbnail: Image | null = null

  public get hasThumbnail (): boolean {
    return this.resolvedThumbnail !== null
  }

  public get url (): string {
    if (!this.resolvedVideo) {
      return ''
    }
    return this.resolvedVideo.url
  }

  public get type (): string {
    if (!this.resolvedVideo) {
      return ''
    }
    return 'video/' + this.resolvedVideo.mime
  }

  public onPlayBtnClick (): void {
    this.isPlayerInitialised = true
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.resolveFile()
    await this.resolveThumbnail()
  }

  /**
   * Resolves the pdf file, if any.
   */
  private async resolveFile (): Promise<void> {
    if (typeof this.video === 'undefined') {
      return
    }

    try {
      this.resolvedVideo = await this.relatedService.resolve(this.video) as unknown as VideoFile
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   * Resolves the thumbnail image, if any.
   */
  private async resolveThumbnail (): Promise<void> {
    if (typeof this.thumbnail === 'undefined' || !isRelated(this.thumbnail)) {
      return
    }

    let imageFile: ImageFile
    try {
      imageFile = await this.relatedService.resolve(this.thumbnail) as unknown as ImageFile
    } catch (e) {
      console.warn(e)
      return
    }

    this.resolvedThumbnail = toBootstrapImageProps(imageFile, this.imageRatio)
  }
}

export default EmbedVideoUi
