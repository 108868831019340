// Copyright © 2021 Move Closer

import { mapModel, MappingConfig } from '@movecloser/front-core'

import { DescriptionOfFile, FileType, DescriptionOfGallery } from '../../../models'
import { Identifier, RelatedType } from '../../../contracts'

import { AbstractSetDriver } from './_abstract'
import { DriverConfig, RelatedRecord, ResolvesDriver } from '../related.contracts'

/**
 * @author Olga Milczek <lukasz.sitnicki@movecloser.pl>
 */
export class GalleryDriver extends AbstractSetDriver<DescriptionOfGallery, DescriptionOfFile<FileType.Image>> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {}

  /**
   * @inheritDoc
   */
  protected childType: RelatedType = RelatedType.File

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.File]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.Gallery

  /**
   * Current position of list pointer.
   * @protected
   */
  protected positions: {[key: string]: number} = {}

  /**
   * @inheritDoc
   */
  public reset (): void {
    this.positions = {}
  }

  /**
   * @inheritDoc
   */
  public resolve (id: string, record: RelatedRecord, config: DriverConfig, driverResolver: ResolvesDriver): DescriptionOfFile<FileType.Image>[] {
    this.throwWhenMissing(id, record)

    const set: DescriptionOfGallery = this.describe(id, record)

    // Check if the output is as expected.
    if (!Array.isArray(set.items)) {
      throw new Error(
        `AbstractSetDriver.resolve(): Failed to resolve items for the [${this.key}] of a given ID [${id}]!`
      )
    }

    const childDriver = driverResolver(this.childType)

    let contentToFind: Identifier[] = [...set.items]

    // Let's we need to consider an entry conditions. If we have limit set,
    // should we prevent against duplicates & if the pointer should be moved.
    const limit: number = !!config.take && typeof config.take === 'number' ? Math.floor(config.take) : set.totalItems
    const continueOrder: boolean = typeof config.continueOrder === 'undefined' || config.continueOrder
    // In case of limit let's narrow the range.
    const startPosition = continueOrder ? this.getPositionOfSet(id) : 0
    let endPosition = startPosition + limit

    contentToFind = contentToFind.filter((el: Identifier, index: number) => {
      return startPosition <= index && index < endPosition
    })

    // If end position is bigger than totalItems of gallery get next items from the begging
    while (endPosition > set.totalItems) {
      endPosition = endPosition - set.totalItems
      const fromStart = set.items.slice(0, endPosition)

      contentToFind.push(...fromStart)
    }

    if (continueOrder) {
      this.setPositionOfSet(id, endPosition)
    }

    return contentToFind.reduce<DescriptionOfFile<FileType.Image>[]>((acc: DescriptionOfFile<FileType.Image>[], curr: Identifier) => {
      if (!childDriver.canDescribe(`${curr}`, record)) {
        return acc
      }

      return [
        ...acc,
        mapModel<DescriptionOfFile<FileType.Image>>(
          childDriver.resolve(`${curr}`, record, config, driverResolver),
          this.adapterMap,
          Object.keys(this.adapterMap).length === 0
        )
      ]
    }, [])
  }

  protected getPositionOfSet (set: string): number {
    if (!this.hasPositionOfSet(set)) {
      this.positions[set] = 0
    }

    return this.positions[set]
  }

  protected setPositionOfSet (set: string, newPositions: number): void {
    this.positions[set] = newPositions
  }

  private hasPositionOfSet (set: string): boolean {
    return typeof this.positions[set] === 'number'
  }
}
