// Copyright © 2022 Move Closer

import { Color, ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { NewsletterModule, NewsletterModuleContent } from './Newsletter.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const newsletterContentFactory = (): NewsletterModuleContent => ({ color: Color.Body })

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const newsletterFactory: ModuleFactory<NewsletterModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      minColSpan: 4,
      minRowSpan: 1,
      maxColSpan: 12,
      maxRowSpan: 12,
      rowSpan: 1
    }
  },
  createModule (): ModuleBase<NewsletterModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.Newsletter,
      content: newsletterContentFactory()
    }
  }
}
