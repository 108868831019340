// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { MetaPropertyName, MetaPropertyProperty } from 'vue-meta'

import { RelatedRecord } from '../services'
import { isPlainObject } from 'lodash'

/**
 * Describes the structure of the object that describes the given content's parent page. It's a recursive object that
 * contains the info about the page that's one level above the given content. It also contains the info about every
 * level up in the page tree (that's the recursive part), up to the root page (homepage).
 *
 * @recursive
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ContentParent {
  /**
   * Page's unique identifier.
   */
  id: number

  /**
   * Human-readable name (title) of the page.
   */
  name: string

  /**
   * Page's parent (or `null`, if that's the root page (homepage)).
   */
  parent: ContentParent | null

  /**
   * Page's slug.
   *
   * TODO: Is it absolute, like `/foo/bar/baz`, or just the `baz` part?
   */
  slug: string
}

/**
 * Checks if the passed-in object correctly implements the `ContentParent` interface.
 *
 * @param subject - The subject (object) to check.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isContentParent = (subject: any): subject is ContentParent => {
  return !(
    !isPlainObject(subject) ||
    typeof subject.id !== 'number' ||
    typeof subject.name !== 'string' ||
    (subject.parent !== null && !isContentParent(subject.parent)) ||
    typeof subject.slug !== 'string'
  )
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type RegistryContent = AnyObject & { parent: ContentParent | null }

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface RegistryResponse {
  content: RegistryContent
  meta: (MetaPropertyName | MetaPropertyProperty)[]
  related: RelatedRecord
}

/**
 * Injection key that should be used EVERY TIME you want to inject the `RegistryResponse` object into you component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const REGISTRY_RESPONSE_INJECTION_KEY = Symbol.for('RegistryResponse')
