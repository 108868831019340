// Copyright © 2021 Move Closer

import { Link } from '@movecloser/ui-core'

import {
  Module,
  ModuleContent,
  ModuleDriver,
  Related,
  RelatedType,
  UnresolvedLink
} from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type NavbarModule = Module<NavbarModuleContent, ModuleDriver.Navbar>

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface NavbarModuleContent extends ModuleContent {
  /**
   * Navigation to be related to the bottom of the module.
   */
  bottomNav?: Related<RelatedType.Navigation> | null

  /**
   * Image to be rendered as a logotype.
   */
  brandImage?: Related<RelatedType.File> | null

  /**
   * Link associated with the brand image (the logotype).
   */
  brandLink?: Link | null

  /**
   * Text to be rendered next to the brand image (the logotype).
   */
  brandText?: string

  /**
   * The page that the User should be redirected to when performing the search action.
   */
  searchResultsPage: Link | UnresolvedLink | null

  /**
   * Navigation to be related to the top section of the module, on the **left** side.
   */
  topLeftNav?: Related<RelatedType.Navigation> | null

  /**
   * Navigation to be related to the top section of the module, on the **right** side.
   */
  topRightNav?: Related<RelatedType.Navigation> | null
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum NavbarSectionType {
  Top = 'top',
  Main = 'main'
}
