// Copyright © 2022 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion } from '../../../contracts'

import { UiContainer } from '../../atoms'

import { EmbedAddon, ImageAddon, ResolvedImageAddon } from './addons'

export type MosaicAddons = EmbedAddon | ImageAddon

export type MosaicModule = ModuleWithVersion<
  MosaicModuleBasicVersionContent,
  ModuleDriver.Mosaic,
  MosaicModuleVersion
>

export interface MosaicModuleAddon<Type = MosaicModuleAddonType> {
  type: Type
  size: MosaicModuleAddonSize
}

export enum MosaicModuleAddonType {
  Embed = 'embed',
  Image = 'image',
}

export enum MosaicModuleAddonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface MosaicModuleBaseContent extends ModuleContent {
  containerWidth: UiContainer['width']
  hasBottomSpacing?: boolean
}

export type MosaicModuleBasicVersionContent = MosaicModuleBaseContent & MosaicModuleSlideContent

export interface MosaicModuleSlideContent {
  addons: MosaicModuleAddon[]
}

export enum MosaicModuleVersion {
  Basic = 'basic'
}

export type ResolvedMosaicModuleAddon = EmbedAddon | ResolvedImageAddon

export interface ResolvedMosaicModuleSlideContent {
  addons: ResolvedMosaicModuleAddon[]
}
