
















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import { UiDataCircleSize } from './UiDataCircle.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<UiDataCircle>({
  name: 'UiDataCircle',
  components: {
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../atoms/UiMarkdown/UiMarkdown.vue')
  }
})
export class UiDataCircle extends Vue {
  /**
   * Main content of the circle - value for the `<dt>` element.
   */
  @Prop({ type: String, required: true })
  public readonly content!: string

  /**
   * The label for the circle - value for the `<dd>` element.
   */
  @Prop({ type: String, required: false })
  public readonly label?: string

  /**
   * Size of the circle.
   */
  @Prop({ type: String, required: false, default: UiDataCircleSize.Small })
  public readonly size!: UiDataCircleSize

  /**
   * Reference to `.UiDataCircle__content` element.
   */
  @Ref('circle')
  private readonly circleRef?: HTMLElement

  /**
   * Centers the circle contents vertically.
   */
  public centerContent (markdownRenderer: Element): void {
    if (!this.circleRef || !(markdownRenderer instanceof HTMLElement)) {
      return
    }

    const circleHeight: number = this.circleRef.getBoundingClientRect().height
    const textHeight: number = markdownRenderer.getBoundingClientRect().height
    const correctiveOffset: number = this.size === UiDataCircleSize.Big ? -10 : 0

    markdownRenderer.style.marginTop = `${(circleHeight - textHeight) / 2 + correctiveOffset}px`
  }

  /**
   * `this.content` with line feed characters (`\n`) replaced with line breaks (`<br/>`).
   *
   * @see content
   */
  public get contentWithLineBreaks (): string {
    return this.content.replace(/\n/g, '<br/>')
  }

  /**
   * Determines whether there's a valid content to be rendered.
   */
  public get hasContent (): boolean {
    return typeof this.content === 'string' && this.content.length > 0
  }

  /**
   * Determines whether there's a valid label to be rendered.
   */
  public get hasLabel (): boolean {
    return typeof this.label === 'string' && this.label.length > 0
  }
}

export default UiDataCircle
