// Copyright © 2022 Move Closer

/**
 * Method to get proper pluralize option in Polish
 *
 * @author Olga Milczek <olga.milczek@movwcloser.pl>
 * @author Filip Rurak <filip.ruak@movecloser.pl> (edited)
 */
export const pluralizationRulesPL = (choice: number): number => {
  if (choice >= 0 && choice < 5) {
    return choice
  }

  return 5
}
