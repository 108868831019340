// Copyright © 2021 Move Closer

import { CanBeAlignedHorizontally, CanHaveColor, Module, ModuleContent, ModuleDriver } from '../../../contracts'
import { HeadingFont } from '../../atoms/UiHeading/UiHeading.contracts'
export { HeadingFont } from '../../atoms/UiHeading/UiHeading.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type HeadingModule = Module<HeadingModuleContent, ModuleDriver.Heading>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HeadingModuleContent extends ModuleContent, CanBeAlignedHorizontally, CanHaveColor {
  /**
   * Heading's display level (from 1 to 6).
   */
  displayLevel?: number

  /**
   * Heading's level (from 1 to 6).
   */
  level: number

  /**
   * The text that is to be prepended to the main heading's content.
   * Will be rendered in the primary color and larger font size.
   */
  prependText?: string

  /**
   * Heading's text content (with Markdown support (will be parsed to HTML)).
   */
  text: string

  /**
   * Text zoom (in %).
   */
  zoom?: number

  /**
   * Font type
   */
  font: HeadingFont
}
