


















































































import { BootstrapButton, BootstrapIcon, BootstrapLink } from '@movecloser/ui-core'
import { Circle } from 'progressbar.js'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { isPlainObject } from 'lodash'

import { collapse } from '../../../extensions'
import { HorizontalAlignment } from '../../../contracts'
import { isLink, log } from '../../../support'

import { UiHeading, UiMarkdown } from '../../atoms'

import { TileBehavior, TileIconPlacement, TileProps } from './Tile.contracts'
import {
  CIRCLE_ANIMATION_OPTIONS,
  CIRCLE_PATH_DRAWING_OPTIONS,
  DEFAULT_CONTENT_ALIGNMENT,
  DEFAULT_HEADING_ALIGNMENT
} from './Tile.config'

/**
 * @emits collapsed - When the `extendedBody` contents are being hidden.
 * @emits expanded - When the `extendedBody` contents are being revealed.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Tile>({
  name: 'Tile',
  components: { BootstrapButton, BootstrapIcon, BootstrapLink, UiHeading, UiMarkdown },
  directives: { collapse },
  mounted (): void {
    if (this.hasDataCircle) {
      this.createDataCircle()
      this.watchDataCircle()
    }
  },
  beforeDestroy (): void {
    this.stopWatchingDataCircle()
  }
})
export class Tile extends Vue {
  /**
   * @see TileProps.behavior
   */
  @Prop({ type: String, required: true })
  public readonly behavior!: TileProps['behavior']

  /**
   * @see TileProps.body
   */
  @Prop({ type: String, required: false })
  public readonly body?: TileProps['body']

  /**
   * @see TileProps.border
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly border!: TileProps['border']

  /**
   * @see TileProps.contentAlignment
   */
  @Prop({ type: String, required: false })
  public readonly contentAlignment?: TileProps['contentAlignment']

  /**
   * @see TileProps.dataCircle
   */
  @Prop({ type: Object, required: false })
  public readonly dataCircle?: TileProps['dataCircle']

  /**
   * @see TileProps.extendedBody
   */
  @Prop({ type: String, required: false })
  public readonly extendedBody?: TileProps['extendedBody']

  /**
   * @see TileProps.heading
   */
  @Prop({ type: Object, required: true })
  public readonly heading!: TileProps['heading']

  /**
   * @see TileProps.headingAlignment
   */
  @Prop({ type: String, required: false })
  private readonly headingAlignment?: TileProps['headingAlignment']

  /**
   * @see TileProps.iconColor
   */
  @Prop({ type: String, required: false })
  public readonly iconColor?: TileProps['iconColor']

  /**
   * @see TileProps.iconName
   */
  @Prop({ type: String, required: false })
  public readonly iconName?: TileProps['iconName']

  /**
   * @see TileProps.iconPlacement
   */
  @Prop({ type: String, required: false, default: TileIconPlacement.Top })
  public readonly iconPlacement!: TileProps['iconPlacement']

  /**
   * @see TileProps.large
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly large?: TileProps['large']

  /**
   * @see TileProps.link
   */
  @Prop({ type: Object, required: false })
  public readonly link?: TileProps['link']

  /**
   * @see TileProps.linkClassName
   */
  @Prop({ type: String, required: false })
  public readonly linkClassName?: TileProps['linkClassName']

  /**
   * @see TileProps.rounded
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly rounded!: TileProps['rounded']

  /**
   * @see TileProps.shadow
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly shadow!: TileProps['shadow']

  /**
   * @see TileProps.tag
   */
  @Prop({ type: String, required: false, default: 'div' })
  public readonly tag!: TileProps['tag']

  /**
   * @see TileProps.transparent
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly transparent!: TileProps['transparent']

  @Ref('dataCircleRef')
  private readonly dataCircleRef?: HTMLDivElement

  /**
   * An instance of the Circle class created inside the `animateDataCircle()` method.
   *
   * FIXME: There's a `@ts-expect-error` comment that probably can be removed
   * somehow and replaced with the correct TS syntax. Sadly, I have no idea how to properly
   * type this property. If you have any idea, feel free to propose some changes.
   *
   * @see createDataCircle()
   * @see Circle
   */
  // @ts-expect-error - I don't know how to fix this error. I've tried many different approaches,
  // but didn't achieve any satisfying results.
  private dataCircleCircle: Circle | null = null

  /**
   * `IntersectionObserver` watching the `.Tile__data-circle` element
   * and checking if it has entered the viewport.
   */
  private dataCircleObserver: IntersectionObserver | null = null

  /**
   * Determines whether the extended body is currently collapsed.
   */
  public isExtendedBodyCollapsed: boolean = true

  /**
   * Registry that binds the `HorizontalAlignment` with the applicable CSS class.
   */
  public readonly horizontalAlignmentClassNameRegistry: Record<HorizontalAlignment, string> = {
    [HorizontalAlignment.Left]: '--left',
    [HorizontalAlignment.Center]: '--center',
    [HorizontalAlignment.Right]: '--right',
    [HorizontalAlignment.Justify]: '--justify'
  }

  /**
   * CSS class that determines the horizontal alignment of the tile's heading.
   */
  public get headingAlignmentClassName (): string {
    if (typeof this.headingAlignment === 'undefined') {
      return ''
    }

    if (!(this.headingAlignment in this.horizontalAlignmentClassNameRegistry)) {
      log(`Given alignment [${this.headingAlignment}] is not supported. Alignment has been set to its default value ([${DEFAULT_HEADING_ALIGNMENT}]).`, 'warn')
      return this.horizontalAlignmentClassNameRegistry[DEFAULT_HEADING_ALIGNMENT]
    }

    return this.horizontalAlignmentClassNameRegistry[this.headingAlignment]
  }

  /**
   * CSS class that determines the horizontal alignment of the tile's content.
   */
  public get contentAlignmentClassName (): string {
    if (typeof this.contentAlignment === 'undefined') {
      return ''
    }

    if (!(this.contentAlignment in this.horizontalAlignmentClassNameRegistry)) {
      log(`Given alignment [${this.contentAlignment}] is not supported. Alignment has been set to its default value ([${DEFAULT_CONTENT_ALIGNMENT}]).`, 'warn')
      return this.horizontalAlignmentClassNameRegistry[DEFAULT_CONTENT_ALIGNMENT]
    }

    return this.horizontalAlignmentClassNameRegistry[this.contentAlignment]
  }

  /**
   * Percentage of the data circle to fill with color.
   */
  public get dataCircleFillPercentage (): number | undefined {
    if (!this.hasDataCircle) {
      return undefined
    }

    if (
      typeof this.dataCircle?.fillPercentage !== 'number' ||
      this.dataCircle.fillPercentage < 0 ||
      this.dataCircle.fillPercentage > 100
    ) {
      return 100
    }

    return this.dataCircle.fillPercentage
  }

  /**
   * Determines whether the `behavior` @Prop has been properly defined.
   */
  public get hasBehavior (): boolean {
    return typeof this.behavior !== 'undefined'
  }

  /**
   * Determines whether the `dataCircle` @Prop has been properly defined.
   */
  public get hasDataCircle (): boolean {
    return typeof this.dataCircle !== 'undefined' &&
      isPlainObject(this.dataCircle) &&
      typeof this.dataCircle.content === 'string' &&
      this.dataCircle.content.length > 0
  }

  /**
   * Determines whether the `heading` @Prop has been properly defined.
   */
  public get hasHeading (): boolean {
    return typeof this.heading !== 'undefined' &&
      typeof this.heading.text === 'string' &&
      typeof this.heading.level === 'number'
  }

  /**
   * Determines whether the `link` @Prop has been properly defined.
   */
  public get hasLink (): boolean {
    return isLink(this.link)
  }

  /**
   * Determines whether the icon should be collapsed.
   */
  public get isIconCollapsed (): boolean {
    if (this.iconPlacement !== TileIconPlacement.Top) {
      return false
    }

    return !this.isExtendedBodyCollapsed
  }

  /**
   * Determines whether the component is collapsible.
   *
   * @see Tile.behavior
   */
  public get isCollapsible (): boolean {
    return this.behavior === TileBehavior.Collapse
  }

  /**
   * Handles the `@click` event on the "collapse" button.
   */
  public onBtnClick (): void {
    this.toggleBody()
  }

  /**
   * Handler for the `transitionCallback` of the `v-collapse` directive.
   *
   * @see collapse
   * @see src/shared/modules/src/extensions/directives/collapse.ts:79
   */
  public vCollapseTransitionCallback (): void {
    this.$emit(this.isExtendedBodyCollapsed ? 'collapsed' : 'expanded')
  }

  /**
   * Animates the data circle.
   */
  private animateDataCircle (): void {
    if (!this.hasDataCircle) {
      return
    }

    if (this.dataCircleCircle === null) {
      log(`Tile.animateDataCircle(): Expected [this.dataCircleCircle] to be an instance of a [Circle], but got [${this.dataCircleCircle}]!`, 'error')
      return
    }

    const progress: number = (this.dataCircleFillPercentage as number) * 0.01

    this.dataCircleCircle.animate(progress, CIRCLE_ANIMATION_OPTIONS)
  }

  /**
   * Creates the data circle.
   */
  private createDataCircle (): void {
    if (typeof this.dataCircleRef === 'undefined') {
      log(`Tile.createDataCircle(): Expected [this.dataCircleRef] to be an instance of [HTMLDivElement], but got [${typeof this.dataCircleRef}]!`, 'error')
      return
    }
    this.dataCircleCircle = new Circle(this.dataCircleRef, CIRCLE_PATH_DRAWING_OPTIONS)

    const circleEl = this.dataCircleRef.querySelector<HTMLElement>('svg')

    if (!circleEl) {
      return
    }

    circleEl.setAttribute('aria-hidden', 'true')
  }

  /**
   * Shows the extended state of the body section.
   */
  private showExtendedBody (): void {
    this.isExtendedBodyCollapsed = false
  }

  /**
   * Shows the initial state of the body section.
   */
  private showInitialBody (): void {
    this.isExtendedBodyCollapsed = true
  }

  /**
   * Disconnects the `IntersectionObserver` watching the `.Tile__data-circle` element.
   */
  private stopWatchingDataCircle (): void {
    if (typeof window === 'undefined' || this.dataCircleObserver === null) {
      return
    }

    this.dataCircleObserver.disconnect()
  }

  /**
   * Toggles (expands/collapses) the body section.
   */
  private toggleBody (): void {
    this.isExtendedBodyCollapsed ? this.showExtendedBody() : this.showInitialBody()
  }

  /**
   * Watches the `.Tile__data-circle` element and checks if it has entered the viewport.
   */
  private watchDataCircle (): void {
    if (typeof window === 'undefined') {
      return
    }

    if (typeof this.dataCircleRef === 'undefined') {
      log(`Tile.watchDataCircle(): Expected [this.dataCircleRef] to be an instance of [HTMLDivElement], but got [${typeof this.dataCircleRef}]!`, 'error')
      return
    }

    this.dataCircleObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animateDataCircle()
        }
      })
    })

    this.dataCircleObserver.observe(this.dataCircleRef)
  }
}

export default Tile
