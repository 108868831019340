// Copyright © 2022 Move Closer

/**
 * Creates array in particular range
 *
 * @param start - Start value
 * @param end - End value
 * @param step - Step in which array should increment its child.
 *
 * @returns - Array[type=number]
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const fromRange = (start: number, end: number, step: number = 1): number[] => {
  return Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), x => start + x * step)
}
