








import { Component } from 'vue-property-decorator'
import { Link } from '@movecloser/ui-core'

import { DescriptionOfJobOffer, JobOffer } from '../../../../models'
import { isLink, log } from '../../../../support'
import { isRelated } from '../../../../services'

import { AbstractModuleUi } from '../../_abstract'

import { JobOffersModule, JobOffersModuleVersion } from '../JobOffers.contracts'

/**
 * Container component for the `JobOffersModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<JobOffersModuleUi>({
  name: 'JobOffersModuleUi',
  components: {
    JobOffersModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './JobOffers.ui.presentation.vue'
    )
  }
})
export class JobOffersModuleUi extends AbstractModuleUi<JobOffersModule> {
  /**
   * Array of the job offers to render.
   */
  public offers: JobOffer[] = []

  /**
   * Additional "read more" link to render.
   */
  public readMoreLink: Link | null = null

  /**
   * @inheritDoc
   */
  protected versionEnum = JobOffersModuleVersion

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await Promise.allSettled([
      this._fetchLink(),
      this.fetchOffers()
    ])
  }

  /**
   * @see JobOffersModuleUi.headingContent
   */
  public get headingContent (): string | undefined {
    if (typeof this.data.content.heading === 'undefined') {
      return
    }

    return this.data.content.heading.text
  }

  /**
   * @see JobOffersModuleUi.headingLevel
   */
  public get headingLevel (): number | undefined {
    if (typeof this.data.content.heading === 'undefined') {
      return
    }

    return this.data.content.heading.level
  }

  /**
   * @see JobOffersModuleUi.openLinkInNewTab
   */
  public get openLinkInNewTab (): boolean {
    return !!this.data.content.openLinkInNewTab
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasValidVersion
  }

  /**
   * Fetches the "read more" link from the `RelatedService`.
   */
  private async _fetchLink (): Promise<void> {
    const unresolvedLink = this.data.content.readMoreLink

    if (!unresolvedLink) {
      return
    }

    if (isLink(unresolvedLink)) {
      this.readMoreLink = unresolvedLink
    } else {
      this.readMoreLink = await this.fetchLink(unresolvedLink)
    }
  }

  /**
   * Fetches the job offers from the `RelatedService`.
   */
  private async fetchOffers (): Promise<void> {
    const unresolvedOffers = this.data?.content?.offers

    if (!isRelated(unresolvedOffers)) {
      const message: string = 'JobOffersModuleUi.loadOffers(): [this.data.content.offers] is not a valid related object!'
      log([message, unresolvedOffers], 'error')
      return
    }

    try {
      this.offers = await this.relatedService.resolve<DescriptionOfJobOffer[]>(unresolvedOffers)
    } catch (error) {
      const message: string = 'JobOffersModuleUi.loadOffers(): Failed to fetch the related data!'
      log([message, error], 'error')
    }
  }

  /**
   * Determines whether the component has successfully fetched the job offers from the `RelatedService`.
   */
  private get hasOffers (): boolean {
    return this.offers.length > 0
  }
}

export default JobOffersModuleUi
