// Copyright © 2022 Move Closer

import { AnyObject, Intersected } from '@movecloser/front-core'

import { AnyDescription, Description, Related, RelatedType } from '../../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface AggregatesRelated {
  /**
   * Stores related to be able to resolve when needed.
   *
   * @param record
   */
  storeRelated (record: RelatedRecord): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type DescriptionsRecord = Partial<Record<RelatedType, AnyObject>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface DriverConfig {
  continueOrder?: boolean
  preventDuplicates?: boolean
  take?: number
}

/**
 * Describes the entity that exposes the `RelatedRecord` object under the dedicated `getRecord()` method.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface ExposesRelatedRecord {
  /**
   * @returns - The current state of the related record.
   */
  getRecord (): RelatedRecord
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type PossibleTypeDriver<DataDescription extends (Description | Description[]) = AnyDescription> =
  RelatedTypeDriver<DataDescription>
  | Intersected<RelatedTypeDriver<DataDescription>, ResolvesRelatedTypeAsync>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 */
export type RelatedRecord = Partial<Record<RelatedType, RelatedRecordTypeEntries>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedRecordTypeEntries {
  [key: string]: AnyObject
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const RelatedServiceType = Symbol.for('IRelatedService')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface RelatedTypeDriver<
  DataDescription extends (Description | Description[]),
  ResolvedData = DataDescription
> {
  /**
   * Determine if there's a description in the record.
   * @param id
   * @param record
   */
  canDescribe (id: string, record: RelatedRecord): boolean

  /**
   * Resolve dependent RelatedTypes.
   */
  dependentTypes (): RelatedType[]

  /**
   * Resolves description of related.
   * @param record
   * @param id
   */
  describe (id: string, record: RelatedRecord): DataDescription

  /**
   * Resolves value of related.
   * @param record
   * @param id
   * @param driverResolver
   * @param config
   */
  resolve (id: string, record: RelatedRecord, config: DriverConfig, driverResolver?: ResolvesDriver): ResolvedData
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedTypeDriverConstructor<DataDescription extends Description> =
  new () => RelatedTypeDriver<DataDescription>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedTypeDriverRegistry = Partial<Record<RelatedType, PossibleTypeDriver>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ResolvesDriver = (type: RelatedType) => PossibleTypeDriver

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface ResolvesRelated {
  /**
   * Resolves description of the given related object.
   *
   * @param related
   */
  describe <T extends Description>(related: Related): T

  /**
   * Resolves the actual DATA using the provided type and ID of the related object.
   *
   * @param related - Related object which data we want to resolve.
   * @param [config={}] - Additional config (like e.g. list items' overrides).
   */
  resolve <T extends (Description | Description[])>(related: Related, config?: DriverConfig): T
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export interface ResolvesRelatedAsync {
  /**
   * Resolves description of the given related object.
   *
   * @param related
   */
  describe <T extends Description>(related: Related): Promise<T>

  /**
   * Resolves the actual DATA using the provided type and ID of the related object.
   *
   * @param related - Related object which data we want to resolve.
   * @param [config={}] - Additional config (like e.g. list items' overrides).
   */
  resolve <T extends (Description | Description[])>(related: Related, config?: DriverConfig): Promise<T>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ResolvesRelatedTypeAsync<M extends AnyObject = AnyObject, D extends AnyObject = AnyObject> {
  /**
   * TODO: Documentation.
   */
  loadDescription (id: string): Promise<D>

  /**
   * TODO: Documentation.
   */
  loadRelated (id: string): Promise<M>
}
