// Copyright © 2021 Move Closer

import { ModuleContent, ModuleDriver, ModuleWithVersion, Related, RelatedType } from '../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface EmbedContent extends ModuleContent {
  /**
   * Determine if autoplay YT video.
   */
  autoplay?: boolean

  /**
   * Additional description of the embedded content.
   *
   * @version EmbedVersion.YouTube
   * @version EmbedVersion.Video
   */
  description?: string

  /**
   * Video thumbnail.
   *
   * @version EmbedVersion.YouTube
   */
  thumbnail?: Related<RelatedType.File>

  /**
   * Relate video
   *
   * @version EmbedVersion.Video
   */
  video?: Related<RelatedType.File>

  /**
   * ID of the YouTube video.
   *
   * @version EmbedVersion.YouTube
   */
  videoId?: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type EmbedModule = ModuleWithVersion<EmbedContent, ModuleDriver.Embed, EmbedVersion>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum EmbedVersion {
  YouTube = 'youtube',
  // Video = 'video'
}
