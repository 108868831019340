







import { AbstractSelectControlOption, BootstrapImageProps, Link } from '@movecloser/ui-core'
import { Component, Provide } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { RawLocation, Route } from 'vue-router'

import { DescriptionOfLink, Identifier, Related, RelatedType } from '../../../../contracts'
import { isLink, log } from '../../../../support'
import { isRelated } from '../../../../services'
import { ISiteService, SiteServiceType } from '../../../../services/site'
import { NavigationItem } from '../../../../models/navigation'

import { AbstractModuleUi } from '../../_abstract'

import { NavbarModule } from '../Navbar.contracts'

import { SEARCH_CALLBACK_INJECTION_KEY } from './Navbar.ui.config'

/**
 * Container component for the `NavbarModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NavbarModuleUi>({
  name: 'NavbarModuleUi',
  components: {
    NavbarModuleUiPresentation: () => import(
      /* webpackChunkName: "frame" */
      './Navbar.ui.presentation.vue'
    )
  }
})
export class NavbarModuleUi extends AbstractModuleUi<NavbarModule> {
  /**
   * @see NavbarModuleUiPresentation.brandImage
   */
  public brandImage: BootstrapImageProps | null = null

  public erecruiterLink: Link = {
    newTab: true,
    isExternal: true,
    label: this.$t('modules.components.modules.Navbar.apply'),
    target: { path: 'https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=792225d305db4813bf1f43e6331561de' }
  }

  /**
   * Object to store resolved navigations
   */
  public resolvedNavigations: Record<string, NavigationItem[]> | null = null

  /**
   * @see NavbarModuleContent.searchResultsPage
   */
  private searchResultsPage: RawLocation | null = null

  /**
   * An instance of the `EventBus` service.
   */
  public get eventBus (): IEventbus {
    return this.resolveInjection<IEventbus>(EventbusType)
  }

  /**
   * @see NavbarModuleUiPresentation._currentLocale
   */
  public get currentLocale (): Identifier {
    return this.siteService.getActiveSite().id
  }

  /**
   * @see NavbarModuleUiPresentation._currentLocale
   */
  public set currentLocale (newLocale: Identifier) {
    const newSite = this.siteService.getSiteById(newLocale)

    if (!newSite || !window) {
      return
    }

    window.location.href = `${newSite.address}/${newSite.basePath}/${newSite.postfix}`
  }

  private get siteService (): ISiteService {
    return this.resolveInjection<ISiteService>(SiteServiceType)
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    this.setInitialResolvedNavigations()
    await Promise.allSettled([
      this.fetchBrandImage(),
      this.fetchNavigations(),
      this.fetchSearchResultsPagePath()
    ])
  }

  /**
   * @see NavbarModuleUiPresentation.locales
   */
  public get locales (): AbstractSelectControlOption[] {
    const availableLocales = this.siteService.getSites()
    return availableLocales.map<AbstractSelectControlOption>(site => ({
      label: site.locale.toUpperCase(),
      value: site.id
    }))
  }

  /**
   * Function that handles the search logic.
   *
   * @see SearchCallback
   */
  @Provide(SEARCH_CALLBACK_INJECTION_KEY)
  public search (query: string): Promise<Route> | void {
    if (typeof query !== 'string') {
      throw new Error(`Expected [query] to be a [string], but got [${typeof query}]!`)
    }

    if (query.length === 0) {
      throw new Error('[query] can NOT be empty!')
    }

    if (this.searchResultsPage === null) {
      throw new Error('[this.searchResultsPage] is null!')
    }

    try {
      if (typeof this.searchResultsPage === 'string') {
        return this.$router.push({ path: this.searchResultsPage, query: { q: query } })
      } else {
        return this.$router.push({ ...this.searchResultsPage, query: { q: query } })
      }
    } catch (error) {
      log(error, 'error')
    }
  }

  /**
   * Fetches the brand image from the `RelatedService`.
   *
   * @see NavbarModuleContent.brandImage
   */
  private async fetchBrandImage (): Promise<void> {
    const unresolvedImage = this.data.content.brandImage

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.brandImage = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'NavbarModuleUi.fetchBrandImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }

  private async fetchNavigations (): Promise<void> {
    const navigationKeys = ['bottomNav', 'topLeftNav', 'topRightNav']

    for (const key of navigationKeys) {
      const navigationToResolve: Related<RelatedType.Navigation> | null | undefined =
        this.data.content[key]

      if (!navigationToResolve || !isRelated(navigationToResolve)) {
        continue
      }

      try {
        if (this.resolvedNavigations === null) {
          return
        }

        this.resolvedNavigations[key] = await this.fetchNavigation(navigationToResolve)
      } catch (error) {
        log('NavbarModuleUi.fetchNavigations(): Failed to fetch the related navigation from the [RelatedService]!', 'error')
      }
    }
  }

  /**
   * Fetches the path to the search results page from the `RelatedService`.
   *
   * @see NavbarModuleContent.searchResultsPage
   */
  private async fetchSearchResultsPagePath (): Promise<void> {
    const searchResultsPage = this.data.content.searchResultsPage

    if (searchResultsPage === null) {
      log('NavbarModuleUi.fetchSearchResultsPagePath(): [this.data.content.searchResultsPage] is [null]!', 'warn')
      return
    }

    if (isLink(searchResultsPage)) {
      this.searchResultsPage = searchResultsPage.target
      return
    }

    if (!isRelated(searchResultsPage.relatedTarget)) {
      return
    }

    try {
      const descriptionOfLink = await this.relatedService.resolve<DescriptionOfLink>(searchResultsPage.relatedTarget)
      this.searchResultsPage = descriptionOfLink.url
    } catch (error) {
      log('NavbarModuleUi.fetchSearchResultsPagePath(): Failed to fetch the related link from the [RelatedService]!', 'error')
    }
  }

  /**
   * Sets the initial value for `this.resolvedNavigations` property.
   */
  private setInitialResolvedNavigations () {
    this.resolvedNavigations = {
      bottomNav: [],
      topLeftNav: [],
      topRightNav: []
    }
  }
}

export default NavbarModuleUi
