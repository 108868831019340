// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { JobOffersModule, JobOffersModuleContent, JobOffersModuleVersion } from './JobOffers.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const jobOffersContentFactory = (): JobOffersModuleContent => ({
  heading: undefined,
  offers: null,
  readMoreLink: null,
  selectedQuantity: undefined
})

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const jobOffersFactory: ModuleFactory<JobOffersModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 6,
      isResizable: true,
      minColSpan: 4,
      minRowSpan: 2,
      maxColSpan: 12,
      maxRowSpan: 12,
      rowSpan: 2
    }
  },
  createModule (): ModuleBase<JobOffersModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: jobOffersContentFactory(),
      driver: ModuleDriver.JobOffers,
      version: JobOffersModuleVersion.Table
    }
  }
}
