













import { Component, Prop } from 'vue-property-decorator'
import { DebouncedFunc, throttle } from 'lodash'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { Entry as IEntry, EntryType } from '../Timeline.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
interface MappedEntry extends IEntry {
  /**
   * CSS class that is to be attached to the HTML element.
   */
  className: string

  /**
   * Entry's number in the `entries` array (not the same as index!).
   */
  counter: number

  /**
   * Value for the `:key` binding.
   */
  key: string

  htmlId: string
}

/**
 * Presentational component for the `TimelineModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TimelineModuleUiPresentation>({
  name: 'TimelineModuleUiPresentation',
  components: {
    Entry: () => import(
      /* webpackChunkName: "modules/Timeline" */
      './partials/Entry/Entry.vue'
    )
  },
  created (): void {
    this.mappedEntries = TimelineModuleUiPresentation.mapEntries(this.entries, this.htmlId)
  },
  mounted (): void {
    this.registerWatchers()
  },
  beforeDestroy (): void {
    this.unregisterWatchers()
  }
})
export class TimelineModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of timeline entries to render.
   */
  @Prop({ type: Array, required: true })
  public readonly entries!: IEntry[]

  /**
   * Determines whether the starting dot (before the 1st entry) should be rendered.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly showStartingDot!: boolean

  /**
   * Determines whether the ending dot (after the last entry) should be rendered.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showEndingDot!: boolean

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Handler for the 'scroll' event responsible for updating the height of the vertical line.
   */
  private lineHeightWatcher: DebouncedFunc<() => void> | null = null

  /**
   * `entries` array supplemented with additional data.
   */
  public mappedEntries: MappedEntry[] = []

  /**
   * `IntersectionObserver` watching the `.Timeline__item` elements
   * to cross the vertical middle of the viewport.
   */
  private timelineItemsObserver: IntersectionObserver | null = null

  /**
   * Resolves the applicable CSS class for the `Entry` of a given type and array index.
   *
   * @param type - Type of the `Entry`.
   * @param index - Index of an entry in the antries array.
   *
   * @see EntryType
   */
  private static getClassNameForEntry (type: EntryType, index: number): string {
    let className: string = ''

    if (type === EntryType.Divider) {
      className = '--centered'
    } else {
      className = index % 2 !== 0 ? '--snap-right' : '--snap-left'
    }

    return className
  }

  /**
   * Determines whether the component has been provided with the correct `entries` prop.
   */
  public get hasEntries (): boolean {
    return typeof this.entries !== 'undefined' &&
      Array.isArray(this.entries) &&
      this.entries.length > 0
  }

  /**
   * Supplies the passed-in `entries` array with the additional data.
   *
   * @param entries - The entries to extend.
   */
  private static mapEntries (entries: IEntry[], id: string): MappedEntry[] {
    let counter: number = 0

    return entries.map<MappedEntry>((entry, index) => {
      if (entry.type !== EntryType.Divider) {
        counter++
      }

      const className: string = TimelineModuleUiPresentation.getClassNameForEntry(entry.type, index)

      const key: string = JSON.stringify(entry.data)

      return {
        ...entry,
        className,
        counter,
        key,
        htmlId: `${id}-${counter}`
      }
    })
  }

  /**
   * Registers all needed watchers.
   */
  private registerWatchers (): void {
    this.watchLineHeight()
    this.watchTimelineItems()
  }

  /**
   * Removes the watcher for the `scroll` event connected with the vertical line's height.
   */
  private stopWatchingLineHeight (): void {
    if (typeof window === 'undefined') {
      return
    }

    // eslint-disable-next-line no-undef
    window.removeEventListener('scroll', this.lineHeightWatcher as EventListenerOrEventListenerObject)
  }

  /**
   * Disconnects the `IntersectionObserver` watching the `.Timeline__item` elements.
   */
  private stopWatchingTimelineItems (): void {
    if (typeof window === 'undefined' || this.timelineItemsObserver === null) {
      return
    }

    this.timelineItemsObserver.disconnect()
  }

  /**
   * Unregisters all registered watchers.
   */
  private unregisterWatchers (): void {
    this.stopWatchingLineHeight()
    this.stopWatchingTimelineItems()
  }

  /**
   * Watches the `scroll` event on the `window` object and updates the height of the vertical line accordingly.
   */
  private watchLineHeight (): void {
    if (typeof window === 'undefined') {
      return
    }

    // Needed to overcome TS errors.
    const el = this.$el as HTMLElement

    this.lineHeightWatcher = throttle(() => {
      const elPosFromDocumentTop: number = el.getBoundingClientRect().top + document.documentElement.scrollTop

      // Factor value from 0 to 1 which allow timeline height to return to 0 after scrolling up
      const heightIncrementFactor = Math.min(1, window.scrollY / 10)

      const lineHeight: number = (window.scrollY - elPosFromDocumentTop + window.innerHeight / 2) * heightIncrementFactor
      el.style.setProperty('--line-height', `${lineHeight}px`)
    }, 100)

    window.addEventListener('scroll', this.lineHeightWatcher)
  }

  /**
   * Watches the `.Timeline__item` elements to cross the vertical middle of the viewport.
   */
  private watchTimelineItems (): void {
    if (typeof window === 'undefined') {
      return
    }

    const timelineItems: HTMLLIElement[] = Array.from(
      this.$el.querySelectorAll<HTMLLIElement>('.Timeline__item:not(.--centered)')
    )

    if (!timelineItems || !Array.isArray(timelineItems) || timelineItems.length === 0) {
      return
    }

    const halfOfViewport: number = window.innerHeight / 2

    const observerOptions = {
      rootMargin: `0px 0px -${halfOfViewport}px 0px`,
      threshold: 0.5
    }

    this.timelineItemsObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.boundingClientRect.y < 0) {
          return
        }

        entry.isIntersecting
          ? entry.target.classList.add('--active')
          : entry.target.classList.remove('--active')
      })
    }, observerOptions)

    timelineItems.forEach(item => this.timelineItemsObserver?.observe(item))
  }
}

export default TimelineModuleUiPresentation
