// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { merge, cloneDeep } from 'lodash'

import { AnyModule, ModuleContent, PickerCallback, Related } from '../../../contracts'
import { DescriptionsRecord } from '../../../services'
import { log } from '../../../support'

import { AbstractModule } from './abstract-module'

/**
 * The abstract class that every module's form **HAS TO** extend.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Component<AbstractModuleForm<AnyModule>>({
  name: 'AbstractModuleForm',

  created (): void {
    this.beforeInitialContentSetup()
    this.setInitialContent()
    this.afterInitialContentSetup()
  }
})
export class AbstractModuleForm<M extends AnyModule> extends AbstractModule {
  /**
   * Module's alignment inside container.
   */
  @PropSync('alignment', { type: Object, required: false })
  public _alignment!: M['alignment']

  /**
   * Module's data.
   */
  @PropSync('content', { type: Object, required: true })
  public _content!: M['content']

  /**
   * Disables all form fields.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  /**
   * Form errors.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public readonly errors!: string[]

  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Module's version.
   *
   * NOTICE!: In ModuleForm we are not mutating version of any module
   * Property below is just for conditions and checking inside forms if it is necessary
   * EX: Conditional renderings
   */
  @PropSync('version', { type: String, required: false })
  public _version?: M['version']

  /**
   * Record of related attached to form.
   */
  protected descriptions: DescriptionsRecord = {}

  /**
   * Module's initial content
   */
  protected initialContent: ModuleContent | null = null

  /**
   * TODO: Description.
   */
  public describe (related: Related, keyName: string = 'title', fallback: string = 'Nie można znaleźć opisu'): void {
    if (typeof this.descriptions[related.type] === 'undefined') {
      this.descriptions[related.type] = {}
    }

    const description = this.descriptions[related.type] as AnyObject

    this.relatedService.describe<AnyObject>(related).then(list => {
      if (typeof list[keyName] === 'undefined') {
        log('AbstractModuleForm.describe(): [list[keyName]] is [undefined]! Aborting!', 'warn')
        return
      }
      description[related.value] = list[keyName]
    }).catch((error: Error) => {
      log(error, 'warn')
      description[related.value] = fallback
    }).finally(() => {
      this.$forceUpdate()
    })
  }

  /**
   * Resolves the description of the given related data.
   */
  public description (related: Related): string | undefined {
    if (!this.descriptions[related.type]) {
      return ''
    }

    return this.descriptions[related.type]?.[related.value]
  }

  /**
   * Called just after the `setInitialContent()` method.
   */
  protected afterInitialContentSetup (): void {
    //
  }

  /**
   * Called just before the `setInitialContent()` method.
   */
  protected beforeInitialContentSetup (): void {
    //
  }

  /**
   * Merges `this.initialContent` with the current value of `this._content`.
   */
  protected setInitialContent (): void {
    if (this.initialContent === null) {
      throw new Error(
        'AbstractModuleForm.setInitialContent(): [this.initialContent] is [null], but its value should be set from/inside the subclass!')
    }

    this._content = cloneDeep(merge(this.initialContent, this._content))
  }
}
