// Copyright © 2021 Move Closer

export type IYoutubeCallback = () => void

interface IYoutubeIframeLoader {
  done: () => void
  listeners: IYoutubeCallback[]
  load: (callback: IYoutubeCallback) => void
  loaded: boolean
  loading: boolean
  src: string
}

declare global {
  interface Window {
    onYouTubeIframeAPIReady?: () => void
    YT?: typeof YT
  }
}

/**
 * @type {{loaded: boolean, listeners: *[], load: YouTubeIframeLoader.load, src: string, loading: boolean, done: YouTubeIframeLoader.done}}
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Alexander Prinzhorn <alexander@prinzhorn.it>
 *
 * @see https://www.npmjs.com/package/youtube-iframe
 */
export const YouTubeIframeLoader: IYoutubeIframeLoader = {
  src: 'https://www.youtube.com/iframe_api',
  loading: false,
  loaded: false,
  listeners: [],

  load: function (callback: IYoutubeCallback) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    this.listeners.push(callback)

    if (this.loaded || window.YT) {
      setTimeout(function () {
        _this.done()
      })
      return
    }

    if (this.loading) return

    this.loading = true

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onYouTubeIframeAPIReady = function () {
      _this.loaded = true
      _this.done()
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = this.src
    document.body.appendChild(script)
  },

  done: function () {
    delete window.onYouTubeIframeAPIReady

    while (this.listeners.length) {
      const listener = this.listeners.pop()
      if (listener) listener()
    }
  }
}
