// Copyright © 2021 Move Closer

import {
  Collection,
  ICollection,
  Injectable,
  IResponse, mapCollection,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { IJobOfferModel, JobOffer, jobOfferAdapterMap } from '../../models'

import { IJobOffersRepository } from './contracts'

/**
 * Repository capable of fetching the job offers from the API.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Injectable()
export class JobOffersRepository extends Repository<JobOffer, IJobOfferModel> implements IJobOffersRepository {
  /**
   * @inheritDoc
   */
  protected map = jobOfferAdapterMap

  /**
   * @inheritDoc
   */
  protected useAdapter = true

  /**
   * @inheritDoc
   */
  public async load (query: QueryParams = {}): Promise<ICollection<JobOffer>> {
    const response: IResponse = await this.connector.call(
      'jobOffers', 'search', { }, { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return new Collection(mapCollection<JobOffer>(response.data.data, this.map), response.data.meta)
  }
}
