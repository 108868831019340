// Copyright © 2022 Move Closer

import {
  HeadingLevel,
  HorizontalAlignment,
  ModuleBase,
  ModuleDriver,
  ModuleFactory,
  ModuleSize
} from '../../../contracts'

import { TileBehavior } from '../../molecules'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../_abstract'

import { TileModule, TileModuleContent } from './Tile.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export function tileContentFactory (): TileModuleContent {
  return {
    behavior: TileBehavior.Collapse,
    body: '',
    contentAlignment: HorizontalAlignment.Left,
    heading: {
      text: '',
      level: HeadingLevel.H1
    },
    headingAlignment: HorizontalAlignment.Left,
    large: false
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const tileFactory: ModuleFactory<TileModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 4,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 12,
      minColSpan: 4,
      minRowSpan: 3,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<TileModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: tileContentFactory(),
      driver: ModuleDriver.Tile
    }
  },
  getResizeStep (): number {
    return 4
  }
}
