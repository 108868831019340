// Copyright © 2022 Move Closer

import { ModuleBase, ModuleDriver, ModuleFactory, ModuleSize } from '../../../contracts'

import { getDefaultModuleAlignment, abstractModuleFactory } from '../_abstract'

import { HashtagWallModule, HashtagWallModuleContent } from './HashtagWall.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const hashtagWallContentFactory = (): HashtagWallModuleContent => ({
  posts: null
})

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const hashtagWallFactory: ModuleFactory<HashtagWallModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: false,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<HashtagWallModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      driver: ModuleDriver.HashtagWall,
      content: hashtagWallContentFactory()
    }
  }
}
