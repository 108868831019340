













































import { Component, PropSync } from 'vue-property-decorator'

import { DescriptionOfGallery } from '../../../models'
import { isRelated } from '../../../services'
import { Picker, Related, RelatedType } from '../../../contracts'

import { RelatedPartial } from '../_abstract'

@Component<GalleryForm>({ name: 'GalleryForm' })
export class GalleryForm extends RelatedPartial<DescriptionOfGallery> {
  /**
   * Related gallery
   */
  @PropSync('gallery', { type: Object, required: false, default: null })
  public _gallery!: Related<RelatedType.Gallery> | null

  /**
   * @inheritDoc
   */
  public get isRelatedSet (): boolean {
    return this._gallery !== null && isRelated(this._gallery)
  }

  /**
   * Total number of photos in gallery
   */
  public get totalItems (): number | undefined {
    if (!this.described || !this.described.totalItems) {
      return
    }

    return this.described.totalItems
  }

  /**
   * Resets related gallery
   */
  public clearGallery (): void {
    this._gallery = null
    this.$emit('clear')
  }

  /**
   * @inheritDoc
   */
  public selectRelated (): void {
    this.pickRelated(Picker.Gallery, this.setRelated, this.getRelatedSource())
  }

  /**
   * @inheritDoc
   * @protected
   */
  protected getRelatedSource (): Related<RelatedType.Gallery> | undefined {
    if (!this.isRelatedSet || this._gallery === null) {
      return
    }
    return this._gallery
  }

  /**
   * Gallery chosen to `_relatedSet`
   * @param selected
   * @protected
   */
  protected setRelated (selected: Related<RelatedType.Gallery>): void {
    this._gallery = selected
    this.$nextTick(() => this.describeRelated())
  }
}

export default GalleryForm
