// Copyright © 2022 Move Closer

import isPlainObject from 'lodash/isPlainObject'
import { AnyObject, IConnector, Injectable } from '@movecloser/front-core'

import { log } from '../../support'

import { IUserComService, NewsletterFormData, UserComConfig } from './user-com.contracts'

/**
 * @inheritDoc
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Injectable()
export class UserComService implements IUserComService {
  /**
   * Service's configuration.
   */
  private readonly config: UserComConfig

  /**
   * An instance of the service that implements the `IConnector` interface.
   */
  private readonly connector: IConnector

  /**
   * Determines whether the User.com script has been successfully initialised.
   */
  private isInitialised: boolean = false

  constructor (config: UserComConfig, connector: IConnector) {
    if (typeof config === 'undefined') {
      throw new Error('UserComService.constructor(): Expected [config] argument to be defined!')
    }

    this.config = config

    if (typeof connector === 'undefined') {
      throw new Error('UserComService.constructor(): Expected [connector] argument to be defined!')
    }

    this.connector = connector
  }

  /**
   * @inheritDoc
   */
  public async subscribe (formData: NewsletterFormData): Promise<void> {
    return new Promise((resolve, reject) => {
      const userengage = window.userengage

      // Actual payload to submit
      const data: {
        [agreement: string]: boolean | string
        name: string
        email: string
      } = {
        name: formData.name,
        email: formData.email
      }

      for (const agreement of formData.agreements) {
        // Here we can assign it to `true`, because we already know that this agreement is checked.
        data[agreement] = true
      }

      // Send pageHit action to user.
      this.pageHit({
        name: data.name,
        email: data.email,
        // We can assign it to true, because we know that it is checked.
        rodo: true
      }).catch((error: Error) => {
        reject(error)
      })

      userengage('event.Formularz_Newsletter', { ...data })
        .then(() => { resolve() })
        .catch((error: unknown) => { reject(error) })
    })
  }

  /**
   * Initializes the User.com JS script.
   */
  private async init (): Promise<void> {
    if (typeof window === 'undefined') {
      return
    }

    if (!UserComService.isConfigValid(this.config)) {
      return
    }

    return new Promise((resolve, reject) => {
      let el: HTMLScriptElement | null =
        document.querySelector<HTMLScriptElement>(`script[src="${this.config.endpoint}"]`)

      if (el === null) {
        el = document.createElement('script')
        el.setAttribute('data-cfasync', 'false')
        el.src = this.config.endpoint
        el.type = 'text/javascript'
      }

      if (el.hasAttribute('data-loaded')) {
        resolve(Boolean(el.getAttribute('data-loaded')))
        return
      }

      el.addEventListener('error', reject)
      el.addEventListener('abort', reject)
      el.addEventListener('load', () => {
        if (el === null) {
          return reject(new Error('[el] is [null]!'))
        }

        el.setAttribute('data-loaded', 'true')
        resolve(true)
      })

      document.body.appendChild(el)
    }).then(() => {
      /**
       * Piece of executable string that should be evaluated once the main
       * script from `loadScript()` method is ready.
       *
       * @see https://user.com/en/integrations/custom-script/
       */
      const _executable: string = `window.civchat = { apiKey: "${this.config.apiKey}" }`
      const userScript: HTMLScriptElement = document.createElement('script')
      userScript.setAttribute('data-cfasync', 'false')
      userScript.addEventListener('load', () => { this.isInitialised = true })
      userScript.type = 'text/javascript'
      userScript.innerHTML = _executable

      document.body.appendChild(userScript)
    }).catch(error => { log(error, 'error') })
  }

  /**
   * @see https://docs.user.com/ue-page-hit/
   * @param payload
   */
  private pageHit (payload: AnyObject): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const UE = window.UE

        UE.pageHit(payload)
        resolve(true)
      } catch (e) {
        reject(new Error("Can't perform User.Com's `pageHit` action"))
      }
    })
  }

  /**
   * Validates the passed-in config object.
   *
   * @param config - The config object to validate.
   *
   * @throws Error - if the config is not valid.
   */
  private static isConfigValid (config: UserComConfig): boolean {
    if (!isPlainObject(config)) {
      log(`UserComService.validateConfig(): Expected the [config] object to be a plain object, but got [${config}]!`, 'error')
      return false
    }

    if (typeof config.apiKey !== 'string') {
      log(`UserComService.validateConfig(): Expected the [config.apiKey] to be a type of [string], but got [${typeof config.apiKey}]!`, 'error')
      return false
    }

    if (config.apiKey.length === 0) {
      log('UserComService.validateConfig(): [config.apiKey] can NOT be empty!', 'error')
      return false
    }

    if (typeof config.endpoint !== 'string') {
      log(`UserComService.validateConfig(): Expected the [config.endpoint] to be a type of [string], but got [${typeof config.endpoint}]!`, 'error')
      return false
    }

    if (config.endpoint.length === 0) {
      log('UserComService.validateConfig(): [config.endpoint] can NOT be empty!', 'error')
      return false
    }

    /**
     * Determines whether the `config.endpoint` is a valid URL address.
     */
    const isEndpointUrl: boolean = new RegExp(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    ).test(config.endpoint)

    if (!isEndpointUrl) {
      log('UserComService.validateConfig(): Expected the [config.endpoint] to be a valid URL address', 'error')
      return false
    }

    return true
  }
}
