// Copyright © 2022 Move Closer

import {
  DEFAULT_CONTAINER_COLUMN_GAP,
  DEFAULT_CONTAINER_MARGIN,
  DEFAULT_CONTAINER_PADDING,
  DEFAULT_CONTAINER_ROW_GAP,
  DEFAULT_CONTAINER_WIDTH
} from '../../atoms'

import { ContainerContent } from './Container.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export function createContainerContent (): ContainerContent {
  return {
    gridGap: {
      column: DEFAULT_CONTAINER_COLUMN_GAP,
      row: DEFAULT_CONTAINER_ROW_GAP
    },
    margin: {
      bottom: DEFAULT_CONTAINER_MARGIN,
      top: DEFAULT_CONTAINER_MARGIN
    },
    padding: {
      bottom: DEFAULT_CONTAINER_PADDING,
      top: DEFAULT_CONTAINER_PADDING
    },
    width: DEFAULT_CONTAINER_WIDTH
  }
}
