// Copyright © 2021 Move Closer

import {
  Injectable,
  IResponse,
  mapCollection,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { GlobalSearchResult, IGlobalSearchResultModel } from '../../models'

import { IGlobalSearchRepository } from './contracts'

/**
 * Repository capable of fetching the search results from the API.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Injectable()
export class GlobalSearchRepository
  extends Repository<GlobalSearchResult, IGlobalSearchResultModel>
  implements IGlobalSearchRepository {
  /**
   * @inheritDoc
   */
  public async load (query: QueryParams = {}): Promise<GlobalSearchResult[]> {
    const response: IResponse = await this.connector.call(
      'root', 'search', { }, { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        response.status,
        { ...response.errors?.errors, ...response.errors?.data }
      )
    }

    return mapCollection<GlobalSearchResult>(response.data.data, {})
  }
}
