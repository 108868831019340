// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<Selectable>({ name: 'Selectable' })
export class Selectable<ContentContract = AnyObject> extends Vue {
  @PropSync('content', { type: Object, required: true })
  public _content!: ContentContract

  @Prop({ type: [String, Number], required: false, default: null })
  public readonly id!: string | number | null

  @Prop({ type: [String, Object], required: false, default: null })
  protected readonly label!: TranslateResult | string

  @Prop({ type: [String, Object], required: false, default: null })
  protected readonly placeholder!: TranslateResult | string

  protected elementId: string = 'selectable'
  protected elementLabel: string = 'Warning: [elementLabel] not set!'
  protected elementPlaceholder: string = 'Warning: [elementPlaceholder] not set!'

  public get model (): unknown {
    throw new Error('[Selectable] component [model] getter not set')
  }

  public set model (model: unknown) {
    throw new Error('[Selectable] component [model] setter not set')
  }

  public get resolvedId (): string {
    return this.id ? `${this.elementId}-${this.id}` : this.elementId
  }

  public get resolvedLabel (): string {
    if (this.label && typeof this.label === 'object') {
      return this.label.toString()
    }

    return this.$t(this.label ?? this.elementLabel).toString()
  }

  public get resolvedPlaceholder (): string {
    if (this.placeholder && typeof this.placeholder === 'object') {
      return this.placeholder.toString()
    }

    return this.$t(this.placeholder ?? this.elementPlaceholder).toString()
  }
}
