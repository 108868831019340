// Copyright © 2022 Move Closer

import { Link } from '@movecloser/ui-core'
import { mapModel, MappingConfig, MappingTypes } from '@movecloser/front-core'

import { DescriptionOfDepartment, DescriptionOfDepartmentsFeed } from '../../../models'
import { RelatedType } from '../../../contracts'

import { RelatedRecord } from '../related.contracts'

import { AbstractTypeDriver } from './_abstract'

/**
 * Driver for the `RelatedService` capable of resolving a set of departments.
 *
 * @see DepartmentsModule
 * @see DepartmentsModuleForm
 * @see DepartmentsModuleUi
 * @see DepartmentsModuleUiPresentation
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export class DepartmentsFeedDriver extends AbstractTypeDriver<DescriptionOfDepartmentsFeed, DescriptionOfDepartment[]> {
  /**
   * @inheritDoc
   */
  protected adapterMap: MappingConfig = {
    link: {
      type: MappingTypes.Function,
      value: item => {
        const link: Link = {
          isExternal: false,
          newTab: false,
          target: ''
        }

        if (typeof item.link !== 'string') {
          return link
        }

        link.target = item.link.startsWith('/') ? item.link : `/${item.link}`

        return link
      }
    }
  }

  /**
   * @inheritDoc
   */
  protected dependencies: RelatedType[] = [RelatedType.Department]

  /**
   * @inheritDoc
   */
  protected key: RelatedType = RelatedType.DepartmentsFeed

  /**
   * @inheritDoc
   */
  public resolve (
    id: string,
    record: RelatedRecord
  ): DescriptionOfDepartment[] {
    this.throwWhenMissing(id, record)

    const set: DescriptionOfDepartmentsFeed = this.describe(id, record)

    // Check if the output is as expected.
    if (!Array.isArray(set.items)) {
      throw new Error(
        `DepartmentsFeedDriver.resolve(): Failed to resolve items for the [${this.key}] of a given ID [${id}]!`
      )
    }

    return set.items.reduce<DescriptionOfDepartment[]>(
      (acc: DescriptionOfDepartment[], curr: DescriptionOfDepartment) => ([
        ...acc,
        mapModel<DescriptionOfDepartment>(
          curr,
          this.adapterMap,
          Object.keys(this.adapterMap).length === 0
        )
      ]), [])
  }
}
