

















import { BootstrapImage } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import {
  ContainerBackgroundImage,
  ContainerColumnGap,
  ContainerData,
  ContainerMargin,
  ContainerPadding,
  ContainerRowGap,
  ContainerWidth
} from './UiContainer.contracts'

import {
  containerColumnGapClassNameRegistry,
  containerMarginClassNameRegistry,
  containerPaddingClassNameRegistry,
  containerRowGapClassNameRegistry,
  containerWidthClassNameRegistry,
  DEFAULT_CONTAINER_COLUMN_GAP,
  DEFAULT_CONTAINER_MARGIN,
  DEFAULT_CONTAINER_PADDING,
  DEFAULT_CONTAINER_ROW_GAP,
  DEFAULT_CONTAINER_WIDTH,
  UI_CONTAINER_ID_ATTR_PREFIX
} from './UiContainer.config'
import { BackgroundColorPalette } from '../../containers/Container/Container.helpers'
import { UseNonce } from '../../../extensions'

/**
 * Component that's aware of all the different container widths/types/modes specified in the app.
 * It helps you get the correct CSS class combination using the passed-in `width` prop.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 */
@Component<UiContainer>({
  name: 'UiContainer',
  components: { BootstrapImage }
})
export class UiContainer extends Mixins<UseNonce>(UseNonce) {
  /**
   * Container's configuration.
   */
  @Prop({ type: Object, required: true })
  private readonly data!: ContainerData

  /**
   * @see UI_CONTAINER_ID_ATTR_PREFIX
   */
  public readonly idPrefix = UI_CONTAINER_ID_ATTR_PREFIX

  /**
   * @see ContainerData.backgroundImage
   */
  public get backgroundImage (): ContainerBackgroundImage | undefined {
    return this.data.backgroundImage
  }

  /**
   * Determines whether the component has been provided with the correct `backgroundImage` prop.
   */
  public get hasBackgroundImage (): boolean {
    // noinspection JSIncompatibleTypesComparison
    return typeof this.backgroundImage === 'object' &&
      this.backgroundImage !== null &&
      typeof this.backgroundImage.src === 'string'
  }

  /**
   * Determines whether the component has been provided with the correct `data` prop.
   */
  public get hasData (): boolean {
    return typeof this.data !== 'undefined'
  }

  /**
   * @see ContainerData.id
   */
  public get id (): ContainerData['id'] {
    return this.data.id
  }

  public get backgroundClassName (): string | undefined {
    switch (this.data.backgroundColor) {
      case BackgroundColorPalette.CTA:
        return '--bg-cta'
      case BackgroundColorPalette.DarkGray:
        return '--bg-dark-gray'
      case BackgroundColorPalette.LightGray:
        return '--bg-light-gray'
      case BackgroundColorPalette.None:
        return
      case BackgroundColorPalette.White:
        return '--bg-white'
    }
  }

  /**
   * CSS class that determines the container's grid gap.
   */
  public get gridGapClassName (): string {
    const columnGapClassName: string =
      containerColumnGapClassNameRegistry.getValue(this.gridGap.column)

    const rowGapClassName: string =
      containerRowGapClassNameRegistry.getValue(this.gridGap.row)

    return `${columnGapClassName} ${rowGapClassName}`
  }

  /**
   * CSS class that determines the container's vertical margin.
   */
  public get marginClassName (): string {
    const bottomMarginClassName: string =
      containerMarginClassNameRegistry.getValue(this.margin.bottom).bottom

    const topMarginClassName: string =
      containerMarginClassNameRegistry.getValue(this.margin.top).top

    return `${bottomMarginClassName} ${topMarginClassName}`
  }

  /**
   * CSS class that determines the container's vertical padding.
   */
  public get paddingClassName (): string {
    const bottomPaddingClassName: string =
      containerPaddingClassNameRegistry.getValue(this.padding.bottom).bottom

    const topPaddingClassName: string =
      containerPaddingClassNameRegistry.getValue(this.padding.top).top

    return `${bottomPaddingClassName} ${topPaddingClassName}`
  }

  /**
   * @see ContainerData.twoByTwoLayout
   */
  public get twoByTwoLayout (): boolean {
    return this.data.twoByTwoLayout || false
  }

  /**
   * CSS class that determines the container's width.
   */
  public get widthClassName (): string {
    let className = containerWidthClassNameRegistry.getValue(this.width)
    if (this.fullwidthOnMobile) className += '-lg'

    return className
  }

  /**
   * @see ContainerData.fullwidthOnMobile
   */
  private get fullwidthOnMobile (): boolean {
    return this.data.fullwidthOnMobile || false
  }

  /**
   * @see ContainerData.gridGap
   */
  private get gridGap (): { column: ContainerColumnGap; row: ContainerRowGap } {
    return {
      column: this?.data?.gridGap?.column ?? DEFAULT_CONTAINER_COLUMN_GAP,
      row: this?.data?.gridGap?.row ?? DEFAULT_CONTAINER_ROW_GAP
    }
  }

  /**
   * @see ContainerData.margin
   */
  private get margin (): { bottom: ContainerMargin; top: ContainerMargin } {
    return {
      bottom: this?.data?.margin?.bottom ?? DEFAULT_CONTAINER_MARGIN,
      top: this?.data?.margin?.top ?? DEFAULT_CONTAINER_MARGIN
    }
  }

  /**
   * @see ContainerData.padding
   */
  private get padding (): { bottom: ContainerPadding; top: ContainerPadding } {
    return {
      bottom: this?.data?.padding?.bottom ?? DEFAULT_CONTAINER_PADDING,
      top: this?.data?.padding?.top ?? DEFAULT_CONTAINER_PADDING
    }
  }

  /**
   * @see ContainerData.width
   */
  private get width (): ContainerWidth {
    return this?.data?.width || DEFAULT_CONTAINER_WIDTH
  }

  public get style (): string | undefined {
    if (!this.backgroundImage) {
      return
    }

    return `
      #${this.idPrefix}${this.id} .UiContainer__bg-image {
        object-position: ${this.backgroundImage.position}
      }
    `
  }
}

export default UiContainer
