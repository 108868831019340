// Copyright © 2021 Move Closer

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export type CanHaveHeading = Partial<HasHeading>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface HasHeading {
  heading: Heading
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface Heading {
  /**
   * Heading's level (from 1 to 6).
   */
  level: HeadingLevel

  /**
   * Heading's content.
   */
  text: string
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export enum HeadingLevel {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  H5 = 5,
  H6 = 6,
}
