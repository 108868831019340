








import { Component, Prop, Vue } from 'vue-property-decorator'
import { HeadingFont } from './UiHeading.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<UiHeading>({ name: 'Heading' })
export class UiHeading extends Vue {
  /**
   * The level of the heading from 1 to 6.
   */
  @Prop({ type: Number, required: true })
  private readonly level!: number

  /**
   * The HTML tag that should be used to render the heading's contents.
   */
  @Prop({ type: String, required: false })
  private readonly tag?: string

  /**
   */
  @Prop({ type: String, required: false, default: HeadingFont.Default })
  private readonly font?: HeadingFont

  /**
   * CSS class that will be applied to the HTML element.
   * Needed to preserve the styling when different HTML element than heading is being used.
   */
  public get classNames (): (string | undefined)[] {
    return [
      typeof this.tag === 'string' ? `h${this.safeLevel}` : undefined,
      `--font-${this.font}`
    ]
  }

  /**
   * The HTML tag that should be used to render the heading's contents.
   */
  public get component (): string {
    return typeof this.tag === 'string' ? this.tag : `h${this.safeLevel}`
  }

  /**
   * `level` prop double-checked for the incorrect values.
   */
  private get safeLevel (): number {
    let level: number = this.level

    if (!Number.isInteger(level) || level < 1 || level > 6) {
      level = 2
    }

    return level
  }
}

export default UiHeading
