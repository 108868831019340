







import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { TimelineModule } from '../Timeline.contracts'

/**
 * Container component for the `TimelineModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TimelineModuleUi>({
  name: 'TimelineModuleUi',
  components: {
    TimelineModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Timeline" */
      './Timeline.ui.presentation.vue'
    )
  }
})
export class TimelineModuleUi extends AbstractModuleUi<TimelineModule> {
  /**
   * Determines whether the module has been provided with the correct `entries`array.
   */
  private get hasEntries (): boolean {
    return typeof this.data.content.entries !== 'undefined' &&
      Array.isArray(this.data.content.entries) &&
      this.data.content.entries.length > 0
  }
}

export default TimelineModuleUi
