








import { AnyObject } from '@movecloser/front-core'
import { BootstrapPagination } from '@movecloser/ui-core'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

import { BOOTSTRAP_PAGINATION_PROPS } from './Pagination.config'

/**
 * @emits update:currentPage - When the User changes the page.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<Pagination>({
  name: 'Pagination',
  components: { BootstrapPagination },
  mounted (): void {
    this.changeAriaInButton()
  }
})
export class Pagination extends Vue {
  /**
   * Props for the `<BPagination>` component (will override the default component's config).
   */
  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly bPaginationProps!: AnyObject

  /**
   * Currently-active page.
   */
  @PropSync('currentPage', { type: Number, required: true })
  public _currentPage!: number

  /**
   * Total number of rows in the dataset.
   *
   * @see https://bootstrap-vue.org/docs/components/pagination#comp-ref-b-pagination-props -> total-rows
   */
  @Prop({ type: Number, required: true })
  public readonly totalRows!: number

  public readonly BOOTSTRAP_PAGINATION_PROPS = BOOTSTRAP_PAGINATION_PROPS

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return typeof this._currentPage === 'number' && typeof this.totalRows === 'number'
  }

  /**
   * I know this workaround is very dirty :/. But bootstrap pagination is adding tabindex = "-1"
   * for elements inside of list item (<li>). There were no ways to overwrite this auto behaviour.
   *
   * Overwriting is required to fulfill WCAG.
   *
   * Refer to this link fir more info: https://github.com/bootstrap-vue/bootstrap-vue/issues/3496#issuecomment-501321997
   */
  private changeAriaInButton (): void {
    if (typeof document === 'undefined') {
      return
    }

    const paginationEl = document.getElementById('pagination-el')

    if (!paginationEl) {
      return
    }

    paginationEl.removeAttribute('role')

    let buttonDefaultAriaLabel: string | null = null

    paginationEl.querySelectorAll<HTMLButtonElement>('li button').forEach((button: HTMLButtonElement) => {
      button.setAttribute('tabindex', '0')
      button.removeAttribute('role')

      // Read default aria-label
      buttonDefaultAriaLabel = button.getAttribute('aria-label')

      if (!buttonDefaultAriaLabel) {
        return
      }

      if (buttonDefaultAriaLabel.includes('Go to page')) {
        button.setAttribute('aria-label',
          String(this.$t('modules.components.molecules.Pagination.to-page')) +
        buttonDefaultAriaLabel.split('Go to page')[1])
      }

      // Here I used key word `next`, just in case if bootstrap will change it in the future.
      // Original string is : "Go to next page".
      if (buttonDefaultAriaLabel.includes('next')) {
        button.setAttribute('aria-label',
          String(this.$t('modules.components.molecules.Pagination.next-page')))
      }

      // Here I used key word `previous`, just in case if bootstrap will change it in the future.
      // Original string is : "Go to previous page".
      if (buttonDefaultAriaLabel.includes('previous')) {
        button.setAttribute('aria-label',
          String(this.$t('modules.components.molecules.Pagination.previous-page')))
      }
    })
  }

  /**
   * Watches for changes of current page to override default pagination's button ARIA.
   *
   * @see SearchResults.ui.presentation.vue
   */
  @Watch('_currentPage')
  private onCurrentPageUpdate (): void {
    let timerIndex: number | null = null

    if (typeof timerIndex === 'number') {
      clearTimeout(timerIndex)
    }

    timerIndex = setTimeout(() => {
      this.changeAriaInButton()
    }, 1000) as unknown as number
  }
}

export default Pagination
