// Copyright © 2021 Move Closer

import {
  Addon,
  AddonData,
  AnyModule,
  ClientAddon,
  Identifier,
  LayoutContainer,
  LayoutSlot,
  TreeNodeParent
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
interface BaseContent {
  id: Identifier
  parent?: TreeNodeParent
  publicationDate: string
  site: Identifier
  title: string
  type: ContentType
  urlPath: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentData extends BaseContent {
  // FIXME: Replace the `any` with more descriptive type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containers: Record<LayoutContainer, any>

  contentId: Identifier
  options: ContentOptions
  slots: Record<LayoutSlot, AnyModule[]>
  properties: Partial<Record<ClientAddon, AddonData>>
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface ContentOptions {
  [key: string]: boolean
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Krzysztof Ustowski <krzysztof.ustowski@movecloser.pl>
 * @author Michał Wawer <michal.wawer@movecloser.pl>
 */
export enum ContentType {
  // Article = 'article',
  Page = 'page'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IContent {
  get<T> (property: string, defaultValue?: T): T
  getOption (key: string, defaultValue?: boolean): boolean
  getProperty (addon: Addon): AddonData
  hasProperty (addon: Addon): boolean
}
