// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'

/**
 * Extendable mixin that encapsulates the logic useful when working with asynchronous components
 * that sometimes should be put in the "loading" state (e.g. while waiting for the API's response).
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Loading>({ name: 'Loading' })
export class Loading extends Vue {
  /**
   * Determines whether the component is in the loading state.
   */
  public isLoading: boolean = false

  /**
   * The CSS class that determines whether the component is in the loading state.
   */
  public get loadingClass (): string {
    return this.isLoading ? '--loading' : ''
  }

  /**
   * Marks the component as loading.
   */
  protected markAsLoading (): void {
    this.isLoading = true
  }

  /**
   * Marks the component as ready.
   */
  protected markAsReady (): void {
    this.isLoading = false
  }
}
