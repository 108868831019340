








import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Colorable } from '../../../../extensions'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `NewsletterModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NewsletterModuleUiPresentation>({
  name: 'NewsletterModuleUiPresentation',
  components: {
    NewsletterForm: () => import(
      /* webpackChunkName: "molecules/NewsletterForm" */
      '../../../molecules/NewsletterForm/NewsletterForm.vue'
    )
  }
})
export class NewsletterModuleUiPresentation extends Mixins<
  AbstractModuleUiPresentation, Colorable
>(AbstractModuleUiPresentation, Colorable) {
  /**
   * Determines whether legend is visible.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLegendHidden?: boolean

  /**
   * Determines legend text.
   */
  @Prop({ type: String, required: false, default: '' })
  public readonly legendText?: string
}

export default NewsletterModuleUiPresentation
